import { Plugin, PluginKey } from "prosemirror-state";
import { v4 as uuidv4 } from "uuid";

export default class BlockId {
  static pluginKey = new PluginKey("blockid");

  asPlugin = () => {
    return new Plugin({
      appendTransaction(transactions, oldState, newState) {
        const ids = new Set<string>();
        const tr = newState.tr;
        let modified = false;
        newState.doc.descendants((node, pos) => {
          const id = node.attrs["block_id"];
          if (id !== undefined) {
            if (id === null || ids.has(id)) {
              const newId = uuidv4();
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                block_id: newId,
              });
              ids.add(newId);
              modified = true;
            } else {
              ids.add(id);
            }
          }
          return false;
        });

        if (modified) return tr.setMeta("inputRulesIgnore", true);

        return undefined;
      },
    });
  };
}
