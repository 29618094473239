import { useQuery } from "jsonapi-react";
import { useState } from "react";
import { Attendee, User } from "../../lib/types";
import { useTranslation } from "react-i18next";

type SelectAttendeesProps = {
  attendees: Attendee[];
  setAttendees: React.Dispatch<React.SetStateAction<Attendee[]>>;
};

export const SelectAttendees = (props: SelectAttendeesProps) => {
  const {t} = useTranslation()
  const users = useQuery(["users", {}]);
  const [isOpen, setIsOpen] = useState(false);
  const [suggestedAttendees, setSuggestedAttendees] = useState<Attendee[]>([]);

  const handleAddAttendee = (query: string) => {
    const regexpEmail = new RegExp(/^.*@.*$/);
    if (regexpEmail.test(query)) {
      const newAttendee = { email: query, displayName: query } as Attendee;
      setSuggestedAttendees([newAttendee]);
    } else {
      const suggestions: Attendee[] = users.data
        ? users.data
            .filter((u: User) =>
              u.displayName
                .toLocaleLowerCase()
                .includes(query.toLocaleLowerCase())
            )
            .map((u: User) => {
              return u as Attendee;
            })
        : [];
      setSuggestedAttendees(suggestions);
    }
  };

  const addAttendee = (attendee: Attendee) => {
    if (!props.attendees.find((p) => p.id === attendee.id)) {
      props.setAttendees(props.attendees.concat(attendee));
    }
  };

  const removeAttendee = (attendee: Attendee) => {
    props.setAttendees(props.attendees.filter((p) => p.id !== attendee.id));
  };

  const filterNotSelectedAttendeesByEmail = (suggestions: Attendee[]) => {
    const attendeesEmails = props.attendees.map((a) => a.email)
    return suggestions.filter((a) => !attendeesEmails.includes(a.email))
  }

  return (
    <div>
      <label
        htmlFor="attendee"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {t("meetings.attendees")}
      </label>
      <div className="relative mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <input
            type="search"
            name="attendee"
            id="attendee"
            onChange={(e) => handleAddAttendee(e.target.value)}
            onFocus={(e) => {
              setIsOpen(true);
              handleAddAttendee(e.target.value);
            }}
            onBlur={() =>
              setTimeout(() => {
                setIsOpen(false);
              }, 100)
            }
            autoComplete="off"
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={t("meetings.attendeePlaceholder").toString()}
          />
        </div>

        {isOpen && (
          <ul
            className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex={-1}
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            { filterNotSelectedAttendeesByEmail(suggestedAttendees).map((attendee) => (
              <li
                className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                id="listbox-option-0"
                role="option"
                aria-selected={props.attendees.includes(attendee)}
                onClick={() => addAttendee(attendee)}
              >
                <div className="flex items-center">
                  <img
                    src={attendee.image}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="font-normal ml-3 block truncate hover:font-semibold">
                    {attendee.displayName}
                  </span>
                </div>
              </li>
            ))}

            {/* <!-- More items... --> */}
          </ul>
        )}

        <ul
          className="mt-1 w-full rounded-md bg-white py-1 text-base ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          tabIndex={-1}
        >
          {/* <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "bg-indigo-600 text-white", Not Highlighted: "text-gray-900"
      --> */}
          {props.attendees.map((attendee) => (
            <li
              className="text-gray-900 relative cursor-default select-none py-1 pr-9"
            >
              <div className="flex items-center">
                <img
                  src={attendee.image}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
                {/* <!-- Selected: "font-semibold", Not Selected: "font-normal" --> */}
                <span className="font-normal ml-3 block truncate">
                  {attendee.displayName}
                </span>
              </div>

              {/* <!--
          Checkmark, only display for selected option.

          Highlighted: "text-white", Not Highlighted: "text-indigo-600"
        --> */}
              <button
                onClick={() => removeAttendee(attendee)}
                className="text-indigo-600 absolute inset-y-0 right-0 flex items-center"
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </svg>
              </button>
            </li>
          ))}
          {/* <!-- More items... --> */}
        </ul>
      </div>
    </div>
  );
};
