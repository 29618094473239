import UserPlaceholder from "../../assets/images/User.svg";
import { useState, useContext } from "react";
import { DocumentConfigContext } from "../DocumentConfigContext";
import type { Node } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { DropdownAssignAttendee } from "./DropdownAssignAttendee";
import { useTranslation } from "react-i18next";
import { useEditorEventCallback } from "@nytimes/react-prosemirror";

type DropdownAssignProps = {
  open?: boolean;
  setBlockAttributes: (view: EditorView, attributes: any) => boolean;
  node: Node;
};

export const DropdownAssign = (props: DropdownAssignProps) => {
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  const currentEvent = useContext(DocumentConfigContext);

  // const menuActiveClass = "transition ease-out duration-100 transform opacity-100 scale-100 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  // const menuInactiveClass = "transition ease-in duration-75 transform opacity-0 scale-100 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  // const itemActiveClass = "bg-gray-100 text-gray-900 flex w-full justify-between px-4 py-2 text-sm"
  // const itemInactiveClass = "text-gray-700 flex w-full justify-between px-4 py-2 text-sm"

  const attendeesAndOrganizer = () => {
    return currentEvent
      ? currentEvent.organizer
        ? [...currentEvent.attendees, currentEvent.organizer]
        : currentEvent.attendees
      : [];
  };

  const currentAssignee = attendeesAndOrganizer().find((attendee) => {
    return attendee.id === props.node.attrs["assignee_id"];
  });
  const closeDropdown = () => {
    setDropdown(false);
  };

  const getBlockId = () => {
    return props.node.attrs["block_id"]?.toString() || "";
  };

  const handleUnassignAction = useEditorEventCallback((view) => {
    if (view) {
      props.setBlockAttributes(view, { assignee_id: null });
    }
    setDropdown(false);
  });

  return (
    <div className="relative inline-block text-left">
      <input
        type="image"
        src={currentAssignee?.image ? currentAssignee.image : UserPlaceholder}
        onClick={() => setDropdown(!dropdown)}
        className="flex rounded-full focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:ring-offset-2 w-5 bg-gray-100"
        id={"menu-" + getBlockId() + "-button"}
        aria-expanded="false"
        aria-haspopup="true"
        alt="participant image"
      />
      {dropdown && (
        <div
          className="transition ease-out duration-100 transform opacity-100 scale-100 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={`menu-${props.node.attrs["block_id"] || ""}-button`}
          tabIndex={-1}
        >
          <div className="text-sm mx-2 mt-1">{t("dropdownAssign.title")}</div>
          <div
            className="list-none mx text-sm text-gray-700"
            id="options"
            role="listbox"
          >
            {attendeesAndOrganizer().map((attendee) => (
              <DropdownAssignAttendee
                key={attendee.id}
                attendee={attendee}
                setBlockAttributes={props.setBlockAttributes}
                closeDropdown={closeDropdown}
              />
            ))}
            <div
              className="group flex list-none cursor-default select-none items-center rounded-md p-2 hover:bg-indigo-600 hover:text-white"
              onClick={handleUnassignAction}
              tabIndex={-1}
            >
              <span className="ml-3 flex-auto text-center">
                {t("dropdownAssign.unassign")}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
