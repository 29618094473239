import { EditorView } from "prosemirror-view";
import { Attendee } from "../../lib/types";
import { useEditorEventCallback } from "@nytimes/react-prosemirror";

type DropdownAssignAttendeeProps = {
  attendee: Attendee;
  setBlockAttributes: (view: EditorView, attributes: any) => boolean;
  closeDropdown: () => void;
};

export const DropdownAssignAttendee = (
  props: DropdownAssignAttendeeProps
) => {
  const handleAssignAction = useEditorEventCallback((view) => {
    if (view) {
      props.setBlockAttributes(view, { assignee_id: props.attendee.id });
    }
    props.closeDropdown();
  });

  return (
    <div
      className="group flex list-none cursor-default select-none items-center rounded-md p-2 hover:bg-indigo-600 hover:text-white"
      onClick={handleAssignAction}
      tabIndex={-1}
    >
      <img
        src={props.attendee.image}
        alt=""
        className="h-6 w-6 flex-none rounded-full"
      />
      <span className="ml-3 flex-auto truncate">
        {props.attendee.displayName}
      </span>
    </div>
  );
};
