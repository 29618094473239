import {
  NodeViewComponentProps,
  useEditorEventCallback,
  ReactNodeView
} from "@nytimes/react-prosemirror";
import { DropdownAssign } from "../../components/editor/DropdownAssign";
import { setBlockType } from "./commands";
import { NodeSelection } from "prosemirror-state";
import { useRef, useEffect } from "react";
import { EditorView } from "prosemirror-view";
import { PropsWithChildren } from "react";

type BaseItemProps = {
  itemType: string;
  hasCheckbox: boolean;
  checkboxClassName?: string;
}

function BaseItem(props: NodeViewComponentProps & BaseItemProps & PropsWithChildren) {
  const ref = useRef<HTMLDivElement>(null);

  const setBlockAttributes = (view: EditorView, attributes: any) => {
    return setBlockType(
      props.node.type,
      attributes,
      NodeSelection.create(view.state.doc, props.getPos())
    )(view.state, view.dispatch);
  };

  const handleCheckbox = useEditorEventCallback((view) => {
    if (view) {
      setBlockType(
        props.node.type,
        { done: !props.node.attrs["done"] },
        NodeSelection.create(view.state.doc, props.getPos())
      )(view.state, view.dispatch);
    }
  });

  useEffect(() => {
    const element = ref.current;
    const keys = Object.keys(props.node.attrs);

    keys.forEach((key) => {
      element?.setAttribute("data-block_type", props.itemType);
      element?.setAttribute("data-" + key, props.node.attrs[key]);
    });
  }, [ref, props.node, props.itemType]);

  return (
    <div className="react-node-view ">
      <div ref={ref} className={`relative flex items start item-indent-${props.node.attrs["indent"]}`}>
        { props.hasCheckbox && <div className="flex h-5 items-center" contentEditable={false}>
          <input
            type="checkbox"
            onClick={handleCheckbox}
            className={props.checkboxClassName}
            defaultChecked={props.node.attrs["done"] === true}
          />
        </div>
        }
        <div className={`${props.hasCheckbox && "ml-3"} w-full ${props.node.attrs["wontDo"] === true ? "line-through" : ""}`}>
          {props.children}
        </div>
        <div contentEditable={false} className="actions-menu w-8">
          <span className="inline-flex h-5 w-5 float-right items-center justify-center rounded-full">
            <DropdownAssign
              key={props.node.attrs["block_id"]}
              setBlockAttributes={setBlockAttributes}
              node={props.node}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function ParagraphItem(props: NodeViewComponentProps) {
  return (
    <BaseItem itemType="paragraph_item" hasCheckbox={false} {...props}/>
  )
}

function DiscussionItem(props: NodeViewComponentProps) {
  return (
    <BaseItem itemType="discussion_item" hasCheckbox={true} checkboxClassName="h-4 w-4 round border-indigo-600 border-2 text-indigo-600 ring-indigo-500" {...props}/>
  )
}

function ActionItem(props: NodeViewComponentProps) {
  return (
    <BaseItem itemType="action_item" hasCheckbox={true} checkboxClassName="h-4 w-4 squared border-green-600 border-2 text-green-600 ring-green-500" {...props}/>
  )
}

function BulletPoint(props: NodeViewComponentProps & PropsWithChildren) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    const keys = Object.keys(props.node.attrs);

    keys.forEach((key) => {
      element?.setAttribute("data-block_type", "bullet_point");
      element?.setAttribute("data-" + key, props.node.attrs[key]);
    });
  }, [ref, props.node]);

  return (
    <div className="react-node-view ">
      <div ref={ref} className={`relative flex items start item-indent-${props.node.attrs["indent"]}`}>
        <div className="flex h-5 items-center" contentEditable={false}>
          <span className="text-xl">•</span>
        </div>
        <div className="ml-1 w-full">{props.children}</div>
      </div>
    </div>
  );
}



export const reactNodeViews: { [key: string]: () => ReactNodeView } = {
  paragraph_item: () => ({
    component: ParagraphItem,
    dom: document.createElement("div"),
    contentDOM: document.createElement("p"),
  }),
  discussion_item: () => ({
    component: DiscussionItem,
    dom: document.createElement("div"),
    contentDOM: document.createElement("p"),
  }),
  action_item: () => ({
    component: ActionItem,
    dom: document.createElement("div"),
    contentDOM: document.createElement("p"),
  }),
  bullet_point: () => ({
    component: BulletPoint,
    dom: document.createElement("div"),
    contentDOM: document.createElement("p"),
  })
};
