import { Dispatch, SetStateAction } from "react";
import { Organization } from "../../../lib/types";
import { Tooltip } from "./Tooltip";

type OrganizationComboboxProps = {
  title: string;
  placeholder?: string;
  organizations: Organization[];
  selectedOrganization: Organization | null;
  setSelectedOrganization: Dispatch<SetStateAction<Organization | null>>;
  tooltipContent?: string;
};

export const OrganizationCombobox = (props: OrganizationComboboxProps) => {
  return (
    <div className="sm:col-span-3">
      {props.tooltipContent ? (
        <Tooltip
          position="top"
          color="dark"
          content={props.tooltipContent}
          showIcon={true}
        >
          <label
            htmlFor="organization"
            className="text-sm font-medium leading-6 text-gray-900"
          >
            {props.title}
          </label>
        </Tooltip>
      ) : (
        <label
          htmlFor="organization"
          className="text-sm font-medium leading-6 text-gray-900"
        >
          {props.title}
        </label>
      )}
      <div className="mt-2">
        <select
          id="organization"
          name="organization"
          value={props.selectedOrganization?.id}
          onChange={(e) =>
            props.setSelectedOrganization(
              props.organizations.find((o) => o.id === e.target.value) || null
            )
          }
          autoComplete="off"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          {props.organizations.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
