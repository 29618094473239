import { useEffect, useState } from "react";
import { Cycle, Objective, Team, User, CurrentUser } from "../../lib/types";
import { SlideOverRight } from "../elements/SlideOverRight";
import { Calendar as CalendarComponent } from "../elements/Calendar";
import { displayDate } from "../../lib/utils";
import { okrStatusMapping } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { useClient, useMutation, useQuery } from "jsonapi-react";
import { UserCombobox } from "../elements/forms/UserCombobox";
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";
import { MultiSelect } from "../elements/forms/MultiSelect";

type ObjectiveDetailProps = {
  currentUser: CurrentUser | null;
  selectedObjective: Objective | null;
  selectedCycle: Cycle | null;
  cycles: Cycle[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  weekStartIndex: number;
};

export const ObjectiveDetail = (props: ObjectiveDetailProps) => {
  const { t } = useTranslation();
  const apiClient = useClient();
  const [editObjective] = useMutation(
    [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "objectives",
      props.selectedObjective?.id,
    ],
    {
      invalidate: ["organizations", "objectives"],
    }
  );
  const [teams, setTeams] = useState<Team[]>([]);
  const teamsQuery = useQuery(["teams", {}]);
  useEffect(() => {
    if (teamsQuery.data) {
      setTeams(teamsQuery.data as Team[]);
    }
    notifyApiErrors(teamsQuery.errors);
  }, [teamsQuery.data, teamsQuery.errors]);
  const [title, setTitle] = useState(
    props.selectedObjective?.title ? props.selectedObjective?.title : ""
  );
  const [description, setDescription] = useState(
    props.selectedObjective?.description
      ? props.selectedObjective?.description
      : ""
  );
  const [status, setStatus] = useState(
    props.selectedObjective?.status || "not_started"
  );
  const [dueAt, setDueAt] = useState<Date | null | undefined>(
    props.selectedObjective?.dueAt
  );

  const [owner, setOwner] = useState<User | null | undefined>(
    props.selectedObjective?.owner
  );
  const [cycle, setCycle] = useState(props.selectedCycle);
  const [progress, setProgress] = useState(
    props.selectedObjective?.progress ? props.selectedObjective?.progress : 0
  );
  const [selectedTeams, setSelectedTeams] = useState<Team[]>(
    props.selectedObjective ? props.selectedObjective.teams : []
  );
  const [isOpenSelectedDatePicker, setIsOpenSelectedDatePicker] =
    useState(false);

  const allStatus = [
    "on_track",
    "behind",
    "at_risk",
    "closed",
    "postponed",
    "not_started",
  ];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editObjective({
      title,
      description,
      progress,
      status,
      cycleId: cycle?.id,
      dueAt,
      ownerId: owner?.id,
      teamIds: selectedTeams.map((t) => t.id),
    });
    props.setOpen(false);
  };

  const handleDelete = async () => {
    await apiClient
      .delete([
        "organizations",
        props.currentUser?.currentOrganizationId,
        "objectives",
        props.selectedObjective?.id,
      ])
      .then((response) => {
        if (response.meta && !response.error) {
          notifySuccess(t("apiMessages.successCycleDeleted"));
          props.setOpen(false);
        } else {
          notifyApiErrors([response.error]);
        }
      });
  };

  return (
    <SlideOverRight
      open={props.open}
      setOpen={props.setOpen}
      title={
        props.selectedObjective
          ? props.selectedObjective.title
          : t("okrs.newObjectiveLabel")
      }
      titleIconClass="fa-bullseye"
      closeLabel="Close"
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.titleLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      name="title"
                      id="title"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.descriptionLabel")}
                </label>
                <div className="mt-2">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  {t("okrs.descriptionHint")}
                </p>
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="progress"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.progressLabel")}
                </label>
                <div className="mt-2">
                  <input
                    type="range"
                    min={0}
                    max="100"
                    step="10"
                    value={progress}
                    onChange={(e) => setProgress(+e.target.value)}
                    list="tickmarks"
                    className="range range-primary"
                  />
                  <span className="text-xs pl-1">({progress}%)</span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.statusLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {allStatus.map((s) => (
                      <option key={s} value={s}>
                        {t(`okrs.${okrStatusMapping[s]}`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="cycle"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.cycleLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="cycle"
                    name="cycle"
                    value={cycle?.id}
                    onChange={(e) =>
                      setCycle(
                        props.cycles.find((c) => c.id === e.target.value) ||
                          null
                      )
                    }
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {props.cycles.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="dueAt"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.dueDateLabel")}
                </label>
                <div className="mt-2">
                  <div className="relative">
                    <button
                      type="button"
                      name="dueAt"
                      id="dueAt"
                      onClick={() =>
                        setIsOpenSelectedDatePicker(!isOpenSelectedDatePicker)
                      }
                      className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                    >
                      {dueAt ? displayDate(dueAt) : "(none)"}
                    </button>
                    {isOpenSelectedDatePicker && (
                      <div className="bg-gray-100 p-3 right-0 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                        <CalendarComponent
                          selectedDate={dueAt}
                          setSelectedDate={setDueAt}
                          weekStartIndex={props.weekStartIndex}
                          open={isOpenSelectedDatePicker}
                          setOpen={setIsOpenSelectedDatePicker}
                          allowClear={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-full">
                <UserCombobox
                  selectedUser={owner}
                  setSelectedUser={setOwner}
                  title={t("okrs.ownerLabel")}
                />
              </div>
              <div className="sm:col-span-full">
                <MultiSelect
                  title="Teams"
                  selectedValues={selectedTeams}
                  setSelectedValues={setSelectedTeams}
                  allValues={teams}
                  displayValue={(t: Team) => t.name}
                  by="id"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleDelete}
            disabled={props.selectedObjective ? false : true}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("menu.delete")}
          </button>
          <button
            type="button"
            onClick={() => props.setOpen(false)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("menu.cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </SlideOverRight>
  );
};
