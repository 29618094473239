import { createElement } from "react";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { displayDate } from "../../lib/utils";

type CalendarProps = {
  selectedDate: Date | null | undefined;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<Date | null | undefined>
  >;
  weekStartIndex: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allowClear?: boolean;
};

export const Calendar = (props: CalendarProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(
    props.selectedDate || new Date()
  );
  const DAYS_IN_WEEK = 7;
  const { t } = useTranslation();
  const weekdayHeader = [
    t("calendar.sundayShort").toString(),
    t("calendar.mondayShort").toString(),
    t("calendar.tuesdayShort").toString(),
    t("calendar.wednesdayShort").toString(),
    t("calendar.thursdayShort").toString(),
    t("calendar.fridayShort").toString(),
    t("calendar.saturdayShort").toString(),
  ];
  const generateWeeksMatrixForDate = (date: Date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const beginningOfMonth = new Date(year, month, 1);
    const endOfMonth = subDays(new Date(year, month + 1, 1), 1);
    const daysInMonth = endOfMonth.getDate();
    const emptyPrefixCount =
      (beginningOfMonth.getDay() - props.weekStartIndex) % DAYS_IN_WEEK;
    const emptyPostfixCount =
      (DAYS_IN_WEEK - 1 - (endOfMonth.getDay() - props.weekStartIndex)) %
      DAYS_IN_WEEK;
    var matrix: (null | Date)[] = [];
    for (var i = 0; i < emptyPrefixCount; i++) {
      matrix.push(null);
    }
    for (var j = 0; j < daysInMonth; j++) {
      matrix.push(new Date(year, month, j + 1));
    }
    for (var k = 0; k < emptyPostfixCount; k++) {
      matrix.push(null);
    }
    var result: (null | Date)[][] = [];
    for (var l = 0; l < matrix.length; l += DAYS_IN_WEEK) {
      result.push(matrix.slice(l, l + DAYS_IN_WEEK));
    }
    return result;
  };

  const beginningOfLastMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  };

  const beginningOfNextMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  };

  const [weeksMatrix, setWeeksMatrix] = useState(
    generateWeeksMatrixForDate(currentDate)
  );

  useEffect(() => {
    setWeeksMatrix(generateWeeksMatrixForDate(currentDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, props.weekStartIndex]);

  const dayCell = (day: Date | null) => {
    if (day && day.toDateString() === currentDate.toDateString()) {
      return createElement(
        "div",
        { className: "w-full h-full" },
        createElement(
          "div",
          {
            className:
              "flex items-center justify-center w-full rounded-full cursor-pointer",
          },
          createElement(
            "button",
            {
              className:
                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:bg-indigo-500 hover:bg-indigo-500 text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-indigo-700 rounded-full",
              role: "link",
              tabIndex: 0,
              type: "button",
            },
            day.getDate()
          )
        )
      );
    } else {
      return createElement(
        "div",
        { className: "px-2 py-2 cursor-pointer flex w-full justify-center" },
        day &&
          createElement(
            "button",
            {
              onClick: () => {
                setCurrentDate(day);
                props.setSelectedDate(day);
              },
              className: "",
              role: "link",
              tabIndex: 0,
              type: "button",
            },
            day.getDate()
          )
      );
    }
  };

  return (
    <>
      <div className="px-4 flex justify-between">
        <button
          type="button"
          onClick={() => {
            const newDate = new Date();
            setCurrentDate(newDate);
            props.setSelectedDate(newDate);
          }}
          className="items-center text-white bg-gray-400 font-medium rounded-lg text-sm px-4 lg:px-5 mb-3"
        >
          {t("calendar.today")}
        </button>

        <span
          tabIndex={0}
          className="focus:outline-none text-base font-bold dark:text-gray-100 text-gray-800 items-center"
        >
          {displayDate(currentDate)}
        </span>
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => {
              const newDate = beginningOfLastMonth(currentDate);
              setCurrentDate(newDate);
              props.setSelectedDate(newDate);
            }}
            aria-label="calendar backward"
            className="focus:text-gray-400 hover:text-gray-400 text-gray-800 dark:text-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chevron-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="15 6 9 12 15 18" />
            </svg>
          </button>
          <button
            type="button"
            onClick={() => {
              const newDate = beginningOfNextMonth(currentDate);
              setCurrentDate(newDate);
              props.setSelectedDate(newDate);
            }}
            aria-label="calendar forward"
            className="focus:text-gray-400 hover:text-gray-400 ml-3 text-gray-800 dark:text-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler  icon-tabler-chevron-right"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="9 6 15 12 9 18" />
            </svg>
          </button>
        </div>
      </div>

      {props.allowClear && (
        <button
          type="button"
          onClick={() => {
            props.setSelectedDate(null);
            props.setOpen(false);
          }}
          aria-label="calendar forward"
          className="rounded-md bg-indigo-600 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Clear
        </button>
      )}

      <div className="flex items-center justify-between pt-3 overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr>
              {weekdayHeader.map((day, index) => (
                <td key={index}>
                  <div className="w-full flex justify-center">
                    <p className="text-base font-medium text-center text-gray-800 dark:text-gray-100">
                      {
                        weekdayHeader[
                          (index + props.weekStartIndex) % DAYS_IN_WEEK
                        ]
                      }
                    </p>
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeksMatrix.map((week, index) => (
              <tr key={index}>
                {week.map((day, index) => (
                  <td key={index}>{dayCell(day)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
