import { displayTime } from "../../lib/utils";

type TimeParagraphProps = {
  date: Date | string | undefined | null;
};

export const TimeParagraph = (props: TimeParagraphProps) => {
  const parsedDate = typeof props.date === "string" || props.date instanceof String ? new Date(props.date) : props.date;
  return (
    <p>
      {parsedDate && (
        <time dateTime={parsedDate.toISOString()}>
          {displayTime(parsedDate)}
        </time>
      )}
    </p>
  );
};
