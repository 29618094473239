import { format } from "date-fns";
import { enUS, de } from "date-fns/locale";
import i18n from "../i18n";

export const getLocale = () => {
  return localStorage.getItem("locale") || "en-US";
};

export const getDateFnsLocale = () => {
  return getLocale() === "de-DE" ? de : enUS;
};

export const displayDateTime = (date: Date | string, locale?: string) => {
  const parsedDate =
    typeof date === "string" || date instanceof String ? new Date(date) : date;
  const parsedLocale = locale ? locale : i18n.language;
  return new Intl.DateTimeFormat(parsedLocale, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(parsedDate);
};

export const displayShortDateTime = (date: Date | string, locale?: string) => {
  const parsedDate =
    typeof date === "string" || date instanceof String ? new Date(date) : date;
  const parsedLocale = locale ? locale : i18n.language;
  return new Intl.DateTimeFormat(parsedLocale, {
    month: "short",
    day: "numeric",
  }).format(parsedDate);
}

export const displayDate = (date: Date | null | undefined, locale?: string) => {
  const parsedLocale = locale ? locale : i18n.language;
  return date
    ? new Intl.DateTimeFormat(parsedLocale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date)
    : "";
};

export const displayTime = (date: Date | undefined | null) => {
  return date ? format(date, "p", { locale: getDateFnsLocale() }) : "";
};

// hour is the standard reprentation of the hour (0-23) as a number
export const displayHour = (hour: number | undefined | null) => {
  if (hour) {
    const date = new Date(1970, 1, 1, hour);
    const formatString = getLocale() === "en-US" ? "h a" : "H";
    return format(date, formatString, { locale: getDateFnsLocale() });
  } else {
    return "";
  }
};

export const displayMonthAndYear = (date: Date | undefined | null) => {
  return date ? format(date, "MMMM yyyy") : "";
};

export const truncate = (text: string | null | undefined, length: number) => {
  if (text) {
    return text.length > length
      ? `${text.substring(0, length - 1)}\u{2026}`
      : text;
  } else {
    return "";
  }
};

export const removeKeys = (obj: any, keys: string[]): any => {
  if (obj !== Object(obj)) {
    return obj;
  } else {
    return Array.isArray(obj)
      ? obj.map((item) => removeKeys(item, keys))
      : Object.keys(obj)
          .filter((k) => !keys.includes(k))
          .reduce(
            (acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }),
            {}
          );
  }
};

export const okrStatusMapping: { [key: string]: string } = {
  on_track: "statusOnTrack",
  behind: "statusBehind",
  at_risk: "statusAtRisk",
  closed: "statusClosed",
  postponed: "statusPostponed",
  not_started: "statusNotStarted",
};

// export const unique = <T extends { id: string }>(a: Array<T>): Array<T> => {
//   return a.filter(({ id }, index, a) => a.findIndex((e) => id === e.id) === index);
// };
