import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";

type OKREmptyStateProps = {
  isEmpty: boolean | undefined;
};

export const OKREmptyState = (
  props: OKREmptyStateProps & PropsWithChildren
) => {
  const { t } = useTranslation();
  return (
    <>
      {props.isEmpty ? (
        <div className="flex items-center w-96">
          <div className="text-center">
            <span className="fa fa-trophy fa-4x text-gray-500"></span>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              { t("okrs.emptyStateTitle") }
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              { t("okrs.emptyStateDescriptionP1") }
            </p>
            <p className="mt-1 text-sm text-gray-500">
              { t("okrs.emptyStateDescriptionP2") }
            </p>
            <p className="mt-1 text-sm text-gray-500">
              { t("okrs.emptyStateDescriptionP3") }
            </p>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
