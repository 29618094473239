import { Outlet } from "react-router-dom";
import VisavysLogo from "../../assets/images/visavys_logo.svg"
import VisavysName from "../../assets/images/visavys_name.svg"
import {websiteUrl, webUrl} from "../../constants/apiUrls"
import { useTranslation } from "react-i18next";

type NavigationLink = {
  title: string;
  url: string;
};

export const SharedLayout = () => {
  const { t } = useTranslation();
  const topNav: NavigationLink[] = [
    { title: t("navigation.productLabel"), url: `${websiteUrl}/product/` },
    { title: t("navigation.featuresLabel"), url: `${websiteUrl}/features/` },
    { title: t("navigation.blogLabel"), url: `${websiteUrl}/blog/` },
  ]
  const solutionsLinks: NavigationLink[] = [
    { title: t("navigation.teamManagementLabel"), url: `${websiteUrl}/team-management/` },
    {
      title: t("navigation.meetingManagementLabel"),
      url: `${websiteUrl}/meeting-management/`,
    },
  ];
  const supportLinks: NavigationLink[] = [
    { title: t("navigation.pricingLabel"), url: `${websiteUrl}/pricing/` },
    { title: t("navigation.guidesLabel"), url: `${websiteUrl}/guides/` },
    { title: t("navigation.apiStatusLabel"), url: `${websiteUrl}/api-status/` },
  ];
  const companyLinks: NavigationLink[] = [
    { title: t("navigation.aboutLabel"), url: `${websiteUrl}/about/` },
    { title: t("navigation.blogLabel"), url: `${websiteUrl}/blog/` },
    { title: t("navigation.jobsLabel"), url: `${websiteUrl}/jobs/` },
  ];
  const legalLinks: NavigationLink[] = [
    { title: t("navigation.termsLabel"), url: `${websiteUrl}/terms/` },
    { title: t("navigation.cookiePolicyLabel"), url: `${websiteUrl}/cookie-policy-eu/` },
    {
      title: t("navigation.privacyStatementLabel"),
      url: `${websiteUrl}/privacy-statement/`,
    },
    { title: t("navigation.privacyPolicyLabel"), url: `${websiteUrl}/privacy-policy/` },
  ];

  return (
    <>
      <header className="relative">
        <div className="bg-gray-900 pt-6">
          <nav
            className="relative mx-auto flex items-center justify-between px-4 sm:px-6 pb-6"
            aria-label="Global"
          >
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a href={websiteUrl}>
                  <span className="sr-only">{t("common.visavysName")}</span>
                  <img
                    src={VisavysLogo}
                    className="h-8 w-auto sm:h-10 bg-white"
                    alt={t("common.visavysLogo").toString()}
                  />
                </a>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {topNav.map((link) => (
                    <a
                      href={link.url}
                      key={link.title}
                      className="px-3 py-2 text-base font-medium text-gray-100 hover:text-gray-300"
                    >
                      {link.title}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6">
              <a
                href={`${webUrl}/login`}
                className="text-base font-medium text-white hover:text-gray-300"
              >
                {t("navigation.loginTitle")}
              </a>
              <a
                href={`${webUrl}/login`}
                className="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700"
              >
                {t("navigation.startFreeTrialLabel")}
              </a>
            </div>
          </nav>
        </div>
      </header>

      <article className="relative pt-10">
        <Outlet />
      </article>

      <footer className="bg-gray-50 mt-6" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-md px-4 pt-12 sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-x-2 flex xl:col-span-1">
              <img
                src={VisavysLogo}
                className="h-10"
                alt="Visavys Logo"
              />
              <img
                src={VisavysName}
                className="h-10"
                alt="Visavys Name"
              />
              <p className="text-base text-gray-500"></p>
              <div className="flex space-x-6"></div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-gray-900">
                    {t("navigation.solutionsLabel")}
                  </h3>
                    <ul className="mt-4 space-y-4">
                      {solutionsLinks.map((link) => (
                        <li
                          className="text-base text-gray-500 hover:text-gray-900"
                          key={link.title}
                        >
                          <a href={link.url}>{link.title}</a>
                        </li>
                      ))}
                    </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-gray-900">
                    {t("navigation.supportLabel")}
                  </h3>
                    <ul className="mt-4 space-y-4">
                      {supportLinks.map((link) => (
                        <li
                          className="text-base text-gray-500 hover:text-gray-900"
                          key={link.title}
                        >
                          <a href={link.url}>{link.title}</a>
                        </li>
                      ))}
                    </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-gray-900">
                    {t("navigation.companyLabel")}
                  </h3>
                    <ul className="mt-4 space-y-4">
                      {companyLinks.map((link) => (
                        <li
                          className="text-base text-gray-500 hover:text-gray-900"
                          key={link.title}
                        >
                          <a href={link.url}>{link.title}</a>
                        </li>
                      ))}
                    </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-gray-900">Legal</h3>
                  <ul className="mt-4 space-y-4">
                    {legalLinks.map((link) => (
                      <li
                        className="text-base text-gray-500 hover:text-gray-900"
                        key={link.title}
                      >
                        <a href={link.url}>{link.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-200 py-8">
          <p className="text-base text-gray-400 xl:text-center">
            {t("navigation.copyrightStatement", {year: new Date().getFullYear()})}
          </p>
        </div>
      </footer>
    </>
  );
};
