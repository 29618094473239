import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "jsonapi-react";
import { useState, useEffect } from "react";
import { User, Organization } from "../lib/types";
import { useParams } from "react-router-dom";
import { notifyApiErrors } from "../lib/toasts";
import { UserList } from "../components/elements/forms/UserList";
import { UserCombobox } from "../components/elements/forms/UserCombobox";
import { MemberDetail } from "../components/organizations/MemberDetail";
import classNames from "classnames";
import { useAppSelector } from "../app/hooks";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";

export const OrganizationDetail = () => {
  const tabs = [
    { name: "Edit Organization", href: "#", current: true },
    { name: "Manage Teams", href: "/teams", current: false },
    { name: "Manage Goals", href: "/okrs", current: false },
    { name: "Analyze Reports", href: "/report", current: false },
  ];
  const auth = useAppSelector((state) => state.auth);
  const params = useParams();
  const organizationId = params.organizationId;
  const { t } = useTranslation();
  const organizationQuery = useQuery(auth.currentUser && ["organizations", organizationId]);
  const membersQuery = useQuery(auth.currentUser && ["organizations", organizationId, "users"]);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [members, setMembers] = useState<User[]>([]);
  const [owner, setOwner] = useState<User | null | undefined>();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userDetailOpen, setUserDetailOpen] = useState(false);

  useEffect(() => {
    if (organizationQuery.data) {
      const theOrganization = organizationQuery.data as Organization;
      setOrganization(theOrganization);
      if (name === undefined && theOrganization) {
        setName(theOrganization.name);
      }
      if (owner === undefined && theOrganization) {
        setOwner(theOrganization.owner);
      }
    }
    notifyApiErrors(organizationQuery.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationQuery.data, organizationQuery.errors]);

  useEffect(() => {
    if (membersQuery.data) {
      setMembers(membersQuery.data as User[]);
    }
    notifyApiErrors(membersQuery.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersQuery.data, membersQuery.errors]);

  const [addUser] = useMutation(["organizations", organizationId, "users"], {
    invalidate: ["organizations", "users"],
  });
  const [editOrganization] = useMutation(["organizations", organizationId], {
    invalidate: ["organizations", "users"],
  });

  const handleSubmitUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errors } = await addUser({
      email,
    });
    notifyApiErrors(errors);
  };
  const handleSubmitOrganization = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const { errors } = await editOrganization({
      name,
      ownerId: owner?.id,
    });
    notifyApiErrors(errors, t("organizations.title"));
  };
  const [email, setEmail] = useState("");
  const [name, setName] = useState<string | undefined>(undefined);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onChangeName = (value: string) => {
    setName(value);
  };

  const handleOnClickUser = (user: User) => {
    setSelectedUser(user);
    setUserDetailOpen(true);
  };

  return (
    <LoadingIndicator isLoading={!auth.currentUser}>
      <div className="isolate w-1/2 bg-white px-6 py-3 lg:px-8">
        {userDetailOpen && (
          <MemberDetail
            currentUser={auth.currentUser}
            selectedUser={selectedUser}
            open={userDetailOpen}
            setOpen={setUserDetailOpen}
          />
        )}
        <div className="mx-auto max-w-2xl text-center mb-2">
          <h2 className="text-xl font-bold tracking-tight text-gray-900">
            {organization?.name}
            {/* {t("organizations.title")} */}
          </h2>
        </div>
              <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={tabs.find((tab) => tab.current)?.name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "bg-gray-100 text-gray-700"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-md px-3 py-2 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <form className="mt-5" onSubmit={handleSubmitOrganization}>
          <div className="w-full sm:max-w-xs">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("organizations.nameLabel")}
            </label>

            <input
              type="text"
              onChange={(e) => onChangeName(e.target.value)}
              name="name"
              id="name"
              className="block mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={name}
              placeholder={t("organizations.namePlaceholder").toString()}
            />
          </div>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <UserCombobox
              selectedUser={owner}
              setSelectedUser={setOwner}
              title={t("okrs.ownerLabel")}
            />
          </div>
          <button
            type="submit"
            className="w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-3 sm:w-auto sm:text-sm"
          >
            {t("menu.save")}
          </button>
        </form>
        <h3 className="text-lg font-medium leading-6 mt-6 text-gray-900">
          {t("organizations.membersLabel")}
        </h3>
        <form
          className="mt-5 sm:flex sm:items-center"
          onSubmit={handleSubmitUser}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              {t("organizations.emailLabel")}
            </label>
            <input
              type="email"
              onChange={(e) => onChangeEmail(e.target.value)}
              name="email"
              id="email"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder={t("organizations.emailPlaceholder").toString()}
            />
          </div>
          <button
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("organizations.addUserButtonLabel")}
          </button>
        </form>
        <UserList
          onClick={handleOnClickUser}
          users={members}
        />
      </div>
    </LoadingIndicator>
  );
};
