import {
  wrapIn,
  setBlockType,
  chainCommands,
  toggleMark,
  exitCode,
  joinUp,
  joinDown,
  lift,
  selectParentNode,
  deleteSelection,
  joinBackward,
  selectNodeBackward,
} from "prosemirror-commands";
import {
  splitListItem,
  liftListItem,
  sinkListItem,
} from "prosemirror-schema-list";
import { undo, redo } from "prosemirror-history";
import { undoInputRule } from "prosemirror-inputrules";
import { Command } from "prosemirror-state";
import { Schema } from "prosemirror-model";
import {
  indentBy,
  indentOnEnter,
  enterAtBeginningOfLine,
  enterAtEndOfLine,
  setEmptyBlockToParagraph,
  mergeWithPreviousEmptyBlock,
  removeEmptyBlockBefore,
  convertToParagraphItem,
  unindentBlock,
  deleteOnEmptyDocument
} from "../commands";

const mac =
  typeof navigator != "undefined"
    ? /Mac|iP(hone|[oa]d)/.test(navigator.platform)
    : false;

/// Inspect the given schema looking for marks and nodes from the
/// basic schema, and if found, add key bindings related to them.
/// This will add:
///
/// * **Mod-b** for toggling [strong](#schema-basic.StrongMark)
/// * **Mod-i** for toggling [emphasis](#schema-basic.EmMark)
/// * **Mod-`** for toggling [code font](#schema-basic.CodeMark)
/// * **Ctrl-Shift-0** for making the current textblock a paragraph
/// * **Ctrl-Shift-1** to **Ctrl-Shift-Digit6** for making the current
///   textblock a heading of the corresponding level
/// * **Ctrl-Shift-Backslash** to make the current textblock a code block
/// * **Ctrl-Shift-8** to wrap the selection in an ordered list
/// * **Ctrl-Shift-9** to wrap the selection in a bullet list
/// * **Ctrl->** to wrap the selection in a block quote
/// * **Enter** to split a non-empty textblock in a list item while at
///   the same time splitting the list item
/// * **Mod-Enter** to insert a hard break
/// * **Mod-_** to insert a horizontal rule
/// * **Backspace** to undo an input rule
/// * **Alt-ArrowUp** to `joinUp`
/// * **Alt-ArrowDown** to `joinDown`
/// * **Mod-BracketLeft** to `lift`
/// * **Escape** to `selectParentNode`
///
/// You can suppress or map these bindings by passing a `mapKeys`
/// argument, which maps key names (say `"Mod-B"` to either `false`, to
/// remove the binding, or a new key name string.
export function buildKeymap(
  schema: Schema,
  mapKeys?: { [key: string]: false | string }
) {
  let keys: { [key: string]: Command } = {},
    type;
  function bind(key: string, cmd: Command) {
    if (mapKeys) {
      let mapped = mapKeys[key];
      if (mapped === false) return;
      if (mapped) key = mapped;
    }
    keys[key] = cmd;
  }

  bind("Mod-z", undo);
  bind("Shift-Mod-z", redo);
  bind("Mod-Backspace", chainCommands(deleteOnEmptyDocument()))
  bind(
    "Backspace",
    chainCommands(
      deleteOnEmptyDocument(),
      deleteSelection,
      unindentBlock(),
      convertToParagraphItem(),
      joinBackward,
      selectNodeBackward,
      removeEmptyBlockBefore(),
      mergeWithPreviousEmptyBlock(),
      undoInputRule
    )
  );
  if (!mac) bind("Mod-y", redo);

  bind("Alt-ArrowUp", joinUp);
  bind("Alt-ArrowDown", joinDown);
  bind("Mod-BracketLeft", lift);
  bind("Escape", selectParentNode);

  bind("Tab", indentBy(1));
  bind("Shift-Tab", indentBy(-1));

  type = schema.marks.strong;
  if (type) {
    bind("Mod-b", toggleMark(type));
    bind("Mod-B", toggleMark(type));
  }
  type = schema.marks.em;
  if (type) {
    bind("Mod-i", toggleMark(type));
    bind("Mod-I", toggleMark(type));
  }
  type = schema.marks.code;
  if (type) {
    bind("Mod-`", toggleMark(type));
  }

  // type = schema.nodes.bullet_list;
  // if (type) {
  //   bind("Shift-Ctrl-8", wrapInList(type));
  // }
  // type = schema.nodes.ordered_list;
  // if (type) {
  //   bind("Shift-Ctrl-9", wrapInList(type));
  // }
  type = schema.nodes.blockquote;
  if (type) {
    bind("Ctrl->", wrapIn(type));
  }
  type = schema.nodes.hard_break;
  if (type) {
    let br = type,
      cmd = chainCommands(exitCode, (state, dispatch) => {
        if (dispatch)
          dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
        return true;
      });
    bind("Mod-Enter", cmd);
    bind("Shift-Enter", cmd);
    bind(
      "Enter",
      chainCommands(
        indentOnEnter(),
        setEmptyBlockToParagraph(),
        enterAtBeginningOfLine,
        enterAtEndOfLine,
        splitListItem(type)
      )
    );
    if (mac) bind("Ctrl-Enter", cmd);
  }
  type = schema.nodes.list_item;
  if (type) {
    bind("Mod-[", liftListItem(type));
    bind("Mod-]", sinkListItem(type));
  }
  type = schema.nodes.paragraph_item;
  if (type) {
    bind("Shift-Ctrl-0", setBlockType(type));
  }
  type = schema.nodes.discussion_item;
  if (type) {
    bind("Shift-Ctrl-8", setBlockType(type));
  }
  type = schema.nodes.action_item;
  if (type) {
    bind("Shift-Ctrl-9", setBlockType(type));
  }
  type = schema.nodes.code_block;
  if (type) {
    bind("Shift-Ctrl-\\", setBlockType(type));
  }
  type = schema.nodes.heading;
  if (type) {
    for (let i = 1; i <= 6; i++)
      bind("Shift-Ctrl-" + i, setBlockType(type, { level: i }));
  }
  type = schema.nodes.horizontal_rule;
  if (type) {
    let hr = type;
    bind("Mod-_", (state, dispatch) => {
      if (dispatch)
        dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
      return true;
    });
  }

  return keys;
}
