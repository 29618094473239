import { useQuery } from "jsonapi-react";
import { ActionItem, User } from "../lib/types";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../lib/toasts";
import { useEffect, useState, Fragment } from "react";
import { ActionItemEmptyState } from "../components/actionItems/ActionItemEmptyState";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";
import { Dialog, Menu, Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ActionItemColumn } from "../components/actionItems/ActionItemColumn";
import { UserCombobox } from "../components/elements/forms/UserCombobox";
import { Calendar } from "../components/elements/Calendar";
import { displayDate, displayShortDateTime } from "../lib/utils";

const sortOptions = ["date", "status"]
const statusFilterOptions = ["all", "open", "done", "wontDo"]

export const ActionItemList = () => {
  const { t } = useTranslation();
  const [sortCriteria, setSortCriteria] = useState<string>("date");
  const [createdByFilter, setCreatedByFilter] = useState<
    User | null | undefined
  >(null);
  const [assignedToFilter, setAssignedToFilter] = useState<
    User | null | undefined
  >(null);
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [fromFilter, setFromFilter] = useState<Date | null | undefined>(null);
  const [untilFilter, setUntilFilter] = useState<Date | null | undefined>(null);
  const [isOpenFromCalendar, setIsOpenFromCalendar] = useState<boolean>(false);
  const [isOpenUntilCalendar, setIsOpenUntilCalendar] =
    useState<boolean>(false);
  const actionItemsPrepareQuery = useQuery([
    "action_items",
    {
      include: ["assignee", "creator"],
      filter: {
        status: statusFilter,
        creatorId: createdByFilter?.id,
        // no assignee as it is always for the current user
        column: "prepare",
        from: fromFilter,
        until: untilFilter
      },
    },
  ]);

  const actionItemsPendingQuery = useQuery([
    "action_items",
    {
      include: ["assignee", "creator"],
      filter: {
        status: statusFilter,
        creatorId: createdByFilter?.id,
        // no assignee as it is always for the current user
        column: "pending",
        from: fromFilter,
        until: untilFilter
      },
    },
  ]);

  const actionItemsFollowUpQuery = useQuery([
    "action_items",
    {
      include: ["assignee", "creator"],
      filter: {
        status: statusFilter,
        // no creator as it is always for the current user
        assigneeId: assignedToFilter?.id,
        column: "follow_up",
        from: fromFilter,
        until: untilFilter
      },
    },
  ]);

  const [actionItemsPrepare, setActionItemsPrepare] = useState<ActionItem[]>(
    []
  );

  useEffect(() => {
    if (actionItemsPrepareQuery.data) {
      setActionItemsPrepare(actionItemsPrepareQuery.data as ActionItem[]);
    }
    notifyApiErrors(actionItemsPrepareQuery.errors);
  }, [actionItemsPrepareQuery.data, actionItemsPrepareQuery.errors]);

  const [actionItemsPending, setActionItemsPending] = useState<ActionItem[]>(
    []
  );

  useEffect(() => {
    if (actionItemsPendingQuery.data) {
      setActionItemsPending(actionItemsPendingQuery.data as ActionItem[]);
    }
    notifyApiErrors(actionItemsPendingQuery.errors);
  }, [actionItemsPendingQuery.data, actionItemsPendingQuery.errors]);

  const [actionItemsFollowUp, setActionItemsFollowUp] = useState<ActionItem[]>(
    []
  );

  useEffect(() => {
    if (actionItemsFollowUpQuery.data) {
      setActionItemsFollowUp(actionItemsFollowUpQuery.data as ActionItem[]);
    }
    notifyApiErrors(actionItemsFollowUpQuery.errors);
  }, [actionItemsFollowUpQuery.data, actionItemsFollowUpQuery.errors]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <section className="flex h-screen min-w-0 flex-1 flex-col xl:order-last m-6">
        <h1 className="text-xl font-medium text-gray-900 mb-6">
          {t("actionItems.title")}
        </h1>
        <LoadingIndicator isLoading={actionItemsPrepareQuery.isLoading}>
          <ActionItemEmptyState
            isEmpty={false} //{actionItemsPrepareQuery.data && actionItemsPrepare.length === 0}
          >
            {/* Mobile filter dialog */}
            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 sm:hidden"
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          {t("actionItems.filtersLabel")}
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">{t("menu.closeMenu")}</span>
                          <span
                            className="fa fa-times h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>

                      {/* Filters */}
                      {/* <form className="mt-4">
                        {filters.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.name}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      <span
                                        className={classNames(
                                          open ? "-rotate-180" : "rotate-0",
                                          "fa fa-chevron-down h-5 w-5 transform"
                                        )}
                                        aria-hidden="true"
                                      ></span>
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {section.options.map(
                                      (option, optionIdx) => (
                                        <div
                                          key={option.value}
                                          className="flex items-center"
                                        >
                                          <input
                                            id={`filter-mobile-${section.id}-${optionIdx}`}
                                            name={`${section.id}[]`}
                                            defaultValue={option.value}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          />
                                          <label
                                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                            className="ml-3 text-sm text-gray-500"
                                          >
                                            {option.label}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form> */}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="w-full text-center">
              <section
                aria-labelledby="filter-heading"
                className="border-t border-gray-200 py-6"
              >
                <h2 id="filter-heading" className="sr-only">
                  {t("actionItems.filtersLabel")}
                </h2>

                <div className="flex items-center justify-between">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        {t("actionItems.sortByLabel")} {t(`actionItems.${sortCriteria}Sort`)}
                        <span
                          className="fa fa-chevron-down -mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        ></span>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortOptions.map((value) => (
                            <Menu.Item key={value}>
                              {({ active }) => (
                                <button
                                  onClick={() => setSortCriteria(value)}
                                  value={sortCriteria}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm font-medium text-gray-900 w-full text-left"
                                  )}
                                >
                                  {t(`actionItems.${value}Sort`)}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <button
                    type="button"
                    className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                    onClick={() => setOpen(true)}
                  >
                    {t("actionItems.filtersLabel")}
                  </button>

                  <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
                    <Popover
                      as="div"
                      key="createdBy"
                      id={`desktop-menu-0`}
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          <span>
                            {t("actionItems.createdByLabel")}{" "}
                            {createdByFilter
                              ? createdByFilter.displayName
                              : t("actionItems.anybodyLabel")}
                          </span>
                          <span
                            className="fa fa-chevron-down -mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          ></span>
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute w-60 right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <UserCombobox
                            selectedUser={createdByFilter}
                            setSelectedUser={setCreatedByFilter}
                            title=""
                            placeholder={t("actionItems.createdByPlaceholder").toString()}
                          />
                        </Popover.Panel>
                      </Transition>
                    </Popover>

                    <Popover
                      as="div"
                      key="assignedTo"
                      id={`desktop-menu-1`}
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          <span>
                            {t("actionItems.assignedToLabel")} {" "}
                            {assignedToFilter
                              ? assignedToFilter.displayName
                              : t("actionItems.anybodyLabel")}
                          </span>
                          <span
                            className="fa fa-chevron-down -mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          ></span>
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute w-60 right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <UserCombobox
                            selectedUser={assignedToFilter}
                            setSelectedUser={setAssignedToFilter}
                            title=""
                            placeholder={t("actionItems.assignedToPlaceholder").toString()}
                          />
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          {t("actionItems.statusLabel")} {t(`actionItems.${statusFilter}Status`)}
                          <span
                            className="fa fa-chevron-down -mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          ></span>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-52 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {statusFilterOptions.map((value) => (
                              <Menu.Item key={value}>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      setStatusFilter(value)
                                    }
                                    value={statusFilter}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm font-medium text-gray-900 w-full text-left"
                                    )}
                                  >
                                    {t(`actionItems.${value}Status`)}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Popover
                      as="div"
                      key="timePeriod"
                      id={`desktop-menu-1`}
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          <span>
                            {t("actionItems.timePeriodLabel")}{" "}
                            {fromFilter || untilFilter
                              ? `${fromFilter ? displayShortDateTime(fromFilter) : t("actionItems.anyTimeLabel")} - ${
                                  untilFilter ? displayShortDateTime(untilFilter) : t("actionItems.anyTimeLabel")
                                }`
                              : t("actionItems.anyTimeLabel")}
                          </span>
                          <span
                            className="fa fa-chevron-down -mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          ></span>
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute w-96 right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <label
                            htmlFor="fromFilter"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {t("actionItems.fromLabel")}
                          </label>
                          <button
                            type="button"
                            name="fromFilter"
                            id="fromFilter"
                            onClick={() =>
                              setIsOpenFromCalendar(!isOpenFromCalendar)
                            }
                            className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                          >
                            {fromFilter ? displayDate(fromFilter) : t("actionItems.fromPlaceholder")}
                          </button>
                          {isOpenFromCalendar && (
                            <div className="bg-gray-100 p-3 right-0 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                              <Calendar
                                selectedDate={fromFilter}
                                setSelectedDate={setFromFilter}
                                weekStartIndex={0}
                                open={isOpenFromCalendar}
                                setOpen={setIsOpenFromCalendar}
                                allowClear={true}
                              />
                            </div>
                          )}
                          <label
                            htmlFor="untilFilter"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {t("actionItems.untilLabel")}
                          </label>
                          <button
                            type="button"
                            name="untilFilter"
                            id="untilFilter"
                            onClick={() =>
                              setIsOpenUntilCalendar(!isOpenUntilCalendar)
                            }
                            className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                          >
                            {untilFilter ? displayDate(untilFilter) : t("actionItems.untilPlaceholder")}
                          </button>
                          {isOpenUntilCalendar && (
                            <div className="bg-gray-100 p-3 right-0 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                              <Calendar
                                selectedDate={untilFilter}
                                setSelectedDate={setUntilFilter}
                                weekStartIndex={0}
                                open={isOpenUntilCalendar}
                                setOpen={setIsOpenUntilCalendar}
                                allowClear={true}
                              />
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  </Popover.Group>
                </div>
              </section>
            </div>

            <div className="flex">
              <ActionItemColumn
                actionItems={actionItemsPrepare}
                title={t("actionItems.prepareColumnTitle")}
              />
              <ActionItemColumn
                actionItems={actionItemsPending
                .sort((a, b) => {
                  if (a.event?.startAt && b.event?.startAt) {
                    return a.event.startAt > b.event.startAt
                      ? -1
                      : a.event.startAt === b.event.startAt
                      ? 0
                      : 1;
                  } else {
                    return a.event?.startAt ? 1 : -1;
                  }
                })
                }
                title={t("actionItems.pendingColumnTitle")}
              />
              <ActionItemColumn
                actionItems={actionItemsFollowUp
                .sort((a, b) => {
                  if (a.event?.startAt && b.event?.startAt) {
                    return a.event.startAt > b.event.startAt
                      ? -1
                      : a.event.startAt === b.event.startAt
                      ? 0
                      : 1;
                  } else {
                    return a.event?.startAt ? 1 : -1;
                  }
                })}
                title={t("actionItems.followUpColumnTitle")}
              />
            </div>
          </ActionItemEmptyState>
        </LoadingIndicator>
      </section>
    </>
  );
};
