import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ApiClient, ApiProvider } from "jsonapi-react";
import { AllRoutes } from "./routes/AllRoutes";
import { store } from "./app/store";
import { JsonApiSchema } from "./lib/types";
import { apiUrl, apiEndpoint } from "./constants/apiUrls";
import { getAuthToken } from "./app/authService"
import { Toaster } from "react-hot-toast";

function App() {
  const apiClient = new ApiClient({
    url: `${apiUrl}${apiEndpoint}`,
    schema: JsonApiSchema,
  });

  const authToken = getAuthToken();
  authToken && apiClient.addHeader("Authorization", "Bearer " + getAuthToken());

  return (
    <Provider store={store}>
      <ApiProvider client={apiClient}>
        <BrowserRouter>
          <AllRoutes />
          <Toaster />
        </BrowserRouter>
      </ApiProvider>
    </Provider>
  );
}
export default App;
