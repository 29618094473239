import { useState } from "react";
import { Event } from "../../lib/types";
import { EditEventModal } from "./EditEventModal";
import { useTranslation } from "react-i18next";
import { webUrl } from "../../constants/apiUrls";
import { displayErrors, notifySuccess } from "../../lib/toasts";
import { useMutation } from "jsonapi-react";

type DropdownMeetingMoreActionsProps = {
  currentEvent: Event;
};

export const DropdownMeetingMoreActions = (
  props: DropdownMeetingMoreActionsProps
) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [editEventIsOpen, setEditEventIsOpen] = useState(false);
  const [editEvent, eventMutationQuery] = useMutation(
    ["events", props.currentEvent?.id],
    {
      invalidate: ["events"],
    }
  );
  const itemBaseCssClass =
    " flex w-full justify-between px-4 py-2 text-sm  hover:bg-indigo-600 hover:text-white";
  // const itemActive = "bg-gray-100 text-gray-900";
  const itemNotActive = "text-gray-700";
  const itemCssClass = itemNotActive + itemBaseCssClass;
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      `${webUrl}/meetings/${props.currentEvent.id}`
    );
    notifySuccess(t("meetings.notificationSuccessUrlCopied"));
    setIsOpen(false);
  };
  const handleShareLink = async () => {
    if (props.currentEvent.sharedToken) {
      await editEvent({
        shareLink: -1,
      });
      notifySuccess(t("meetings.notificationSuccessShareLinkDisabled"));
    } else {
      const result = await editEvent({
        shareLink: 30,
      });
      const newEvent = result.data as Event;
      navigator.clipboard.writeText(`${webUrl}/shared/meetings/${newEvent.sharedToken}`)
      notifySuccess(t("meetings.notificationSuccessShareLinkEnabled"));
      displayErrors(eventMutationQuery)
    }
    setIsOpen(false);
  };
  const handleCopyShareLink = () => {
    navigator.clipboard.writeText(
      `${webUrl}/shared/meetings/${props.currentEvent.sharedToken}`
    );
    notifySuccess(t("meetings.notificationSuccessShareLinkCopied"));
    setIsOpen(false);
  };

  const hasValidSharedLink = () => {
    return props.currentEvent?.sharedTokenExpiry
      ? props.currentEvent.sharedToken &&
          new Date(props.currentEvent.sharedTokenExpiry) > new Date()
      : false;
  };

  return (
    <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
      {editEventIsOpen && (
        <EditEventModal
          currentEvent={props.currentEvent}
          setIsOpen={setEditEventIsOpen}
        />
      )}
      <button
        onClick={() => setEditEventIsOpen(!editEventIsOpen)}
        className="inline-flex items-center rounded-full border-indigo-800 border bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800"
      >
        {t("meetings.editEvent")}
      </button>
      <div className="relative ml-3 inline-block text-left">
        <div>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="-my-2 flex items-center rounded-full p-2 text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600"
            id="menu-3-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <span className="sr-only">{t("meetings.openOptions")}</span>
            {/* <!-- Heroicon name: mini/ellipsis-vertical --> */}
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
            </svg>
          </button>
        </div>

        {isOpen && (
          <div
            className="absolute right-0 bg-white z-20 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-3-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              <button
                type="button"
                onClick={() => {
                  handleCopyUrl();
                }}
                className={itemCssClass}
                role="menuitem"
                tabIndex={-1}
                id="menu-3-item-1"
              >
                <span>{t("meetings.copyUrl")}</span>
              </button>
            </div>
            <div className="py-1" role="none">
              <button
                type="button"
                onClick={() => {
                  handleShareLink();
                }}
                className={itemCssClass}
                role="menuitem"
                tabIndex={-1}
                id="menu-3-item-1"
              >
                <span>
                  {hasValidSharedLink()
                    ? t("meetings.disableShareLink")
                    : t("meetings.enableShareLink")}
                </span>
              </button>
            </div>
            {hasValidSharedLink() && (
              <div className="py-1" role="none">
                <button
                  type="button"
                  onClick={() => {
                    handleCopyShareLink();
                  }}
                  className={itemCssClass}
                  role="menuitem"
                  tabIndex={-1}
                  id="menu-3-item-1"
                >
                  <span>{t("meetings.copyShareLink")}</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
