import { ActionItem } from "../../lib/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { displayShortDateTime } from "../../lib/utils";
import classNames from "classnames";

type ActionItemColumnProps = {
  actionItems: ActionItem[];
  title: string;
};

export const ActionItemColumn = (props: ActionItemColumnProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex-1 bg-white border rounded-lg mr-3">
      <div className="border-b border-gray-200 bg-white px-4 mt-2 py-5 sm:px-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {props.title}
        </h3>
      </div>
      <ul className="divide-y divide-gray-200 px-4">
        {props.actionItems.map((actionItem) => (
          <li key={actionItem.id} className="flex items-start text-left w-full py-4">
            <button
              className="flex items-start text-left w-full"
              onClick={() => navigate(`/meetings/${actionItem.event.id}`)}
            >
            <input
              type="checkbox"
              className="h-4 w-4 squared border-green-600 border-2 text-green-600 ring-green-500"
              readOnly={true}
              checked={actionItem.done === true}
            />
            <div className="ml-3 w-full">
              <p
                className={classNames(
                  actionItem.wontDo ? "line-through" : "",
                  "text-md font-medium text-gray-900"
                )}
              >
                {actionItem.content}
              </p>
              <p className="text-sm text-gray-500" title="Event">
                {actionItem.event.summary}{" "}
                {actionItem.event?.startAt &&
                  `(${displayShortDateTime(actionItem.event.startAt)})`}
              </p>
              <p className="text-sm text-gray-500 w-full">
                {actionItem.creator && (
                  <img
                    className="inline h-6 w-6 mr-2 rounded-full bg-gray-50"
                    src={actionItem.creator.image}
                    alt=""
                    title={`${t("actionItems.createdByLabel")} ${actionItem.creator.displayName}`}
                  />
                )}
                {actionItem.dueAt && (
                  <span
                    title="Due at"
                    className="flex-inline mr-2 mt-2 items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-red-600/20"
                  >
                    <i className="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {displayShortDateTime(actionItem.dueAt)}
                  </span>
                )}
                {actionItem.assignee && (
                  <img
                    className="inline float-right h-6 w-6 rounded-full bg-gray-50"
                    src={actionItem.assignee.image}
                    alt=""
                    title={`${t("actionItems.assignedToLabel")} ${actionItem.assignee.displayName}`}
                  />
                )}
              </p>
            </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
