import { useQuery, useMutation } from "jsonapi-react";
import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { Calendar, Objective } from "../../lib/types";
import { useAppSelector } from "../../app/hooks";
import {
  startOfWeek,
  addDays,
  subDays,
  isSameDay,
  getUnixTime,
  roundToNearestMinutes,
  addMinutes,
  getWeek,
  differenceInMinutes,
  isEqual,
  startOfDay,
} from "date-fns";
import { Event, Attendee } from "../../lib/types";
import { EditEventModalEvent } from "./EditEventModalEvent";
import { SelectAttendees } from "./SelectAttendees";
import { TimeParagraph } from "../elements/TimeParagraph";
import {
  displayMonthAndYear,
  displayTime,
  displayDate,
  displayHour,
} from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../../lib/toasts";
import { Calendar as CalendarComponent } from "../elements/Calendar";
import { MultiSelect } from "../elements/forms/MultiSelect";

type EditEventModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  currentEvent: Event | null;
};

export const EditEventModal = (props: EditEventModalProps) => {
  const DAYS_IN_WEEK = 7;
  const MORNING_START = 7;
  const { t } = useTranslation();
  const summaryPlaceholder = t("meetings.summaryPlaceholder");
  const isNewEvent = () => {
    return !props.currentEvent;
  };
  const weekStartIndex = (): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
    const currentIndex = auth.currentUser ? auth.currentUser.weekStartIndex : 0;
    const weekStartIndex = (
      currentIndex >= 0 && currentIndex < DAYS_IN_WEEK ? currentIndex : 0
    ) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
    return weekStartIndex;
  };
  const auth = useAppSelector((state) => state.auth);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    props.currentEvent?.startAt || new Date()
  );
  const [isOpenSelectedDatePicker, setIsOpenSelectedDatePicker] =
    useState(false);
  const [isOpenStartAt, setIsOpenStartAt] = useState(false);
  const [isOpenEndAt, setIsOpenEndAt] = useState(false);
  const startOfWeekFor = (date: Date) => {
    return startOfWeek(date, { weekStartsOn: weekStartIndex() });
  };

  const beginningOfSelectedWeek = () => {
    const date = selectedDate ? selectedDate : new Date();
    return startOfWeekFor(date);
  };

  const endOfSelectedWeek = () => {
    return addDays(beginningOfSelectedWeek(), DAYS_IN_WEEK);
  };

  const isDateVisible = (date: Date) => {
    return isEqual(beginningOfSelectedWeek(), startOfWeekFor(date));
  };

  const fromFilter = () => {
    return getUnixTime(beginningOfSelectedWeek());
  };

  const untilFilter = () => {
    return getUnixTime(endOfSelectedWeek());
  };

  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [selectedObjectives, setSelectedObjectives] = useState<Objective[]>(
    props.currentEvent ? props.currentEvent.objectives : []
  );

  const objectivesQuery = useQuery(
    auth.currentUser?.currentOrganizationId && [
      "organizations",
      auth.currentUser.currentOrganizationId,
      "objectives",
      {},
    ]
  );

  useEffect(() => {
    if (objectivesQuery.data) {
      setObjectives(objectivesQuery.data as Objective[]);
    }
    notifyApiErrors(objectivesQuery.errors);
  }, [objectivesQuery.data, objectivesQuery.errors]);

  const eventsQuery = useQuery([
    "events",
    {
      filter: {
        from: fromFilter(),
        until: untilFilter(),
      },
      include: [],
    },
  ]);

  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (eventsQuery.data) {
      setEvents(eventsQuery.data as Event[]);
    }
    notifyApiErrors(eventsQuery.errors);
  }, [eventsQuery.data, eventsQuery.errors]);

  const gridRowsPerDay = 48;
  const hours = Array.from(Array(24).keys());
  const weekdayHeader = [
    t("calendar.sundayShort"),
    t("calendar.mondayShort"),
    t("calendar.tuesdayShort"),
    t("calendar.wednesdayShort"),
    t("calendar.thursdayShort"),
    t("calendar.fridayShort"),
    t("calendar.saturdayShort"),
  ];
  const isSelectedDay = (date: Date) => {
    return selectedDate ? isSameDay(selectedDate, date) : false;
  };

  const [summary, setSummary] = useState(
    isNewEvent() ? "" : props.currentEvent?.summary
  );
  const [description, setDescription] = useState(
    isNewEvent() ? "" : props.currentEvent?.description
  );
  const [startAt, setStartAt] = useState<Date | null | undefined>(
    isNewEvent()
      ? roundToNearestMinutes(new Date(), {
          nearestTo: 30,
          roundingMethod: "ceil",
        })
      : props.currentEvent?.startAt || new Date()
  );
  const [endAt, setEndAt] = useState<Date | null | undefined>(
    isNewEvent()
      ? addMinutes(startAt || new Date(), 60)
      : props.currentEvent?.endAt
  );
  const [calendarId, setCalendarId] = useState(
    auth.currentUser?.currentCalendarId
  );
  const [attendees, setAttendees] = useState<Attendee[]>(
    props.currentEvent?.attendees || []
  );
  const [isAllDay, setIsAllDay] = useState(
    (props.currentEvent?.isAllDay as boolean) || false
  );
  const [recurrence, setRecurrence] = useState("none");
  const [editEvent, { errors }] = useMutation(
    ["events", props.currentEvent?.id],
    {
      invalidate: ["events"],
    }
  );

  const calendarsQuery = useQuery(["calendars", {}]);

  const [calendars, setCalendars] = useState<Calendar[]>([]);

  useEffect(() => {
    if (calendarsQuery.data) {
      setCalendars(calendarsQuery.data as Calendar[]);
      notifyApiErrors(calendarsQuery.errors);
    }
  }, [calendarsQuery.data, calendarsQuery.errors]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const attendeeEmails = attendees.map((attendee) => attendee.email);
    await editEvent({
      summary,
      description,
      startAt,
      endAt,
      calendarId,
      attendeeEmails,
      recurrence,
      isAllDay,
      objectiveIds: selectedObjectives.map((o) => o.id),
    });
    props.setIsOpen(false);
  };

  const scroll = useCallback((node: any) => {
    node && node.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const durationInMinutes = () => {
    if (!endAt || !startAt) {
      return 0;
    }
    return differenceInMinutes(endAt, startAt);
  };
  const getMinutesToStartAt = () => {
    return startAt ? startAt.getMinutes() + startAt.getHours() * 60 : 0;
  };
  const gridRow = () => {
    return Math.ceil((gridRowsPerDay / 1440) * getMinutesToStartAt() + 2); // 24 * 60 * 2 = 2880 and 2 is the offset
  };

  const span = () => {
    return Math.ceil((gridRowsPerDay / 1440) * durationInMinutes()); // 24 * 60 * 2 = 2880
  };

  const timeOptionList = (interval: number, currentOffset: number | null) => {
    let items = [];
    const aDate = new Date(1970, 1, 1);
    for (let i = 0; i <= 1440; i += interval) {
      items.push(
        <li
          key={i}
          className={`py-2 p-3 ${
            currentOffset === i
              ? "font-semibold text-indigo-700"
              : "font-normal"
          }`}
          data-value={i}
          ref={currentOffset === i ? scroll : null}
        >
          {displayTime(addMinutes(aDate, i))}
        </li>
      );
    }
    return items;
  };

  // returns the minutes since midnight in the date
  const getMinuteOffset = (date: Date | null | undefined) => {
    if (date) {
      return date.getHours() * 60 + date.getMinutes();
    } else {
      return null;
    }
  };

  useEffect(() => {
    notifyApiErrors(errors);
  }, [errors]);

  const handleClickTime = (
    e: any,
    setDateAttribute: Dispatch<SetStateAction<Date | null | undefined>>,
    setIsOpen: Dispatch<SetStateAction<boolean>>
  ) => {
    if (e.target) {
      const target = e.target as HTMLButtonElement;
      if (target.nodeName === "LI") {
        const minuteOffset = parseInt(target.getAttribute("data-value") || "0");
        setDateAttribute(
          addMinutes(startOfDay(selectedDate || new Date()), minuteOffset)
        );
        setIsOpen(false);
      }
    }
  };

  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-20 overflow-y-auto">
        <div className="flex min-h-screen max-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-auto max-h-[95vh] min-w-[95%] rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-5/6 sm:p-6">
            <form onSubmit={handleSubmit}>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {isNewEvent()
                  ? t("meetings.createEvent")
                  : t("meetings.editEvent")}
              </h2>
              <div className="flex flex-row">
                <div className="flex-none w-96">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-10 mr-4 flex flex-col">
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                            <input
                              type="text"
                              name="summary"
                              id="summary"
                              value={summary}
                              onChange={(e) => setSummary(e.target.value)}
                              autoComplete="off"
                              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder={summaryPlaceholder}
                            />
                          </div>
                          <div className="mt-2">
                            <textarea
                              id="description"
                              name="description"
                              placeholder={t(
                                "meetings.descriptionPlaceholder"
                              ).toString()}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              rows={2}
                              className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                            ></textarea>
                          </div>
                          <div className="mt-2">
                            <div className="flex items-center">
                              <div className="flex items-center mr-2">
                                <span className="fa fa-clock-o"></span>
                              </div>
                              <div className="relative">
                                <button
                                  type="button"
                                  name="selectedDate"
                                  id="selectedDate"
                                  onClick={() =>
                                    setIsOpenSelectedDatePicker(
                                      !isOpenSelectedDatePicker
                                    )
                                  }
                                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs sm:leading-6"
                                  placeholder="2023-06-11"
                                >
                                  {displayDate(selectedDate)}
                                </button>
                                {isOpenSelectedDatePicker && (
                                  <div className="bg-gray-100 p-3 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                                    <CalendarComponent
                                      selectedDate={selectedDate}
                                      setSelectedDate={setSelectedDate}
                                      weekStartIndex={weekStartIndex()}
                                      open={isOpenSelectedDatePicker}
                                      setOpen={setIsOpenSelectedDatePicker}
                                    />
                                  </div>
                                )}
                              </div>
                              <span className="mx-2 text-gray-500"></span>
                              <div className="relative">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setIsOpenStartAt(!isOpenStartAt)
                                  }
                                  className="relative w-24 cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs sm:leading-6"
                                  aria-haspopup="listbox"
                                  aria-expanded="true"
                                  aria-labelledby="listbox-label"
                                >
                                  <span className="flex items-center">
                                    {displayTime(startAt)}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <svg
                                      className="h-5 w-5 text-gray-400"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </button>

                                {isOpenStartAt && (
                                  <ul
                                    onClick={(e) => {
                                      handleClickTime(
                                        e,
                                        setStartAt,
                                        setIsOpenStartAt
                                      );
                                    }}
                                    className="absolute z-10 mt-1 max-h-56 w-24 overflow-auto rounded-md bg-white text-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs"
                                    tabIndex={-1}
                                    role="listbox"
                                    aria-labelledby="listbox-label"
                                    aria-activedescendant="listbox-option-3"
                                  >
                                    {timeOptionList(
                                      30,
                                      getMinuteOffset(startAt)
                                    )}
                                  </ul>
                                )}
                              </div>
                              <span className="mx-2 text-gray-500">-</span>
                              <div className="relative">
                                <button
                                  type="button"
                                  onClick={() => setIsOpenEndAt(!isOpenEndAt)}
                                  className="relative w-24 cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs sm:leading-6"
                                  aria-haspopup="listbox"
                                  aria-expanded="true"
                                  aria-labelledby="listbox-label"
                                >
                                  <span className="flex items-center">
                                    {displayTime(endAt)}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <svg
                                      className="h-5 w-5 text-gray-400"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </button>

                                {isOpenEndAt && (
                                  <ul
                                    onClick={(e) => {
                                      handleClickTime(
                                        e,
                                        setEndAt,
                                        setIsOpenEndAt
                                      );
                                    }}
                                    className="absolute z-10 mt-1 max-h-56 w-24 overflow-auto rounded-md bg-white text-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs"
                                    tabIndex={-1}
                                    role="listbox"
                                    aria-labelledby="listbox-label"
                                    aria-activedescendant="listbox-option-3"
                                  >
                                    {timeOptionList(30, getMinuteOffset(endAt))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <span className="fa fa-globe"></span>
                            <span className="pl-2 text-sm">
                              (UTC+0200) Europe/Berlin
                            </span>
                          </div>
                        </div>

                        <div className="mt-2">
                          <label
                            htmlFor="recurrence"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            <span className="fa fa-repeat"></span>{" "}
                            {t("meetings.recurrence")}
                          </label>
                          <div className="mt-2">
                            <select
                              id="recurrence"
                              name="recurrence"
                              value={recurrence}
                              onChange={(e) => setRecurrence(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option value="none">
                                {t("meetings.recurrenceOptionNone")}
                              </option>
                              <option value="daily-all">
                                {t("meetings.recurrenceOptionDailyAll")}
                              </option>
                              <option value="daily-mofr">
                                {t("meetings.recurrenceOptionDailyMoFr")}
                              </option>
                              <option value="weekly">
                                {t("meetings.recurrenceOptionWeekly")}
                              </option>
                              <option value="bi-weekly">
                                {t("meetings.recurrenceOptionBiWeekly")}
                              </option>
                              <option value="monthly">
                                {t("meetings.recurrenceOptionMonthly")}
                              </option>
                              <option value="monthly-same-week">
                                {t("meetings.recurrenceOptionMonthlySameWeek")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="isAllDay"
                            name="isAllDay"
                            onChange={() => setIsAllDay(!isAllDay)}
                            checked={isAllDay}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label
                            htmlFor="isAllDay"
                            className="font-medium text-gray-900"
                          >
                            {t("meetings.allDay")}
                          </label>
                        </div>
                      </div>

                      <div className="mt-2">
                        <SelectAttendees
                          attendees={attendees}
                          setAttendees={setAttendees}
                        />
                      </div>

                      <div className="mt-2">
                        <MultiSelect
                          title="Objectives"
                          selectedValues={selectedObjectives}
                          setSelectedValues={setSelectedObjectives}
                          allValues={objectives}
                          displayValue={(o: Objective) => o.title}
                          by="id"
                        />
                      </div>

                      <div className="mt-2">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="calendarId"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {t("calendar.calendar")}
                          </label>
                          <div className="mt-2">
                            <select
                              id="calendarId"
                              name="calendarId"
                              value={auth.currentUser?.currentCalendarId}
                              onChange={(e) => setCalendarId(e.target.value)}
                              autoComplete="off"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              {calendars.map((calendar) => (
                                <option key={calendar.id} value={calendar.id}>
                                  {calendar.externalCalendarId}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 h-full overflow-y-auto">
                  <div className="flex flex-col">
                    <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
                      <h1 className="text-lg font-semibold text-gray-900">
                        <time dateTime="2022-01">
                          {displayMonthAndYear(selectedDate)}
                        </time>
                      </h1>
                      <div className="flex items-center">
                        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                          <button
                            type="button"
                            onClick={() =>
                              selectedDate &&
                              setSelectedDate(subDays(selectedDate, 7))
                            }
                            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                          >
                            <span className="sr-only">
                              {t("calendar.previousMonth")}
                            </span>
                            {/* <!-- Heroicon name: mini/chevron-left --> */}
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            type="button"
                            className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                          >
                            {`${t("calendar.calendarWeekShort")} ${getWeek(
                              beginningOfSelectedWeek()
                            )}`}
                          </button>
                          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                          <button
                            type="button"
                            onClick={() =>
                              selectedDate &&
                              setSelectedDate(addDays(selectedDate, 7))
                            }
                            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                          >
                            <span className="sr-only">
                              {t("calendar.nextMonth")}
                            </span>
                            {/* <!-- Heroicon name: mini/chevron-right --> */}
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </header>
                    <div className="isolate flex flex-auto flex-col overflow-auto max-h-[calc(95vh-15rem)] bg-white">
                      <div
                        style={{ width: ": 165%" } as React.CSSProperties}
                        className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
                      >
                        <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
                          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                            <div className="col-end-1 w-14"></div>
                            {weekdayHeader.map((weekday, index) => (
                              <div
                                key={index}
                                className="flex items-center justify-center py-3"
                              >
                                <span
                                  className={
                                    isSelectedDay(
                                      addDays(beginningOfSelectedWeek(), index)
                                    )
                                      ? "flex items-baseline"
                                      : ""
                                  }
                                >
                                  {weekdayHeader[
                                    (index + weekStartIndex()) % 7
                                  ] + " "}
                                  <span
                                    className={
                                      isSelectedDay(
                                        addDays(
                                          beginningOfSelectedWeek(),
                                          index
                                        )
                                      )
                                        ? "ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                                        : "items-center justify-center font-semibold text-gray-900"
                                    }
                                  >
                                    {addDays(beginningOfSelectedWeek(), index)
                                      .getDate()
                                      .toString()}
                                  </span>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="flex flex-auto">
                          <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100"></div>
                          <div className="grid flex-auto grid-cols-1 grid-rows-1">
                            {/* <!-- Horizontal lines --> */}
                            <div
                              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                              style={
                                {
                                  gridTemplateRows: `repeat(${
                                    gridRowsPerDay / 2
                                  }, minmax(3.5rem, 1fr))`,
                                } as React.CSSProperties
                              }
                            >
                              <div className="row-end-1 h-7"></div>
                              {hours.map((hour: number) => (
                                <div
                                  key={hour}
                                  ref={hour === MORNING_START ? scroll : null}
                                >
                                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    {displayHour(hour)}
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* <!-- Vertical lines --> */}
                            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                              <div className="col-start-1 row-span-full"></div>
                              <div className="col-start-2 row-span-full"></div>
                              <div className="col-start-3 row-span-full"></div>
                              <div className="col-start-4 row-span-full"></div>
                              <div className="col-start-5 row-span-full"></div>
                              <div className="col-start-6 row-span-full"></div>
                              <div className="col-start-7 row-span-full"></div>
                              <div className="col-start-8 row-span-full w-8"></div>
                            </div>

                            {/* <!-- Events --> */}
                            <ol
                              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                              style={
                                {
                                  gridTemplateRows: `1.75rem repeat(${gridRowsPerDay}, minmax(0, 1fr)) auto`,
                                } as React.CSSProperties
                              }
                            >
                              {selectedDate && isDateVisible(selectedDate) && (
                                <li
                                  className={`relative z-20 mt-px flex col-start-${
                                    ((selectedDate.getDay() -
                                      weekStartIndex()) %
                                      DAYS_IN_WEEK) +
                                    1
                                  }`}
                                  style={
                                    {
                                      gridRow: `${gridRow()} / span ${span()}`,
                                    } as React.CSSProperties
                                  }
                                >
                                  <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg  text-white bg-indigo-700 hover:bg-indigo-900 pt-1 pl-2 text-xs leading-5 ">
                                    <p className="order-1 font-semibold">
                                      {summary ? summary : summaryPlaceholder}
                                    </p>
                                    <TimeParagraph date={startAt} />
                                  </div>
                                </li>
                              )}
                              {events.map((event) => (
                                <EditEventModalEvent
                                  key={event.id}
                                  event={event}
                                  weekStartIndex={weekStartIndex()}
                                  gridRowsPerDay={gridRowsPerDay}
                                  isDateVisible={isDateVisible}
                                />
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => props.setIsOpen(false)}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("menu.cancel")}
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("menu.save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
