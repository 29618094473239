import { Event } from "../lib/types";
import { useQuery } from "jsonapi-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { notifyApiErrors } from "../lib/toasts";
import { ProseMirrorViewer } from "../components/editor/ProseMirrorViewer";

export const SharedMeeting = () => {
  const params = useParams();
  const currentEventQuery = useQuery([
    "shared_events",
    params.linkToken,
    {
      include: ["note", "owner", "attendees"],
    },
  ]);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);

  useEffect(() => {
    if (currentEventQuery.data) {
      setCurrentEvent(currentEventQuery.data as Event);
    }
    notifyApiErrors(currentEventQuery.errors);
  }, [currentEventQuery.data, currentEventQuery.errors]);

  return (
    <div className="px-4 sm:px-6 md:px-8">
      <div className="px-4 sm:px-6 md:px-8">
        <header className="entry-header">
          <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 dark:text-slate-200 md:text-3xl ">
            <a href="https://visavys.com/about/" rel="bookmark">
              {currentEvent?.summary}
            </a>
          </h1>{" "}
        </header>
        <section className="flex h-full flex-1 flex-col order-last bg-gray-100 px-5 py-3 border rounded-md">
          {currentEvent && (
            <ProseMirrorViewer
              docVersion={currentEvent.note?.docVersion || 0}
              doc={currentEvent.note?.doc || "{}"}
              docId={currentEvent.note?.docId || ""}
              participants={currentEvent.attendees}
            />
          )}
        </section>
      </div>
    </div>
  );
};
