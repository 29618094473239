import { CurrentUser, Event } from "../../lib/types";
import { useQuery } from "jsonapi-react";
import { useState, useEffect } from "react";
import { DropdownMeetingMoreActions } from "./DropdownMeetingMoreActions";
import { DropdownNoteMoreActions } from "../notes/DropdownNoteMoreActions";
import {
  ProseMirrorEditor,
  RefProseMirrorEditor,
} from "../editor/ProseMirrorEditor";
import { DocumentConfigContext } from "../DocumentConfigContext";
import { displayDateTime } from "../../lib/utils";
import { notifyApiErrors } from "../../lib/toasts";
import { useRef } from "react";
import { NoteTemplateModal } from "../notes/NoteTemplateModal";
import { LoadingIndicator } from "../elements/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { truncate } from "../../lib/utils";

type MeetingDetailProps = {
  eventId: string;
  currentUser: CurrentUser;
};

export const MeetingDetail = (props: MeetingDetailProps) => {
  const [t] = useTranslation();
  const editorRef = useRef<RefProseMirrorEditor>(null);
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);

  const event = useQuery([
    "events",
    props.eventId,
    {
      include: ["note", "owner", "attendees"],
    },
  ]);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);

  useEffect(() => {
    setCurrentEvent(event.data as Event);
    notifyApiErrors(event.errors);
  }, [event.data, event.errors]);

  return (
    <div className="min-h-0 flex-1">
      <LoadingIndicator isLoading={event.isLoading}>
        {currentEvent && (
          <div className="pt-5 pb-2">
            <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
              <div className="sm:w-0 sm:flex-1">
                <h1
                  id="message-heading"
                  className="text-xl font-medium text-gray-900"
                >
                  {currentEvent.summary}
                </h1>
              </div>
              <DropdownMeetingMoreActions currentEvent={currentEvent} />
            </div>
            {/* <!-- Thread section--> */}
            <ul className="space-y-2 py-2 sm:space-y-2 lg:px-4 bg-white mx-4 my-4 rounded">
              <li>
                <div className="sm:flex sm:items-baseline sm:justify-between">
                  <div className="sm:w-0 sm:flex-1">
                    <h3 className="text-base font-medium">
                      <span className="text-gray-900">
                        {currentEvent.summary}
                      </span>
                      {currentEvent.objectives.map((objective) => (
                        <span className="ml-3 border-0 rounded-md bg-indigo-300 px-2 py-0 text-center text-xs font-normal text-white shadow-sm hover:bg-gray-200">
                          {truncate(objective.title, 30)}
                        </span>
                      ))}
                      <p className="text-xs font-light leading-3 text-gray-500 dark:text-gray-300">
                        {currentEvent.startAt &&
                          displayDateTime(currentEvent.startAt)}
                      </p>
                    </h3>
                    <p className="mt-1 truncate text-sm text-gray-500">
                      {currentEvent.note?.owner?.displayName}
                    </p>
                  </div>
                  {isOpenTemplateModal && (
                    <NoteTemplateModal
                      setIsOpenTemplateModal={setIsOpenTemplateModal}
                      editorRef={editorRef}
                    />
                  )}
                  {currentEvent.note && props.currentUser && (
                    <DropdownNoteMoreActions
                      setIsOpenTemplateModal={setIsOpenTemplateModal}
                      currentUser={props.currentUser}
                      note={currentEvent.note}
                    />
                  )}
                </div>
              </li>
              <li>
                <DocumentConfigContext.Provider value={currentEvent}>
                  <ProseMirrorEditor
                    ref={editorRef}
                    docVersion={currentEvent.note?.docVersion || 0}
                    doc={currentEvent.note?.doc || "{}"}
                    docId={currentEvent.note?.docId || ""}
                    participants={currentEvent.attendees}
                  />
                </DocumentConfigContext.Provider>
                <div className="text-right mt-1">
                  <span id="save-status" className="text-xs">
                    {currentEvent.note &&
                      currentEvent.note.updatedAt &&
                      t("notes.lastUpdated", {
                        date: displayDateTime(currentEvent.note.updatedAt),
                      })}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
      </LoadingIndicator>
    </div>
  );
};
