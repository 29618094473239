import {
  // wrapItem,
  blockTypeItem,
  Dropdown,
  undoItem,
  redoItem,
  MenuItem,
  MenuElement,
  MenuItemSpec,
} from "prosemirror-menu";
import { icons } from "./icons";
import { NodeSelection, EditorState, Command } from "prosemirror-state";
import { Schema, NodeType, MarkType } from "prosemirror-model";
import { toggleMark } from "prosemirror-commands";
import { wrapInList } from "prosemirror-schema-list";
import { TextField, openPrompt } from "./prompt";
import { indentBy } from "../commands"
import i18next from 'i18next';

// Helpers to create specific types of items

function canInsert(state: EditorState, nodeType: NodeType) {
  let $from = state.selection.$from;
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d);
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true;
  }
  return false;
}

function insertImageItem(nodeType: NodeType) {
  return new MenuItem({
    title: i18next.t("prose.menuImageItemTitle").toString(),
    label: i18next.t("prose.menuImageItemLabel").toString(),
    icon: icons.addImage,
    enable(state) {
      return canInsert(state, nodeType);
    },
    run(state, _, view) {
      let { from, to } = state.selection,
        attrs = null;
      if (
        state.selection instanceof NodeSelection &&
        state.selection.node.type === nodeType
      )
        attrs = state.selection.node.attrs;
      openPrompt({
        title: i18next.t("prose.menuImageItemTitle").toString(),
        fields: {
          src: new TextField({
            label: "Location",
            required: true,
            value: attrs && attrs.src,
          }),
          title: new TextField({ label: "Title", value: attrs && attrs.title }),
          alt: new TextField({
            label: "Description",
            value: attrs ? attrs.alt : state.doc.textBetween(from, to, " "),
          }),
        },
        callback(attrs) {
          view.dispatch(
            view.state.tr.replaceSelectionWith(nodeType.createAndFill(attrs)!)
          );
          view.focus();
        },
      });
    },
  });
}

function cmdItem(cmd: Command, options: Partial<MenuItemSpec>) {
  let passedOptions: MenuItemSpec = {
    label: options.title as string | undefined,
    run: cmd,
  };
  for (let prop in options)
    (passedOptions as any)[prop] = (options as any)[prop];
  if (!options.enable && !options.select)
    passedOptions[options.enable ? "enable" : "select"] = (state) => cmd(state);

  return new MenuItem(passedOptions);
}

function markActive(state: EditorState, type: MarkType) {
  let { from, $from, to, empty } = state.selection;
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}

function markItem(markType: MarkType, options: Partial<MenuItemSpec>) {
  let passedOptions: Partial<MenuItemSpec> = {
    active(state) {
      return markActive(state, markType);
    },
  };
  for (let prop in options)
    (passedOptions as any)[prop] = (options as any)[prop];
  return cmdItem(toggleMark(markType), passedOptions);
}

function linkItem(markType: MarkType) {
  return new MenuItem({
    title: i18next.t("prose.menuLinkItemTitle").toString(),
    icon: icons.link,
    active(state) {
      return markActive(state, markType);
    },
    enable(state) {
      return !state.selection.empty;
    },
    run(state, dispatch, view) {
      if (markActive(state, markType)) {
        toggleMark(markType)(state, dispatch);
        return true;
      }
      openPrompt({
        title: i18next.t("prose.menuLinkItemPromptTitle").toString(),
        fields: {
          href: new TextField({
            label: i18next.t("prose.menuLinkItemPromptHrefLabel").toString(),
            required: true,
          }),
          title: new TextField({ label: i18next.t("menuLinkItemPromptLinkTitle").toString() }),
        },
        callback(attrs) {
          toggleMark(markType, attrs)(view.state, view.dispatch);
          view.focus();
        },
      });
    },
  });
}

function wrapListItem(nodeType: NodeType, options: Partial<MenuItemSpec>) {
  return cmdItem(wrapInList(nodeType, (options as any).attrs), options);
}

type MenuItemResult = {
  /// A menu item to toggle the [strong mark](#schema-basic.StrongMark).
  toggleStrong?: MenuItem;

  /// A menu item to toggle the [emphasis mark](#schema-basic.EmMark).
  toggleEm?: MenuItem;

  /// A menu item to toggle the [code font mark](#schema-basic.CodeMark).
  toggleCode?: MenuItem;

  /// A menu item to toggle the [link mark](#schema-basic.LinkMark).
  toggleLink?: MenuItem;

  /// A menu item to insert an [image](#schema-basic.Image).
  insertImage?: MenuItem;

  /// A menu item to wrap the selection in a [bullet list](#schema-list.BulletList).
  wrapBulletList?: MenuItem;

  /// A menu item to wrap the selection in an [ordered list](#schema-list.OrderedList).
  wrapOrderedList?: MenuItem;

  /// A menu item to wrap the selection in a [block quote](#schema-basic.BlockQuote).
  wrapBlockQuote?: MenuItem;

  /// A menu item to set the current textblock to be a normal
  /// [paragraph](#schema-basic.Paragraph).
  makeParagraphItem?: MenuItem;

  /// A menu item to set the current textblock to be a normal
  /// [paragraph](#schema-basic.Paragraph).
  makeActionItem?: MenuItem;

  /// A menu item to set the current textblock to be a normal
  /// [paragraph](#schema-basic.Paragraph).
  makeDiscussionItem?: MenuItem;

  // A menu item to set the current textblock to be a bullet point
  makeBulletPoint?: MenuItem;

  /// A menu item to set the current textblock to be a
  /// [code block](#schema-basic.CodeBlock).
  makeCodeBlock?: MenuItem;

  /// Menu items to set the current textblock to be a
  /// [heading](#schema-basic.Heading) of level _N_.
  makeHead1?: MenuItem;
  makeHead2?: MenuItem;
  makeHead3?: MenuItem;
  makeHead4?: MenuItem;
  makeHead5?: MenuItem;
  makeHead6?: MenuItem;

  /// A menu item to insert a horizontal rule.
  insertHorizontalRule?: MenuItem;

  /// A dropdown containing the headings 2-6
  headingMenu: Dropdown;

  /// Array of block-related menu items.
  blockMenu: MenuElement[][];

  /// Inline-markup related menu items.
  inlineMenu: MenuElement[][];

  /// An array of arrays of menu elements for use as the full menu
  /// for, for example the [menu
  /// bar](https://github.com/prosemirror/prosemirror-menu#user-content-menubar).
  fullMenu: MenuElement[][];
};


/// Given a schema, look for default mark and node types in it and
/// return an object with relevant menu items relating to those marks.
export function buildMenuItems(schema: Schema): MenuItemResult {
  let r: MenuItemResult = {} as any;
  let mark: MarkType | undefined;

  const indentIncrease = new MenuItem({
    title: i18next.t("prose.menuIncreaseIndentationItemTitle").toString(),
    run: indentBy(1),
    // enable: function enable(state) {
    //   return prosemirrorHistory.undo(state);
    // },
    icon: icons.indentIncrease
  });

  const indentDecrease = new MenuItem({
    title: i18next.t("prose.menuDecreaseIndentationItemTitle").toString(),
    run: indentBy(-1),
    // enable: function enable(state) {
    //   return prosemirrorHistory.undo(state);
    // },
    icon: icons.indentDecrease
  });

  mark = schema.marks.strong;
  if (mark)
    r.toggleStrong = markItem(mark, {
      title: i18next.t("prose.menuBoldItemTitle").toString(),
      icon: icons.strong,
    });
  mark = schema.marks.em;
  if (mark)
    r.toggleEm = markItem(mark, { title: i18next.t("prose.menuItalicItemTitle").toString(), icon: icons.em });
  mark = schema.marks.code;
  if (mark)
    r.toggleCode = markItem(mark, {
      title: i18next.t("prose.menuCodeItemTitle").toString(),
      icon: icons.code,
    });
  mark = schema.marks.link;
  if (mark) r.toggleLink = linkItem(mark);

  let node: NodeType | undefined;
  node = schema.nodes.image;
  if (node) {
    r.insertImage = insertImageItem(node);
  }
  node = schema.nodes.bullet_list;
  if (node) {
    r.wrapBulletList = wrapListItem(node, {
      title: i18next.t("prose.menuBulletListItemTitle").toString(),
      icon: icons.bulletList,
    });
  }
  node = schema.nodes.ordered_list;
  if (node) {
    r.wrapOrderedList = wrapListItem(node, {
      title: i18next.t("prose.menuOrderedListItemTitle").toString(),
      icon: icons.orderedList,
    });
  }
  // node = schema.nodes.blockquote;
  // if (node) {
  //   r.wrapBlockQuote = wrapItem(node, {
  //     title: "Wrap in block quote",
  //     icon: icons.blockquote,
  //   });
  // }
  node = schema.nodes.paragraph_item;
  if (node) {
    r.makeParagraphItem = blockTypeItem(node, {
      title: i18next.t("prose.menuParagraphItemTitle").toString(),
      label: i18next.t("prose.menuParagraphItemLabel").toString(),
      icon: icons.paragraph,
    });
  }
  node = schema.nodes.action_item;
  if (node) {
    r.makeActionItem = blockTypeItem(node, {
      title: i18next.t("prose.menuActionItemTitle").toString(),
      label: i18next.t("prose.menuActionItemLabel").toString(),
      icon: icons.checkboxSquare,
    });
  }
  node = schema.nodes.discussion_item;
  if (node) {
    r.makeDiscussionItem = blockTypeItem(node, {
      title: i18next.t("prose.menuDiscussionItemTitle").toString(),
      label: i18next.t("prose.menuDiscussionItemLabel").toString(),
      icon: icons.checkboxCircle,
    });
  }
  node = schema.nodes.bullet_point;
  if (node) {
    r.makeBulletPoint = blockTypeItem(node, {
      title: i18next.t("prose.menuBulletPointTitle").toString(),
      label: i18next.t("prose.menuBulletPointLabel").toString(),
      icon: icons.bulletList,
    });
  }
  node = schema.nodes.code_block;
  if (node) {
    r.makeCodeBlock = blockTypeItem(node, {
      title: i18next.t("prose.menuCodeBlockTitle").toString(),
      label: i18next.t("prose.menuCodeBlockLabel").toString(),
    });
  }
  node = schema.nodes.heading;
  if (node) {
    r.makeHead1 = blockTypeItem(node, {
      title: i18next.t("prose.menuHeadTitle", {level: 1}).toString(),
      label: i18next.t("prose.menuHeadLabel", {level: 1}).toString(),
      icon: icons.heading,
      attrs: { level: 1 },
    });
    for (let i = 2; i <= 10; i++)
      (r as any)["makeHead" + i] = blockTypeItem(node, {
        title: i18next.t("prose.menuHeadTitle", {level: i}).toString(),
        label: i18next.t("prose.menuHeadLabel", {level: i}).toString(),
        attrs: { level: i },
      });
  }
  // node = schema.nodes.horizontal_rule;
  // if (node) {
  //   let hr = node;
  //   r.insertHorizontalRule = new MenuItem({
  //     title: "Insert horizontal rule",
  //     label: "Horizontal rule",
  //     enable(state) {
  //       return canInsert(state, hr);
  //     },
  //     run(state, dispatch) {
  //       dispatch(state.tr.replaceSelectionWith(hr.create()));
  //     },
  //   });
  // }

  undoItem.spec.icon = icons.undo;
  redoItem.spec.icon = icons.redo;

  let cut = <T>(arr: T[]) => arr.filter((x) => x) as NonNullable<T>[];
  r.headingMenu = new Dropdown(
    cut([r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6]),
    { label: i18next.t("prose.menuHeadingMenuTitle").toString() }
  );

  r.inlineMenu = [
    cut([
      r.toggleStrong,
      r.toggleEm,
      r.toggleCode,
      r.insertImage,
      r.toggleLink,
    ]),
  ];
  r.blockMenu = [
    cut([
      r.makeParagraphItem,
      r.makeDiscussionItem,
      r.makeActionItem,
      r.makeBulletPoint,
      r.makeHead1,
      indentIncrease,
      indentDecrease
      // r.wrapBulletList,
      // r.wrapOrderedList,
      // r.wrapBlockQuote,
      // joinUpItem,
      // liftItem,
      // selectParentNodeItem,
    ]),
  ];
  r.fullMenu = r.inlineMenu.concat(
    r.blockMenu,
    [[r.headingMenu]],
    [[undoItem, redoItem]]
  );

  return r;
}
