import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentUser, Note } from "../../lib/types";
import { useClient } from 'jsonapi-react'
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";
import { useNavigate } from "react-router-dom";

type DropdownNoteMoreActionsProps = {
  setIsOpenTemplateModal: Dispatch<SetStateAction<boolean>>;
  note: Note;
  currentUser: CurrentUser;
};

export const DropdownNoteMoreActions = (
  props: DropdownNoteMoreActionsProps
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const itemBaseCssClass = " flex w-full justify-between px-4 py-2 text-sm";
  // const itemActive = "bg-gray-100 text-gray-900";
  const itemNotActive = "text-gray-700";
  const itemCssClass = itemNotActive + itemBaseCssClass;
  const handleApplyTemplate = () => {
    setIsOpen(false);
    props.setIsOpenTemplateModal(true);
  };
  const client = useClient()
  const handleDelete = async () => {
    const response: any = await client.delete(['notes', props.note.id])
    if(response.message) {
      notifySuccess(t(`meetings.${response.message}`));
      navigate("/meetings", { replace: true })
      window.location.reload();
    }
    notifyApiErrors([response.error])
  }

  return (
    <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
      <div className="relative ml-3 inline-block text-left">
        <div>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="-my-2 flex items-center rounded-full p-2 text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600"
            id="menu-3-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <span className="sr-only">{t("meetings.openOptions")}</span>
            {/* <!-- Heroicon name: mini/ellipsis-vertical --> */}
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
            </svg>
          </button>
        </div>

        {isOpen && (
          <div
            className="absolute right-0 bg-white z-20 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-3-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              <button
                type="button"
                onClick={() => handleApplyTemplate()}
                className={itemCssClass}
                role="menuitem"
                tabIndex={-1}
                id="menu-3-item-0"
              >
                <span>{t("notes.applyTemplate")}</span>
              </button>
              {props.currentUser.id === props.note.owner.id && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className={itemCssClass}
                  role="menuitem"
                  tabIndex={-1}
                  id="menu-3-item-1"
                >
                  <span>{t("notes.deleteNote")}</span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
