import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { logout } from "../../app/authSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const DropdownUserSettings = () => {
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleOrganization = () => {
    navigate(`/organizations/${auth?.currentUser?.currentOrganizationId}`);
    setIsOpen(false);
  };
  const handleProfile = () => {
    navigate("/profile");
    setIsOpen(false);
  };
  const itemClass =
    "text-gray-700 flex w-full justify-between px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900";

  return (
    <div className="relative inline-block text-left">
      <button
        className="flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg text-gray-400 hover:bg-gray-700"
        onClick={() => setIsOpen(!isOpen)}
      >
        {auth?.currentUser?.image ? (
          <img
            className="h-12  w-12 rounded-full"
            src={auth.currentUser.image}
            alt=""
          />
        ) : (
          <svg
            className="h-12  w-12 rounded-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
          </svg>
        )}
      </button>
      {isOpen && (
        <div
          className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-1-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <button
              onClick={handleProfile}
              className={itemClass}
              role="menuitem"
              tabIndex={-1}
              id="menu-1-item-0"
            >
              {t("navigation.profileTitle")}
            </button>
            <button
              onClick={handleOrganization}
              className={itemClass}
              role="menuitem"
              tabIndex={-1}
              id="menu-1-item-1"
            >
              {t("navigation.organizationTitle")}
            </button>
            <button
              onClick={() => dispatch(logout())}
              className={itemClass}
              role="menuitem"
              tabIndex={-1}
              id="menu-1-item-2"
            >
              {t("navigation.signOutTitle")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
