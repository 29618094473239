import { useTranslation, Trans } from "react-i18next";
import { PropsWithChildren } from "react";

type ActionItemEmptyStateProps = {
  isEmpty: boolean | undefined;
};

export const ActionItemEmptyState = (
  props: ActionItemEmptyStateProps & PropsWithChildren
) => {
  const { t } = useTranslation();
  return (
    <>
      {props.isEmpty ? (
        <div className="flex items-center w-96">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              All done!
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {t("actionItems.emptyStateTitle")}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              <Trans i18nKey="emptyStateDescription">
                You can switch your current calendar in the{" "}
                <b className="font-bold">Profile</b> section.
              </Trans>
              {t("actionItems.emptyStateDescription")}
            </p>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
