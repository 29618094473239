import { useEffect, useState } from "react";
import { Team, User, CurrentUser } from "../../lib/types";
import { SlideOverRight } from "../elements/SlideOverRight";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "jsonapi-react";
import { UserCombobox } from "../elements/forms/UserCombobox";
import { UserComboboxCollection } from "../elements/forms/UserComboboxCollection";
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";
import { useClient } from "jsonapi-react";

type TeamDetailProps = {
  currentUser: CurrentUser | null;
  selectedTeam: Team | null;
  setSelectedTeam: React.Dispatch<React.SetStateAction<Team | null>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TeamDetail = (props: TeamDetailProps) => {
  const { t } = useTranslation();
  const apiClient = useClient();
  const teamQuery = useQuery(
    props.selectedTeam?.id && [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "teams",
      props.selectedTeam?.id,
    ]
  );
  const [editTeam] = useMutation(
    [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "teams",
      props.selectedTeam?.id,
    ],
    {
      invalidate: ["organizations", "teams"],
    }
  );
  const [name, setName] = useState(
    props.selectedTeam?.name ? props.selectedTeam?.name : ""
  );
  const [description, setDescription] = useState(
    props.selectedTeam?.description ? props.selectedTeam?.description : ""
  );
  const [location, setLocation] = useState(
    props.selectedTeam?.location ? props.selectedTeam?.location : ""
  );

  const [manager, setManager] = useState<User | null | undefined>(
    props.selectedTeam?.manager
  );
  const [members, setMembers] = useState<User[]>(
    props.selectedTeam?.members || []
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editTeam({
      name,
      description,
      location,
      managerId: manager?.id,
      memberIds: members.map((m) => m.id),
    }).then((response) => {
      if (response.data) {
        props.selectedTeam?.id
          ? notifySuccess(t("apiMessages.successTeamCreated"))
          : notifySuccess(t("apiMessages.successTeamUpdated"));
        const persistedTeam = response.data as Team;
        props.setSelectedTeam(persistedTeam);
      }
      notifyApiErrors(response.errors);
    });
    // props.setOpen(false);
  };

  const handleDelete = async () => {
    if (props.selectedTeam) {
      await apiClient
        .delete([
          "organizations",
          props.currentUser?.currentOrganizationId,
          "teams",
          props.selectedTeam.id,
        ])
        .then((response) => {
          if (response.meta && !response.error) {
            notifySuccess(t("apiMessages.successTeamDeleted"));
            props.setOpen(false);
          } else {
            notifyApiErrors([response.error]);
          }
        });
    }
  };

  useEffect(() => {
    if (teamQuery.data) {
      const team = teamQuery.data as Team;
      setMembers(team.members);
    }
    notifyApiErrors(teamQuery.errors);
  }, [teamQuery.data, teamQuery.errors]);

  return (
    <SlideOverRight
      open={props.open}
      setOpen={props.setOpen}
      title={
        props.selectedTeam ? props.selectedTeam.name : t("teams.newTeamLabel")
      }
      titleIconClass="fa-users"
      closeLabel={t("menu.close")}
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("teams.nameLabel")}*
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      id="name"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("teams.locationLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      name="location"
                      id="location"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("teams.descriptionLabel")}
                </label>
                <div className="mt-2">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-full">
                <UserCombobox
                  selectedUser={manager}
                  setSelectedUser={setManager}
                  title={t("teams.managerLabel")}
                />
              </div>
              <div className="sm:col-span-full">
                <UserComboboxCollection
                  selectedUsers={members}
                  setSelectedUsers={setMembers}
                  title={t("teams.membersLabel")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleDelete}
            disabled={props.selectedTeam ? false : true}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("menu.delete")}
          </button>
          <button
            type="button"
            onClick={() => props.setOpen(false)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("menu.cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </SlideOverRight>
  );
};
