import React from "react";
import { differenceInMinutes } from "date-fns";
import { Event } from "../../lib/types";
import { TimeParagraph } from "../elements/TimeParagraph";

type EditEventModalEventProps = {
  event: Event;
  weekStartIndex: number;
  gridRowsPerDay: number;
  isDateVisible: (date: Date) => boolean;
};

export const EditEventModalEvent = (props: EditEventModalEventProps) => {
  const DAYS_IN_WEEK = 7
  const durationInMinutes =
    props.event.startAt && props.event.endAt
      ? differenceInMinutes(props.event.endAt, props.event.startAt)
      : 0;
  const getMinutesToStartAt = props.event.startAt
    ? props.event.startAt.getMinutes() + props.event.startAt.getHours() * 60
    : 0;
  const gridRow = Math.ceil(
    (props.gridRowsPerDay / 1440) * getMinutesToStartAt + 2
  ); // 24 * 60 = 1440 and 2 is the offset
  const span = Math.ceil((props.gridRowsPerDay / 1440) * durationInMinutes); // 24 * 60 = 1440

  const getWeekDayIndex = (): number => {
    return props.event.startAt ? ((props.event.startAt.getDay() - props.weekStartIndex) % DAYS_IN_WEEK) + 1 : 0;
  };

  return (
    <>
      {props.event.startAt && props.isDateVisible(props.event.startAt) && (
        <li
          className={`relative mt-px flex col-start-${getWeekDayIndex()}`}
          style={
            {
              gridRow: `${gridRow} / span ${span}`,
            } as React.CSSProperties
          }
        >
          <button className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-indigo-50 pt-1 pl-2 text-xs leading-5 hover:bg-indigo-100">
            <p className="order-1 font-semibold text-indigo-700">
              {props.event.summary}
            </p>
            <TimeParagraph date={props.event.startAt} />
          </button>
        </li>
      )}
    </>
  );
};
