import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { setCredentials } from "../app/authSlice";
import { apiUrl, authGooglePath } from "../constants/apiUrls";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../lib/toasts";
import LoginWallpaper from "../assets/images/login_wallpaper.jpg";
import Logo from "../assets/images/visavys_logo.svg";
import VisavysName from "../assets/images/visavys_name.svg";
import GoogleLogo from "../assets/images/google_logo.svg";
import MicrosoftLogo from "../assets/images/microsoft_logo.svg";
import { Tooltip } from "../components/elements/forms/Tooltip";

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);

  let [searchParams] = useSearchParams();
  // redirect authenticated user to profile screen
  const authParams = {
    uid: searchParams.get("uid"),
    "access-token": searchParams.get("access-token"),
    client: searchParams.get("client"),
    expiry: searchParams.get("expiry"),
    "token-type": searchParams.get("token-type"),
  };
  if (
    authParams.uid &&
    authParams["access-token"] &&
    authParams.client &&
    authParams.expiry &&
    authParams["token-type"]
  ) {
    dispatch(setCredentials(authParams));
  }

  useEffect(() => {
    if (auth.authToken) {
      navigate("/meetings");
    }
  }, [auth.authToken, navigate]);

  if (searchParams.get("invitation_status")) {
    const status = searchParams.get("invitation_status")?.toString();
    if (status === "accepted") {
      notifySuccess(
        t(`invitationStatus.${status}Title`),
        t(`invitationStatus.${status}Description`),
        "invitation_status"
      );
    } else {
      notifyError(
        t(`invitationStatus.${status}Title`),
        t(`invitationStatus.${status}Description`),
        "invitation_status"
      );
    }
  }

  return (
    <section className="h-full bg-white">
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center">
          <div className="w-full">
            <div className="flex-shrink-0">
              <a href="/" className="inline-flex">
                <span className="sr-only">{t("common.visavysName")}</span>
                <img className="h-12 w-auto" src={Logo} alt="" />
                <img className="h-12 w-auto ml-3" src={VisavysName} alt="" />
              </a>
            </div>
            <div className="block rounded-lg bg-white">
              <div className="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div className="px-4 md:px-0 lg:w-6/12">
                  <img
                    src={LoginWallpaper}
                    alt="Login Wallpaper"
                    className="w-full"
                  />
                </div>

                {/* <!-- Right column container with background and description--> */}
                <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none">
                  {/* style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)" */}
                  <div className="flex">
                    <div className="flex flex-1 flex-col justify-center sm:px-6 lg:flex-none">
                      <div className="mx-auto w-full max-w-sm lg:ml-32">
                        <div>
                          {/* <%= image_tag("favicon/android-chrome-192x192.png", class:"h-12 w-auto") %> */}
                          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                            {t("login.signInPrompt")}
                          </h2>
                        </div>

                        <div className="mt-8">
                          <div>
                            <div>
                              <form
                                action={`${apiUrl}${authGooglePath}`}
                                method="post"
                              >
                                <button
                                  type="submit"
                                  className="flex items-center my-3 w-full bg-white border border-gray-300 rounded-lg shadow-md max-w-xs px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                  <img
                                    src={GoogleLogo}
                                    className="h-6 w-6 mr-2"
                                    alt="Google Logo"
                                  />
                                  <span>{t("login.loginGoogle")}</span>
                                </button>
                              </form>
                              <form
                                action={`${apiUrl}${authGooglePath}`}
                                method="post"
                              >
                                <Tooltip
                                  position="bottom"
                                  color="dark"
                                  content={t("login.alertMicrosoftBeta")}
                                >
                                  <button
                                    type="submit"
                                    disabled
                                    className="flex items-center my-3 w-full bg-white border border-gray-300 rounded-lg shadow-md max-w-xs px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                  >
                                    <img
                                      src={MicrosoftLogo}
                                      className="h-6 w-6 mr-2"
                                      alt="Microsoft Logo"
                                    />
                                    <span>{t("login.loginMicrosoft")}</span>
                                  </button>
                                </Tooltip>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
