import { Event } from "../../lib/types";
import { TimeParagraph } from "../elements/TimeParagraph";
import { truncate } from "../../lib/utils"

type MeetingListItemProps = {
  event: Event;
  active: boolean;
  changeCurrentEvent: (event: Event) => void;
};

export const MeetingListItem = (
  props: MeetingListItemProps & { key: string }
) => {
  const linkBaseCssClass =
    " border-2 group w-full inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-indigo-100";
  const linkEnabledCssClass = "bg-indigo-200 border-indigo-700";
  const linkNotEnabledCssClass = "border-indigo-400";

  const linkCssClass = props.active
    ? linkEnabledCssClass + linkBaseCssClass
    : linkNotEnabledCssClass + linkBaseCssClass;
  return (
    <li className="relative mt-px flex py-1">
      <button onClick={() => props.changeCurrentEvent(props.event)} className={linkCssClass}>
        <p className="font-semibold text-left">{truncate(props.event.summary, 80)}</p>
        <p className="text-left">{truncate(props.event.description, 80)}</p>
        <TimeParagraph date={props.event.startAt}/>
      </button>
    </li>
  );
};
