// Type definitions follow this rule:
//
// id,
//
// sorted list of attributes
//
// sorted list of relationships
//
// createdAnd
// updatedAt

const JsonApiCalendar = {
  type: "calendar",
  fields: {
    id: "string",

    color: "string",
    externalCalendarId: "string",
    type: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    owner: {
      type: "user",
    },
  },
};

const JsonApiEvent = {
  type: "event",
  fields: {
    id: "string",

    color: "string",
    isAllDay: "boolean",
    eventType: "string",
    endAt: {
      type: "date",
    },
    externalEventId: "string",
    organizerEmail: "string",
    startAt: {
      type: "date",
    },
    summary: "string",
    description: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    calendar: {
      type: "calendar",
    },
    meeting: {
      type: "meeting",
    },
    note: {
      type: "note",
    },
    attendees: {
      type: "attendee",
    },
    organizer: {
      type: "attendee",
    },
    objectives: {
      type: "objective",
    }
  },
};

const JsonApiMeeting = {
  type: "meeting",
  fields: {
    id: "string",

    description: "string",
    endAt: {
      type: "date",
    },
    externalEventId: "string",
    htmlLink: "string",
    isAllDay: "boolean",
    location: "string",
    startAt: {
      type: "date",
    },
    status: "string",
    title: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    organizer: {
      type: "user",
    },
    note: {
      type: "note",
    },
    event: {
      type: "event",
    },
  },
};

const JsonApiUser = {
  type: "user",
  fields: {
    id: "string",

    email: "string",
    firstName: "string",
    image: "string",
    lastName: "string",
    name: "string",
    nickname: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    organization: {
      type: "organization",
    },
    organziationMemberships: {
      type: "organziationMembership"
    }
  },
};

const JsonApiCurrentUser = {
  type: "user",
  fields: {
    id: "string",

    email: "string",
    firstName: "string",
    image: "string",
    isManager: "boolean",
    lastName: "string",
    name: "string",
    nickname: "string",
    currentOrganizationId: "string",
    currentManagerId: "string",
    currentRole: "string",

    locale: "string",
    onboardedAt: "string",
    onboardingStep: "number",
    paymentPlan: "string",
    timezone: "string",
    weekStartIndex: "number",

    createdAt: "string",
    updatedAt: "string",
  },
  relationships: {
    calendars: {
      type: "calendar",
    },
    organizations: {
      type: "organization",
    },
  },
};

const JsonApiAttendee = {
  type: "attendee",
  fields: {
    id: "string",

    displayName: "string",
    email: "string",
    firstName: "string",
    image: "string",
    isActive: "boolean",
    isGuest: "boolean",
    lastName: "string",
    name: "string",
    nickname: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    organization: {
      type: "organization",
    },
  },
};

const JsonApiOrganization = {
  type: "organization",
  fields: {
    id: "string",

    name: "string",
    shortName: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    owner: {
      type: "user",
    },
    members: {
      type: "user"
    }
  },
};

const JsonApiActionItem = {
  type: "actionItem",

  fields: {
    id: "string",
    content: "string",
    dueAt: {
      type: "date",
      readOnly: true,
    },
    wontDo: "boolean",
    done: "boolean",
    blockId: "string",
    documentId: "string",
    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },

  relationships: {
    assignee: {
      type: "attendee",
    },
    creator: {
      type: "attendee",
    },
    event: {
      type: "event",
    },
    note: {
      type: "note",
    },
  },
};

const JsonApiKeyResult = {
  type: "keyResult",
  fields: {
    id: "string",

    currentValue: "number",
    description: "string",
    position: "number",
    progress: "number",
    startValue: "number",
    status: "string",
    targetValue: "number",
    title: "string",
    valueType: "string",
    valueUnit: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    objective: {
      type: "objective"
    },
    owner: {
      type: "user",
    }
  },
};

const JsonApiObjective = {
  type: "objective",
  fields: {
    id: "string",

    dueAt: {
      type: "date",
      readOnly: true,
    },
    progress: "number",
    status: "string",
    title: "string",
    description: "string",
    position: "number",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    owner: {
      type: "user",
    },
    cycle: {
      type: "cycle",
    },
    keyResults: {
      type: "keyResult"
    },
    teams: {
      type: "team"
    }
  },
};

const JsonApiCycle = {
  type: "cycle",
  fields: {
    id: "string",

    default: "boolean",
    endAt: {
      type: "date",
      readOnly: true,
    },
    isArchived: "boolean",
    name: "string",
    startAt: {
      type: "date",
      readOnly: true,
    },

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    objectives: {
      type: "objective",
    }
  },
};

const JsonApiTeam = {
  type: "team",
  fields: {
    id: "string",


    active: "boolean",
    description: "string",
    location: "string",
    membersCount: "number",
    name: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    manager: {
      type: "user",
    },
    members: {
      type: "user"
    }
  },
};

const JsonApiOrganizationMembership = {
  type: "organizationMembership",
  fields: {
    id: "string",

    deleted: "boolean",
    role: "string",
    status: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
    manager: {
      type: "user",
    },
    member: {
      type: "user"
    },
    organization: {
      type: "organization"
    }
  },
};

const JsonApiReport = {
  type: "report",
  fields: {
    id: "string",

    audienceSize: "any",
    calendarId: "string",
    cycle: "string",
    goalsVsOtherActivities: "any",
    keyConstituencies: "any",
    meetingDuration: "any",
    meetingVsAlone: "any",
    organizationId: "any",
    productivity: "any",
    scheduledVsSpontaneous: "any",
    timeWithDirects: "any",
    userId: "string",

    createdAt: {
      type: "date",
      readOnly: true,
    },
    updatedAt: {
      type: "date",
      readOnly: true,
    },
  },
  relationships: {
  },
};

export const JsonApiSchema = {
  googleCalendar: JsonApiCalendar,
  calendar: JsonApiCalendar,
  //calendars: JsonApiCalendar,
  // googleCalendar: JsonApiCalendar,
  googleEvent: JsonApiEvent,
  event: JsonApiEvent,
  // events: JsonApiEvent,
  sharedEvent: JsonApiEvent,
  // googleEvent: JsonApiEvent,
  meeting: JsonApiMeeting,
  // meetings: JsonApiMeeting,
  organization: JsonApiOrganization,
  // organizations: JsonApiOrganization,
  user: JsonApiUser,
  // users: JsonApiUser,
  currentUser: JsonApiCurrentUser,
  attendee: JsonApiAttendee,
  // attendees: JsonApiAttendee,
  // action_item: JsonApiActionItem,
  actionItem: JsonApiActionItem,
  // action_items: JsonApiActionItem,
  // actionItems: JsonApiActionItem,
  objective: JsonApiObjective,
  organizationMembership: JsonApiOrganizationMembership,
  keyResult: JsonApiKeyResult,
//  cycles: JsonApiCycle,
  cycle: JsonApiCycle,
  team: JsonApiTeam,
  report: JsonApiReport
};

export interface Meeting {
  id: string;

  description: string;
  endAt?: Date;
  externalEventId: string;
  htmlLink?: string;
  isAllDay: boolean;
  location?: string;
  startAt?: Date;
  status?: string;
  title: string;

  event: Event;
  note: Note;
  organizer?: User;

  createdAt: Date;
  updatedAt: Date;
}

export interface Calendar {
  id: string;

  color: string;
  type: string;
  externalCalendarId: string;

  owner: User;

  createdAt: Date;
  updatedAt: Date;
}

export interface Event {
  id: string;

  calendarId: string;

  attendees: User[];
  color: string;
  description?: string;
  endAt?: Date;
  eventType: string;
  externalEventId: string;
  isAllDay: boolean;
  meeting?: Meeting;
  note?: Note;
  objectives: Objective[];
  organizer?: Attendee;
  organizerEmail: string;
  sharedToken?: string;
  sharedTokenExpiry?: Date;
  startAt?: Date;
  summary?: string;

  createdAt: Date;
  updatedAt: Date;
}

export interface User {
  id: string;
  displayName: string;
  email: string;
  firstName: string;
  image: string;
  lastName: string;
  isActive: boolean;
  isGuest: boolean;
  name: string;
  nickname: string;
  organizationMemberships: OrganizationMembership[]
}

export interface Attendee {
  id?: string;
  displayName: string;
  email: string;
  firstName?: string;
  image?: string;
  lastName?: string;
  isActive?: boolean;
  isGuest?: boolean;
  name?: string;
  nickname?: string;
}

export interface CurrentUser {
  id: string;

  currentCalendarId: string;
  currentManagerId: string;
  currentOrganizationId: string;
  currentRole: string;
  email: string;
  firstName: string;
  image: string;
  isManager: boolean;
  lastName: string;
  locale: string;
  name: string;
  nickname: string;
  onboardedAt: string;
  onboardingStep: number;
  paymentPlan: string;
  timezone: string;
  weekStartIndex: number;

  createdAt: string;
  updatedAt: string;

  calendars: Calendar[];
  organizations: Organization[]
}

export interface Organization {
  id: string;

  name: string;
  shortName: string;

  owner: User;

  createdAt: Date;
  updatedAt: Date;

  members: User[];
}

export interface Note {
  id: string;

  description: string;
  isNot1On1: boolean;
  isPrivate: boolean;
  title: string;

  meeting: Meeting;
  organization: Organization;
  owner: User;
  doc: string;
  docVersion: number;
  docId: string;

  createdAt: Date;
  updatedAt: Date;
}

export interface ActionItem {
  id: string;

  content: string;
  dueAt: Date;
  wontDo: boolean;
  done: boolean;
  blockId: string;
  documentId: string;

  createdAt: Date;
  updatedAt: Date;

  assignee: Attendee;
  creator: Attendee;
  event: Event;
  note: Note;
}

export interface KeyResult {
  id: string;

  currentValue: number;
  description: string;
  position: number;
  progress: number;
  startValue: number;
  status: string;
  targetValue: number;
  title: string;
  valueType: string;
  valueUnit: string;

  createdAt: Date;
  updatedAt: Date;

  objective: Objective
  owner: User
}

export interface Objective {
  id: string;

  description: string;
  dueAt: Date;
  position: number;
  progress: number;
  status: string;
  title: string;

  createdAt: Date;
  updatedAt: Date;
  owner: User;
  cycle: Cycle;
  keyResults: KeyResult[];
  teams: Team[];
}

export interface Cycle {
  id: string;

  default: boolean;
  endAt: Date;
  isArchived: boolean;
  name: string;
  startAt: Date;

  createdAt: Date;
  updatedAt: Date;

  objectives: Objective[];
}

export interface Team {
  id: string;

  active: boolean;
  description: string;
  location: string;
  membersCount: number;
  name: string;

  createdAt: Date;
  updatedAt: Date;

  manager: User;
  members: User[];
}

export interface OrganizationMembership {
  id: string;
  deleted: boolean;
  role: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  manager: User;
  member: User;
  organization: Organization;
}

export interface Report {
  id: string;
  audienceSize: any[];
  calendarId: string;
  cycle: string;
  goalsVsOtherActivities: any[];
  keyConstituencies: any[];
  meetingDuration: any[];
  meetingVsAlone: any[];
  organizationId: string;
  productivity: any[];
  scheduledVsSpontaneous: any[];
  timeWithDirects: any[];
  userId: string;
}

export interface ApiError {
  detail: string | null | undefined;
  source: string | null | undefined;
  status: string
  code: string | undefined
  title: string | null | undefined;
}

export interface ApiErrors {
  errors: ApiError[]
}

export type Mutable<T> = { -readonly [P in keyof T ]: T[P] };
