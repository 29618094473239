import React from "react";
import { PropsWithChildren } from "react";
import classNames from "classnames";

type TooltipProps = {
  position: string;
  color: string;
  content: string;
  showIcon?: boolean;
  widthClassName?: string;
};

// See https://github.com/tailwindlabs/headlessui/discussions/948
export const Tooltip = ({ position, color, content, children, showIcon, widthClassName}: TooltipProps & PropsWithChildren) => {
  return (
    <div className="relative group cursor-help">
      { children }
      { showIcon && <span
        className="fa fa-question-circle pl-1"
        aria-hidden="true"
      />
      }
      <div
        className={classNames(
          `absolute hidden z-10 invisible opacity-0 group-hover:block group-hover:visible group-hover:opacity-95 group-focus:block group-focus:visible group-focus:opacity-95 rounded-md py-2 px-4 shadow h-auto min-w-min ${widthClassName} transition-opacity delay-200 ease-in-out`,
          {
            "bg-gray-50 text-current": color === "light",
            "bg-gray-800 text-white": color === "dark",
          },
          {
            "bottom-full left-1 transform -translate-x-1 -translate-y-1":
              position === "top",
            "top-full left-1 transform -translate-x-1 translate-y-1":
              position === "bottom",
            "left-full top-1 transform translate-x-1 -translate-y-1":
              position === "right",
            "right-full top-1 transform -translate-x-1 -translate-y-1":
              position === "left",
          }
        )}
        role="tooltip"
      >
        <span className="text-xs">{content}</span>
      </div>
    </div>
  );
};
