import React from "react";
import { PropsWithChildren } from "react";

type LoadingIndicatorProps = {
  isLoading: boolean | undefined;
};

export const LoadingIndicator = (
  props: LoadingIndicatorProps & PropsWithChildren
) => {
  return (
    <>
      {props.isLoading ? (
        <span className="fa fa-spinner fa-pulse fa-3x fa-fw"></span>
      ) : (
        props.children
      )}
    </>
  );
};
