import { useMutation, useQuery } from "jsonapi-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { notifyApiErrors } from "../../lib/toasts";
import { Organization, CurrentUser } from "../../lib/types";

type OrganizationModalProps = {
  setOnboardingRequired: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: CurrentUser | null
};

export const OrganizationModal = (props: OrganizationModalProps) => {
  const [t, ] = useTranslation();
  const [name, setName] = useState("");
  const [editOrganization] = useMutation(["organizations", props.currentUser?.currentOrganizationId]);
  const [editUser] = useMutation(["users", props.currentUser?.id]);
  const organizationQuery = useQuery(props.currentUser?.currentOrganizationId && ["organizations", props.currentUser.currentOrganizationId])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const organizationResponse = await editOrganization({
      name
    });
    notifyApiErrors([organizationResponse.error]);
    const userResponse = await editUser({
      onboardedAt: new Date()
    })
    notifyApiErrors([userResponse.error]);
    props.setOnboardingRequired(false)
  };

  useEffect(() => {
    if(organizationQuery.data) {
      const organization = organizationQuery.data as Organization
      setName(organization.name)
    }
    notifyApiErrors(organizationQuery.errors)
  }, [organizationQuery.data, organizationQuery.errors])

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <form onSubmit={handleSubmit}>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* <!-- Heroicon name: outline/exclamation-triangle --> */}
                  <svg
                    className="h-8 w-8 text-indigo-600"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    ></path>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    { t("organizations.namePrompt") }
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      { t("organizations.organizationExplanation") }
                    </p>
                  </div>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={ t("organizations.shortNamePlaceholder").toString() }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  { t("menu.save") }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
