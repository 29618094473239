import { User } from "../../../lib/types";
import { useTranslation } from "react-i18next";

type UserListProps = {
  removeUser?: (user: User) => void;
  users: User[];
  onClick?: (user: User) => void;
};

export const UserList = (props: UserListProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <ul className="divide-y divide-gray-100">
        {props.users.map((user) => (
          <li
            key={user.email}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <button
              onClick={() => props.onClick && props.onClick(user)}
              type="button"
              disabled={!props.onClick}
            >
              <div className="flex min-w-0 gap-x-4">
                <img
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                  src={user.image}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold text-left leading-6 text-gray-900">
                    {user.displayName}
                  </p>
                  <p className="mt-1 truncate text-xs text-left leading-5 text-gray-500">
                    {user.email}
                  </p>
                </div>
              </div>
            </button>
            {props.removeUser && (
              <button
                onClick={() => props.removeUser && props.removeUser(user)}
                className="rounded-full bg-white w-6 h-6 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                title={t("forms.remove").toString()}
              >
                <span className="fa fa-times" />
              </button>
            )}
          </li>
        ))}
      </ul>
      {/* <a
          href="#"
          className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          View all
        </a> */}
    </div>
  );
};
