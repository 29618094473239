import { useEffect } from "react";
import { DonutChart } from "../components/charts/DonutChart";
import { StackedBarplot } from "../components/charts/StackedBarplot";
import { ChartBox } from "../components/charts/ChartBox";
import { useQuery } from "jsonapi-react";
import { useAppSelector } from "../app/hooks";
import { Report } from "../lib/types";
import { useState } from "react";
import { displayMonthAndYear } from "../lib/utils";
import { format } from "date-fns";
import { ChartEmptyState } from "../components/charts/ChartEmptyState";
import { useTranslation } from "react-i18next";

export const ReportList = () => {
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth);
  const [selectedCycle, setSelectedCycle] = useState<string | null>(
    "M-" + format(new Date(), "yyyy-M")
  );
  const reportQuery = useQuery(
    auth.currentUser?.currentOrganizationId && [
      "organizations",
      auth.currentUser.currentOrganizationId,
      "reports",
      selectedCycle,
    ]
  );
  const [report, setReport] = useState<Report | undefined>(undefined);

  const camelize = (
    str: string | null | undefined,
    capitalize: boolean = false
  ) => {
    if (str) {
      const a = str
        .toLowerCase()
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
      return capitalize
        ? a.substring(0, 1).toUpperCase() + a.substring(1)
        : a.substring(0, 1).toLowerCase() + a.substring(1);
    } else {
      return str;
    }
  };

  const translateKeys = (data: any[], chartName: string, key: string) => {
    return data.map((entry) => {
      const result = { ...entry };
      result[key] = t(`reports.${chartName}Key${camelize(entry[key], true)}`);
      return result;
    });
  };

  useEffect(() => {
    if (reportQuery && reportQuery.data) {
      setReport(reportQuery.data as Report);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportQuery, setReport]);

  const cycleOptions = [...Array(12).keys()].map((n) => ({
    value: `M-2023-${n + 1}`,
    name: displayMonthAndYear(new Date(`2023-${n + 1}-01`)),
  }));

  return (
    <section className="flex min-w-0 flex-1 flex-col xl:order-last m-6">
      <h1 className="text-xl font-bold text-gray-900 mb-6">Report</h1>
      <select
        id="month"
        name="month"
        value={selectedCycle || "M-2023-9"}
        onChange={(e) => setSelectedCycle(e.target.value)}
        autoComplete="off"
        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-3"
      >
        {cycleOptions.map((cycle) => (
          <option value={cycle.value}>{cycle.name}</option>
        ))}
      </select>
      <div className="flex flex-wrap">
        <ChartBox
          title={t("reports.chartGoalsVsOtherActivitiesTitle")}
          tooltipContent={t("chartGoalsVsOtherActivitiesTooltip").toString()}
        >
          {report?.goalsVsOtherActivities ? (
            <DonutChart
              width={500}
              height={200}
              data={translateKeys(
                report.goalsVsOtherActivities,
                "goalsVsOtherActivities",
                "name"
              )}
              excludeZeros={true}
            />
          ) : (
            <ChartEmptyState
              content={t(
                "reports.chartGoalsVsOtherActivitiesEmptyStateTitle"
              ).toString()}
            />
          )}

          {/* <p>{t("reports.chartGoalsVsOtherActivitiesDescription")}</p> */}
        </ChartBox>

        {auth.currentUser?.isManager && (
          <ChartBox
            title={t("reports.chartTimeWithDirectsTitle")}
            tooltipContent={t("reports.chartTimeWithDirectsTooltip").toString()}
          >
            {report?.timeWithDirects ? (
              <DonutChart
                width={500}
                height={200}
                data={translateKeys(
                  report.timeWithDirects,
                  "timeWithDirects",
                  "name"
                )}
              />
            ) : (
              <ChartEmptyState
                content={t(
                  "reports.chartTimeWithDirectsEmptyStateTitle"
                ).toString()}
              />
            )}

            {/* <p>{t("reports.chartTimeWithDirectsDescription")}</p> */}
          </ChartBox>
        )}

        <ChartBox
          title={t("reports.chartProductivityTitle")}
          tooltipContent={t("reports.chartProductivityTooltip").toString()}
        >
          {report?.productivity && report.productivity.length !== 0 ? (
            <StackedBarplot
              width={500}
              height={400}
              data={translateKeys(report.productivity, "productivity", "name")}
              allSubgroups={["open", "done", "wontdo"]}
            />
          ) : (
            <ChartEmptyState
              content={t("reports.chartProductivityEmptyStateTitle").toString()}
            />
          )}

          {/* <p>{t("reports.chartProductivityDescription")}</p> */}
        </ChartBox>

        <ChartBox title={t("reports.chartKeyConstituenciesTitle")}>
          {report?.keyConstituencies ? (
            <StackedBarplot
              width={500}
              height={400}
              data={translateKeys(
                report.keyConstituencies,
                "keyConstituencies",
                "x"
              )}
              allSubgroups={["meetings"]}
              rotateXAxis={true}
            />
          ) : (
            <ChartEmptyState
              content={t(
                "reports.chartKeyConstituenciesEmptyStateTitle"
              ).toString()}
            />
          )}

          {/* <p>{t("reports.chartKeyConstituenciesDescription")}</p> */}
        </ChartBox>

        <ChartBox
          title={t("reports.chartScheduledVsSpontaneousTitle")}
          tooltipContent={t("chartScheduledVsSpontaneousTooltip").toString()}
        >
          {report?.scheduledVsSpontaneous ? (
            <StackedBarplot
              width={200}
              height={200}
              data={translateKeys(
                report.scheduledVsSpontaneous,
                "scheduledVsSpontaneous",
                "x"
              )}
              allSubgroups={["self", "other"]}
            />
          ) : (
            <ChartEmptyState
              content={t(
                "reports.chartScheduledVsSpontaneousEmptyStateTitle"
              ).toString()}
            />
          )}

          {/* <p>{t("reports.chartScheduledVsSpontaneousDescriptionP1")}</p> */}
          {/* <p>{t("reports.chartScheduledVsSpontaneousDescriptionP2")}</p> */}
        </ChartBox>
        <ChartBox title={t("reports.chartMeetingDurationTitle")}>
          {report?.meetingDuration ? (
            <StackedBarplot
              width={500}
              height={400}
              data={translateKeys(
                report.meetingDuration,
                "meetingDuration",
                "x"
              )}
              allSubgroups={["occurances"]}
            />
          ) : (
            <ChartEmptyState
              content={t(
                "reports.chartMeetingDurationEmptyStateTitle"
              ).toString()}
            />
          )}

          {/* <p>{t("reports.chartMeetingDurationDescription")}</p> */}
        </ChartBox>
        <ChartBox title={t("reports.chartAudienceSizeTitle")}>
          {report?.audienceSize ? (
            <StackedBarplot
              width={500}
              height={400}
              data={report.audienceSize}
              allSubgroups={["occurances"]}
            />
          ) : (
            <ChartEmptyState
              content={t("reports.chartAudienceSizeEmptyStateTitle").toString()}
            />
          )}
          {/* <p>{t("reports.chartAudienceSizeDescription")}</p> */}
        </ChartBox>
      </div>
    </section>
  );
};
