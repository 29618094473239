import { useState } from "react";
import "./ProseMirrorEditor.module.css";
import { viewerSetup } from "../../lib/prose/setup";
import { ProseMirror, useNodeViews } from "@nytimes/react-prosemirror";
import { EditorState } from "prosemirror-state";
import { schema } from "../../lib/prose/schema";
import { reactNodeViews } from "../../lib/prose/reactNodeViews";
import { User } from "../../lib/types";

type ProseMirrorEditorProps = {
  doc: string;
  docVersion: number;
  docId: string;
  participants: User[];
};

export const ProseMirrorViewer = (props: ProseMirrorEditorProps) => {
  const initialEditorState = EditorState.create({
    schema,
    plugins: [...viewerSetup({ schema: schema })],
    doc: schema.nodeFromJSON(props.doc),
  });

  const { nodeViews, renderNodeViews } = useNodeViews(reactNodeViews);
  const [mount, setMount] = useState<HTMLDivElement | null>(null);

  return (
    <ProseMirror
      mount={mount}
      state={initialEditorState}
      nodeViews={nodeViews}
      editable={() => false}
    >
      <div ref={setMount} />
      {renderNodeViews()}
    </ProseMirror>
  );
};
