import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "../components/layouts/Layout";
import {
  ActionItemList,
  CycleList,
  Login,
  MeetingList,
  OKRList,
  OrganizationDetail,
  PageNotFound,
  Profile,
  ReportList,
  SharedMeeting,
  TeamList,
  UnderMaintenance,
} from "../pages";
import ProtectedRoute from "./ProtectedRoute";
import { SharedLayout } from "../components/layouts/SharedLayout";

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/shared/" element={<SharedLayout />}>
          <Route path="meetings/:linkToken" element={<SharedMeeting />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route element={<ProtectedRoute />}>
            <Route path="profile" element={<Profile />} />
            <Route path="meetings" element={<MeetingList />} />
            <Route path="action_items" element={<ActionItemList />} />
            <Route path="meetings/:eventId" element={<MeetingList />} />
            <Route
              path="organizations/:organizationId"
              element={<OrganizationDetail />}
            />
            <Route path="reports" element={<ReportList />} />
            <Route path="okrs" element={<OKRList />} />
            <Route path="cycles" element={<CycleList />} />
            <Route path="teams" element={<TeamList />} />
            {/* <Route path="meetings/:id" element={<MeetingDetail />} /> */}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/maintenance" element={<UnderMaintenance />} />
      </Routes>
    </>
  );
};
