import { Dispatch, SetStateAction } from "react";
import { useTranslation, Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import { PropsWithChildren } from "react";

type MeetingEmptyStateProps = {
  setNewEventIsOpen: Dispatch<SetStateAction<boolean>>;
  isEmpty: boolean | undefined;
};

export const MeetingEmptyState = (
  props: MeetingEmptyStateProps & PropsWithChildren
) => {
  const { t } = useTranslation();
  return (
    <>
      {props.isEmpty ? (
        <div className="flex items-center justify-center min-h-screen w-full">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              {t("meetings.emptyStateTitle")}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {t("meetings.emptyStateDescription")}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              <Trans i18nKey="meetings.emptyStateCalenderSwitch">
                You can switch your current calendar in the{" "}
                <NavLink to="/profile" className="font-bold">
                  Profile
                </NavLink>{" "}
                section.
              </Trans>
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => props.setNewEventIsOpen(true)}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {/* <!-- Heroicon name: mini/plus --> */}
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                {t("meetings.newEvent")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
