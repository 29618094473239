import axios from "axios";
import {apiUrl} from "../constants/apiUrls"
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface Credentials {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const userLogin = createAsyncThunk(
  "user/login",
  async (
    { email, password }: Pick<Credentials, "email" | "password">,
    { rejectWithValue }
  ) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const request = axios
        .post(`${apiUrl}/auth/sign_in`, { email, password }, config)
        .then((response) => {
          return {
            uid: response.headers.uid,
            "token-type": response.headers["token-type"],
            client: response.headers.client,
            expiry: response.headers.expiry,
            "access-token": response.headers["access-token"],
            // FIXME: That doens't look right
            currentUser: response.data,
          };
        });
      const data = await request;

      // TODO Update local storage and axios headers

      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userRegister = createAsyncThunk(
  "user/register",
  async (
    { firstName, lastName, email, password }: Credentials,
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        `${apiUrl}/api/user/register`,
        { firstName, lastName, email, password },
        config
      );
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
