import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "jsonapi-react";
import { useState, useEffect} from "react";
import { CurrentUser, Organization, User, Calendar } from "../lib/types";
import { displayErrors } from "../lib/toasts";
import { persistLocaleSettingsInLocalStorage } from "../app/authService";
import { timezones } from "../lib/timezones";
import { UserCombobox } from "../components/elements/forms/UserCombobox";
import { OrganizationCombobox } from "../components/elements/forms/OrganizationCombobox";
import { CalendarCombobox } from "../components/elements/forms/CalendarCombobox";

export const Profile = () => {
  const { t } = useTranslation();

  const currentUserQuery = useQuery(["current_user"]);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

  const managerQuery = useQuery(
    currentUser?.currentManagerId && ["users", currentUser.currentManagerId]
  );

  const [currentOrganization, setCurrentOrganization] =
    useState<Organization | null>(null);
  const [currentCalendar, setCurrentCalendar] =
    useState<Calendar | null>(null);
  const [editUser] = useMutation(["users", currentUser?.id], {
    invalidate: ["current_user", "users"],
  });

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [timezone, setTimezone] = useState("Europe/Berlin");
  const [locale, setLocale] = useState("en-US");
  const [weekStartIndex, setWeekStartIndex] = useState(0);
  const [manager, setManager] = useState<User | null | undefined>(undefined);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const query = await editUser({
      currentCalendarId: currentCalendar?.id,
      currentManagerId: manager?.id,
      currentOrganizationId: currentOrganization?.id,
      firstName,
      lastName,
      locale,
      timezone,
      weekStartIndex,
    });
    // prevent any caches from kicking in
    persistLocaleSettingsInLocalStorage({ locale, weekStartIndex });
    window.location.reload();
    displayErrors(query);
  };

  const fieldClassName = "mt-2 w-full sm:max-w-xs";

  const textFieldClassName =
    "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";

  const labelClassName = "block text-sm font-medium leading-6 text-gray-900";

  useEffect(() => {
    if (!currentUser && currentUserQuery.data) {
      const user = currentUserQuery.data as CurrentUser;
      setCurrentUser(user);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setLocale(user.locale);
      setTimezone(user.timezone);
      setWeekStartIndex(user.weekStartIndex);
      displayErrors(currentUserQuery);
      setCurrentOrganization(user.organizations.find((o) => o.id === user.currentOrganizationId) || null)
      setCurrentCalendar(user.calendars.find((c) => c.id === user.currentCalendarId) || null)
    }
  }, [currentUser, currentUserQuery, currentUserQuery.data, setCurrentUser]);

  useEffect(() => {
    if (managerQuery && managerQuery.data) {
      // checking for undefined allows it to be set to null without overwriting
      manager === undefined && setManager(managerQuery.data as User);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerQuery, setManager]);

  return (
    <div className="isolate w-1/2 bg-white px-6 py-3 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-xl font-bold tracking-tight text-gray-900">
          {t("profile.title")}
        </h2>
      </div>
      <form className="mt-3" onSubmit={handleSubmit}>
        <div className={fieldClassName}>
          <label htmlFor="firstName" className={labelClassName}>
            {t("profile.firstNameLabel")}
          </label>
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            name="firstName"
            id="firstName"
            className={textFieldClassName}
            value={firstName}
          />
        </div>
        <div className={fieldClassName}>
          <label htmlFor="lastName" className={labelClassName}>
            {t("profile.lastNameLabel")}
          </label>
          <input
            type="text"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            name="lastName"
            id="lastName"
            className={textFieldClassName}
            value={lastName}
          />
        </div>
        <div className={fieldClassName}>
          <label htmlFor="email" className={labelClassName}>
            {t("profile.emailLabel")}
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className={`${textFieldClassName} bg-gray-100`}
            value={currentUser ? currentUser.email : ""}
            disabled={true}
          />
        </div>
        <div className={fieldClassName}>
          <OrganizationCombobox
            title={t("profile.currentOrganizationLabel")}
            organizations={currentUser?.organizations || []}
            selectedOrganization={currentOrganization}
            setSelectedOrganization={setCurrentOrganization}
            tooltipContent="The current organization sets the context for Visavys so the right people and objectives are suggested."
          />
        </div>
        <div className={fieldClassName}>
          <CalendarCombobox
            title={t("profile.currentCalendarLabel")}
            calendars={currentUser?.calendars || []}
            selectedCalendar={currentCalendar}
            setSelectedCalendar={setCurrentCalendar}
            tooltipContent="Select the calendar you want to be shown in the meetings tab. This calendar will be synced regularly and new events are created in this calendar by default."
          />
        </div>
        <div className={fieldClassName}>
          <UserCombobox
            selectedUser={manager}
            setSelectedUser={setManager}
            title={t("profile.managerLabel")}
            tooltipContent="You can be part of teams with different managers, but usually you have one line manager."
          />
        </div>
        <div className={fieldClassName}>
          <label htmlFor="timezone" className={labelClassName}>
            {t("profile.timezoneLabel")}
          </label>
          <select
            id="timezone"
            onChange={(e) => {
              setTimezone(e.target.value);
            }}
            name="timezone"
            value={timezone}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            {timezones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
        <div className={fieldClassName}>
          <label htmlFor="locale" className={labelClassName}>
            {t("profile.localeLabel")}
          </label>
          <select
            id="locale"
            onChange={(e) => {
              setLocale(e.target.value);
            }}
            name="locale"
            value={locale}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="de-DE">{t("languages.german")}</option>
            <option value="en-US">{t("languages.english")}</option>
          </select>
        </div>
        <div className={fieldClassName}>
          <label htmlFor="weekStartIndex" className={labelClassName}>
            {t("profile.weekStartIndexLabel")}
          </label>
          <select
            id="weekStartIndex"
            onChange={(e) => {
              setWeekStartIndex(Number(e.target.value));
            }}
            name="weekStartIndex"
            value={weekStartIndex}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="0">{t("calendar.sunday")}</option>
            <option value="1">{t("calendar.monday")}</option>
            <option value="2">{t("calendar.tuesday")}</option>
            <option value="3">{t("calendar.wednesday")}</option>
            <option value="4">{t("calendar.thursday")}</option>
            <option value="5">{t("calendar.friday")}</option>
            <option value="6">{t("calendar.saturday")}</option>
          </select>
        </div>
        <div className={fieldClassName}>
          <label htmlFor="paymentPlan" className={labelClassName}>
            {t("profile.paymentPlanLabel")}
          </label>
          <input
            type="text"
            name="paymentPlan"
            id="paymentPlan"
            className={`${textFieldClassName} bg-gray-100`}
            value={currentUser ? currentUser.paymentPlan : ""}
            disabled={true}
          />
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset- sm:w-auto sm:text-sm"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </div>
  );
};
