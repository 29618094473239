import type { Schema } from "prosemirror-model";
import type { EditorState } from "prosemirror-state";
import type { PluginState } from "./plugin";
import key from "./plugin-key";
import { compactRebaseable, Rebaseable } from "./rebaseable";
import { CommitData } from "./network";
import { v4 as uuidv4 } from "uuid";

const maxStepsPerCommit = 10;

export class InflightCommit<S extends Schema> {
  readonly baseVersion: number;
  readonly steps: Rebaseable<S>[];
  readonly sessionId: string;

  constructor(
    steps: Rebaseable<S>[],
    baseVersion: number,
    sessionId = uuidv4()
  ) {
    this.baseVersion = baseVersion;
    this.steps = steps;
    this.sessionId = sessionId;
  }

  sendable(): CommitData {
    return {
      baseVersion: this.baseVersion,
      sessionId: this.sessionId,
      steps: this.steps.map((step) => step.step.toJSON()),
    };
  }

  static fromState<S extends Schema>(
    editorState: EditorState
  ): InflightCommit<S> | undefined {
    const state: PluginState<S> = key.getState(editorState);

    // we may only have one inflight commit at a time
    if (state.inflightCommit) return;
    if (state.localSteps.length === 0) return;

    const sendableSteps = compactRebaseable(state.localSteps);
    state.localSteps = sendableSteps.splice(maxStepsPerCommit - 1);

    state.inflightCommit = new InflightCommit(
      sendableSteps,
      state.syncedVersion
    );

    return state.inflightCommit;
  }
}
