const getWebUrl = () => {
  return appWebUrl ? appWebUrl : document.location.origin
}

const getApiUrl = () => {
  if(appApiUrl) {
    return appApiUrl
  } else {
    if(document.location.origin.match("^https://app.visavys.(de|com)$")) {
      return document.location.origin.replace("app", "api")
    } else {
      console.log("apiUrl set to undefined")
      return "undefined"
    }
  }
}

const appWebUrl = process.env.REACT_APP_WEB_URL
const appApiUrl = process.env.REACT_APP_API_URL
export const webUrl = getWebUrl()
export const websiteUrl = document.location.origin.replace("app.", "")
export const apiUrl = getApiUrl()
export const apiEndpoint = '/api/v1'
export const websocketPath = '/websocket'

export const apiCalendarsPath = '/calendars'
export const apiEventsPath = '/events'
export const apiMeetingsPath = '/meetings'
export const apiNotesPath = '/notes'
export const apiOrganizationsPath = '/organizations'
export const apiUsersPath = '/users'

export const authGooglePath = '/auth/google_oauth2'
