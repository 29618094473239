import { useState } from "react";
import { Cycle, CurrentUser } from "../../lib/types";
import { SlideOverRight } from "../elements/SlideOverRight";
import { Calendar as CalendarComponent } from "../elements/Calendar";
import { displayDate } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { useClient, useMutation } from "jsonapi-react";
import ToggleSelect from "../elements/forms/ToggleSelect";
import { Tooltip } from "../elements/forms/Tooltip";
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";
import { startOfMonth, endOfMonth, addMonths } from "date-fns";

type CycleDetailProps = {
  currentUser: CurrentUser | null;
  selectedCycle: Cycle | null;
  setSelectedCycle: React.Dispatch<React.SetStateAction<Cycle | null>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  weekStartIndex: number;
};

export const CycleDetail = (props: CycleDetailProps) => {
  const { t } = useTranslation();
  const [editCycle] = useMutation(
    [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "cycles",
      props.selectedCycle?.id,
    ],
    {
      invalidate: ["organizations", "cycles"],
    }
  );

  const apiClient = useClient();
  const [name, setName] = useState(
    props.selectedCycle?.name ? props.selectedCycle?.name : ""
  );
  const [isArchived, setIsArchived] = useState(props.selectedCycle?.isArchived);
  const [isDefault, setIsDefault] = useState(props.selectedCycle?.default);
  const [startAt, setStartAt] = useState<Date | null | undefined>(
    props.selectedCycle?.startAt
      ? new Date(props.selectedCycle?.startAt)
      : startOfMonth(addMonths(new Date(), 1))
  );
  const [endAt, setEndAt] = useState<Date | null | undefined>(
    props.selectedCycle?.endAt
      ? new Date(props.selectedCycle?.endAt)
      : endOfMonth(addMonths(startAt || new Date(), 3))
  );
  const [isOpenStartAtDatePicker, setIsOpenStartAtDatePicker] = useState(false);
  const [isOpenEndAtDatePicker, setIsOpenEndAtDatePicker] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editCycle({
      name,
      startAt,
      endAt,
      isArchived,
      default: isDefault,
    }).then((response) => {
      if (response.data) {
        props.selectedCycle?.id
          ? notifySuccess(t("apiMessages.successCycleCreated"))
          : notifySuccess(t("apiMessages.successCycleUpdated"));
        const persistedCycle = response.data as Cycle;
        props.setSelectedCycle(persistedCycle);
      }
      notifyApiErrors(response.errors);
    });
    props.setOpen(false);
  };

  const handleDelete = async () => {
    await apiClient
      .delete([
        "organizations",
        props.currentUser?.currentOrganizationId,
        "cycles",
        props.selectedCycle?.id,
      ])
      .then((response) => {
        if (response.meta && !response.error) {
          notifySuccess(t("apiMessages.successCycleDeleted"));
          props.setOpen(false);
        } else {
          notifyApiErrors([response.error]);
        }
      });
  };

  return (
    <SlideOverRight
      open={props.open}
      setOpen={props.setOpen}
      title={
        props.selectedCycle ? props.selectedCycle.name : t("okrs.newCycleLabel")
      }
      titleIconClass="fa-repeat"
      closeLabel={t("menu.closeMenu")}
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.nameLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      id="name"
                      autoComplete="off"
                      placeholder={`e.g. Q3 ${new Date().getFullYear()}`}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="startAt"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.startAtLabel")}
                </label>
                <div className="mt-2">
                  <div className="relative">
                    <button
                      type="button"
                      name="startAt"
                      id="startAt"
                      onClick={() =>
                        setIsOpenStartAtDatePicker(!isOpenStartAtDatePicker)
                      }
                      className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                    >
                      {displayDate(startAt)}
                    </button>
                    {isOpenStartAtDatePicker && (
                      <div className="bg-gray-100 p-3 left-0 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                        <CalendarComponent
                          selectedDate={startAt}
                          setSelectedDate={setStartAt}
                          weekStartIndex={props.weekStartIndex}
                          open={isOpenStartAtDatePicker}
                          setOpen={setIsOpenStartAtDatePicker}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="endAt"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.endAtLabel")}
                </label>
                <div className="mt-2">
                  <div className="relative">
                    <button
                      type="button"
                      name="endAt"
                      id="endAt"
                      onClick={() =>
                        setIsOpenEndAtDatePicker(!isOpenEndAtDatePicker)
                      }
                      className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                    >
                      {displayDate(endAt)}
                    </button>
                    {isOpenEndAtDatePicker && (
                      <div className="bg-gray-100 p-3 right-0 absolute z-30 w-80 border-2 rounded-md border-gray-300">
                        <CalendarComponent
                          selectedDate={endAt}
                          setSelectedDate={setEndAt}
                          weekStartIndex={props.weekStartIndex}
                          open={isOpenEndAtDatePicker}
                          setOpen={setIsOpenEndAtDatePicker}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <Tooltip
                  position="top"
                  color="dark"
                  content={t("okrs.cycleDefaultTooltip")}
                >
                  <label
                    htmlFor="default"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("okrs.defaultLabel")}
                    <span
                      className="fa fa-question-circle pl-1"
                      aria-hidden="true"
                    ></span>
                  </label>
                </Tooltip>
                <div className="mt-2">
                  <ToggleSelect enabled={isDefault} setEnabled={setIsDefault} />
                </div>
              </div>

              <div className="sm:col-span-3">
                <Tooltip
                  position="top"
                  color="dark"
                  content={t("okrs.cycleArchivedTooltip")}
                >
                  <label
                    htmlFor="isArchived"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("okrs.isArchivedLabel")}
                    <span
                      className="fa fa-question-circle pl-1"
                      aria-hidden="true"
                    ></span>
                  </label>
                </Tooltip>
                <div className="mt-2">
                  <input
                    id="isArchived"
                    checked={isArchived}
                    onChange={() => setIsArchived(!isArchived)}
                    name="isArchived"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleDelete}
            disabled={props.selectedCycle ? false : true}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("menu.delete")}
          </button>
          <button
            type="button"
            onClick={() => props.setOpen(false)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("menu.close")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </SlideOverRight>
  );
};
