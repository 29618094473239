import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PropsWithChildren } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type MinimalDropdownMenuProps = {
  positionHorizontal?: "left" | "right";
  positionVertical?: "top" | "bottom";
  className?: string;
};

export const activeClass = "bg-gray-100 text-gray-900";
export const inactiveClass = "text-gray-700";
export const baseClass = "block px-4 py-2 text-sm w-full text-left";
export const itemClass = (active: Boolean) => {
  return classNames(active ? activeClass : inactiveClass, baseClass);
};

export const MinimalDropdownMenu = (
  props: MinimalDropdownMenuProps & PropsWithChildren
) => {
  const { t } = useTranslation();
  const positionHorizontal = props.positionHorizontal
    ? props.positionHorizontal
    : "right";
  const positionVertical = props.positionVertical
    ? props.positionVertical
    : "top";
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={props.className ||  "flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"}>
          <span className="sr-only">{t("menu.openOptions")}</span>
          <span className="fa fa-ellipsis-h" aria-hidden="true"></span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${positionHorizontal}-0 z-10 mt-2 w-56 origin-${positionVertical}-${positionHorizontal} rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">{props.children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
