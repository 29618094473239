import { baseKeymap } from "prosemirror-commands";
import { keymap } from "prosemirror-keymap";
import { createConsumer } from "@rails/actioncable";
import { railsCollab } from "../../lib/collabplus/rails-collab";
import { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { collabPlus, isSynced } from "../../lib/collabplus";
import "./ProseMirrorEditor.module.css";
import { useAppSelector } from "../../app/hooks";
import { exampleSetup } from "../../lib/prose/setup";
import { apiUrl, websocketPath } from "../../constants/apiUrls";
import { ProseMirror, useNodeViews } from "@nytimes/react-prosemirror";
import { EditorState } from "prosemirror-state";
import { schema } from "../../lib/prose/schema";
import { reactNodeViews } from "../../lib/prose/reactNodeViews";
import BlockId from "../../lib/prose/blockid";
import PasteHandler from "../../lib/prose/pasteHandler"
import { User } from "../../lib/types";
import { Slice } from "prosemirror-model";
import './prosemirror_menu.css';
import { useTranslation } from "react-i18next";

function getWebSocketURL(token: string) {
  return apiUrl + websocketPath + "?token=" + token;
}

type ProseMirrorEditorProps = {
  doc: string;
  docVersion: number;
  docId: string;
  participants: User[];
};

export interface RefProseMirrorEditor {
  applyTemplate: (docJson: any) => void
}

export const ProseMirrorEditor = forwardRef((props: ProseMirrorEditorProps, ref: Ref<RefProseMirrorEditor>) => {
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth);
  const authToken = auth.authToken || "";
  const consumer = createConsumer(getWebSocketURL(authToken));
  const collab = collabPlus(
    railsCollab(consumer, {
      document_id: props.docId,
    }),
    {
      startingVersion: props.docVersion || 0,
      onConnectionClose: function (e) {
        throw new Error("Function not implemented.");
      },
    }
  );

  const pasteHandler = new PasteHandler()

  const initialEditorState = EditorState.create({
    schema,
    plugins: [
      ...exampleSetup({ schema: schema }),
      keymap(baseKeymap),
      new BlockId().asPlugin(),
      collab,
      ...pasteHandler.plugins
    ],
    doc: schema.nodeFromJSON(props.doc),
  });

  const [editorState, setEditorState] = useState(initialEditorState);
  const { nodeViews, renderNodeViews } = useNodeViews(reactNodeViews);
  const [mount, setMount] = useState<HTMLDivElement | null>(null);

  const onDispatchTransaction = useCallback((tr: any) => {
    setEditorState((s) => s.apply(tr));
    const collabState = collab.getState(editorState);
    if (collabState) {
      const synced = isSynced(collabState);

      const saveStatus = document.getElementById("save-status");
      if (saveStatus) {
        saveStatus.innerText = synced ? t("menu.saved") : t("menu.saving");
      }
    }
  }, [collab, editorState, t]);

  useImperativeHandle(ref, () => {
    return {
      applyTemplate: (docJson: any) => {
        const newDoc = schema.nodeFromJSON(docJson);
        onDispatchTransaction(
          editorState.tr.replace(
          0,
          editorState.doc.content.size,
        new Slice(newDoc.content, 0, 0)
      )
    );
      }
    };
  }, [editorState.doc.content.size, editorState.tr, onDispatchTransaction]);

  useEffect(() => {
    setEditorState(initialEditorState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.doc, setEditorState])

  return (
    <>
      <div>
        <ProseMirror
          mount={mount}
          state={editorState}
          nodeViews={nodeViews}
          dispatchTransaction={onDispatchTransaction}
        >
          <div ref={setMount} />
          {renderNodeViews()}
        </ProseMirror>
      </div>
    </>
  );
})
