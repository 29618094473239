import { useEffect, useState } from "react";
import UserPlaceholder from "../assets/images/User.svg";
import { Fragment } from "react";
import { useQuery } from "jsonapi-react";
import { useAppSelector } from "../app/hooks";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../lib/toasts";
import { Team } from "../lib/types";
import { TeamDetail } from "../components/teams/TeamDetail";
import { TeamEmptyState } from "../components/teams/TeamEmptyState";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";

export const TeamList = () => {
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth);
  const [teamDetailOpen, setTeamDetailOpen] = useState(false);
  const [teams, setTeams] = useState<Team[]>([])
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(
    null
  );

  const teamsQuery = useQuery(auth.currentUser?.currentOrganizationId && [
    "organizations",
    auth.currentUser?.currentOrganizationId,
    "teams",
    {},
  ]);

  useEffect(() => {
    if (teamsQuery.data) {
      setTeams(teamsQuery.data as Team[]);
    }
    notifyApiErrors(teamsQuery.errors);
  }, [teamsQuery.data, teamsQuery.errors]);

  const handleSelectTeam = (team: Team) => {
    setSelectedTeam(team);
    setTeamDetailOpen(true);
  };

  const handleAddTeam = () => {
    setSelectedTeam(null);
    setTeamDetailOpen(true);
  };

  return (
    <section className="flex h-screen min-w-0 flex-1 flex-col xl:order-last m-6 bg-white overflow-auto">
      {teamDetailOpen && (
        <TeamDetail
          currentUser={auth.currentUser}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          open={teamDetailOpen}
          setOpen={setTeamDetailOpen}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900 mb-6">{t("teams.pageTitle")}</h1>
          <button
            type="button"
            onClick={() => handleAddTeam()}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("teams.addTeamLabel")}
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingIndicator isLoading={teamsQuery.isLoading}>
              <TeamEmptyState isEmpty={teamsQuery.data && teams.length === 0}>
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        {t("teams.nameLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("teams.locationLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("teams.managerLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("teams.membersLabel")}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {teams.map((team) => (
                      <Fragment key={team.name}>
                        <tr className="border-t border-gray-200 bg-gray-100">
                          <th
                            // colSpan={5}
                            // scope="colgroup"
                            className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            <button
                              className="hover:text-indigo-700"
                              onClick={() => handleSelectTeam(team)}
                            >
                              <span className="fa fa-bullseye pr-1"></span>
                              {team.name}
                            </button>
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            {team.location}
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            <img
                              src={
                                team.manager?.image
                                  ? team.manager.image
                                  : UserPlaceholder
                              }
                              className="flex rounded-full focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:ring-offset-2 w-6 bg-gray-100"
                              alt={
                                team.manager?.displayName
                                  ? team.manager.displayName
                                  : "unassigned"
                              }
                            />
                            {team.manager?.displayName}
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            {team.membersCount}
                          </th>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </TeamEmptyState>
            </LoadingIndicator>
          </div>
        </div>
      </div>
    </section>
  );
};
