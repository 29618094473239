import React from "react";
import { PropsWithChildren } from "react";
import { Tooltip } from "../elements/forms/Tooltip";

type ChartBoxProps = {
  title: string;
  tooltipContent?: string;
};

export const ChartBox = (props: ChartBoxProps & PropsWithChildren) => {
  return (
    <div className="flex w-[34rem] mr-3 mb-3 space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <h2 className="absolute text-lg font-bold text-gray-900 flex-1">
        {props.tooltipContent ? (
          <Tooltip
            position="bottom"
            color="dark"
            content={props.tooltipContent}
            showIcon={true}
            widthClassName="w-96"
          >
            {props.title}
          </Tooltip>
        ) : (
          props.title
        )}
      </h2>
      <div className="relative flex items-center mt-3">
        <div className="min-w-0 flex-1">{props.children}</div>
      </div>
    </div>
  );
};
