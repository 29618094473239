import React from "react";

type ChartEmptyStateProps = {
  title?: string;
  content?: string;
}

export const ChartEmptyState = (props: ChartEmptyStateProps) => {
  return (
    <div className="relative block my-3 w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
        ></path>
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
        ></path>
      </svg>
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        {props.title ? props.title : "Insufficient data"}
      </span>
      {props.content && <span className="mt-2 block text-sm font-normal text-gray-900">
        {props.content}
      </span>}
    </div>
  );
};
