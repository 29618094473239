import { useAppSelector, useAppDispatch } from "../app/hooks";
import { setCurrentUser } from "../app/authSlice"
import { Outlet, useNavigate } from "react-router-dom";
import { useClient } from "jsonapi-react";
import { useEffect } from "react"

const ProtectedRoute = () => {
  const dispatch = useAppDispatch()
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const apiClient = useClient();

  useEffect(() => {
    if (auth.authToken) {
      // With this we can make authenticated API calls
      apiClient.addHeader("Authorization", "Bearer " + auth.authToken);
      if(!auth.currentUser) {
        apiClient.fetch(['current_user']).then((response_body) => { dispatch(setCurrentUser(response_body["data"]))})
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [auth, apiClient, dispatch, navigate])

  return <Outlet />;
};

export default ProtectedRoute;
