import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { User } from "../../../lib/types";
import { useQuery } from "jsonapi-react";
import classNames from "classnames";
import { Tooltip } from "./Tooltip";

type UserComboboxProps = {
  title: string;
  placeholder?: string;
  selectedUser: User | null | undefined;
  setSelectedUser: Dispatch<SetStateAction<User | null | undefined>>;
  tooltipContent?: string;
};

export const UserCombobox = (props: UserComboboxProps) => {
  const [query, setQuery] = useState("");

  const usersQuery = useQuery(["users"]);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (usersQuery && usersQuery.data) {
      setUsers(usersQuery.data as User[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersQuery, setUsers]);

  const filteredUsers =
    query === ""
      ? users
      : users.filter((user) => {
          return user.displayName.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={props.selectedUser}
      onChange={props.setSelectedUser}
      nullable
    >
      {props.tooltipContent ? (
        <Tooltip
          position="top"
          color="dark"
          content={props.tooltipContent}
          showIcon={true}
        >
          <Combobox.Label className="text-sm font-medium leading-6 text-gray-900">
            {props.title}
          </Combobox.Label>
        </Tooltip>
      ) : (
        <Combobox.Label className="text-sm font-medium leading-6 text-gray-900">
          {props.title}
        </Combobox.Label>
      )}
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(user: User) => user?.displayName}
          placeholder={props.placeholder || ""}
          autoComplete="off"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <span
            className="fa fa-chevron-down h-5 w-5 text-gray-400"
            aria-hidden="true"
          ></span>
        </Combobox.Button>

        {filteredUsers.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredUsers.map((user) => (
              <Combobox.Option
                key={user.id}
                value={user}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={user.image}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {user.displayName}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <span className="fa fa-check" aria-hidden="true"></span>
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
