import { Dispatch, SetStateAction } from "react";
import { Calendar } from "../../../lib/types";
import { Tooltip } from "./Tooltip";

type CalendarComboboxProps = {
  title: string;
  placeholder?: string;
  calendars: Calendar[];
  selectedCalendar: Calendar | null;
  setSelectedCalendar: Dispatch<
    SetStateAction<Calendar | null>
  >;
  tooltipContent?: string;
};

export const CalendarCombobox = (props: CalendarComboboxProps) => {
  return (
    <div className="sm:col-span-3">
      {props.tooltipContent ? (
        <Tooltip
          position="top"
          color="dark"
          content={props.tooltipContent}
          showIcon={true}
        >
          <label
            htmlFor="calendar"
            className="text-sm font-medium leading-6 text-gray-900"
          >
            {props.title}
          </label>
        </Tooltip>
      ) : (
        <label
          htmlFor="organization"
          className="text-sm font-medium leading-6 text-gray-900"
        >
          {props.title}
        </label>
      )}
      <div className="mt-2">
        <select
          id="calendar"
          name="calendar"
          value={props.selectedCalendar?.id}
          onChange={(e) => props.setSelectedCalendar(props.calendars.find((o) => o.id === e.target.value) || null)}
          autoComplete="off"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          {props.calendars.map((c) => (
            <option key={c.id} value={c.id}>
              {c.externalCalendarId}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
