import React, { useEffect, useState } from "react";
import UserPlaceholder from "../assets/images/User.svg";
import { Fragment } from "react";
import { useQuery } from "jsonapi-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../lib/toasts";
import { Cycle, KeyResult, Objective } from "../lib/types";
import { differenceInDays } from "date-fns";
import { ObjectiveDetail } from "../components/okrs/ObjectiveDetail";
import { okrStatusMapping } from "../lib/utils";
import { KeyResultDetail } from "../components/okrs/KeyResultDetail";
import {
  MinimalDropdownMenu,
  itemClass,
} from "../components/elements/menus/MinmalDropdownMenu";
import { Menu } from "@headlessui/react";
import { OKREmptyState } from "../components/okrs/OKREmptyState";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";
import { useAppSelector } from "../app/hooks";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const OKRList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const [objectiveDetailOpen, setObjectiveDetailOpen] = useState(false);
  const [keyResultDetailOpen, setKeyResultDetailOpen] = useState(false);
  const [currentCycle, setCurrentCycle] = useState<Cycle | null>(null);
  const [selectedKeyResult, setSelectedKeyResult] = useState<KeyResult | null>(
    null
  );
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [objectives, setObjectives] = useState([] as Objective[]);
  const [selectedObjective, setSelectedObjective] = useState<Objective | null>(
    null
  );

  const objectivesQuery = useQuery(
    currentCycle &&
      auth.currentUser?.currentOrganizationId && [
        "organizations",
        auth.currentUser.currentOrganizationId,
        "objectives",
        {
          filter: {
            cycleId: currentCycle.id,
          },
        },
      ]
  );

  const cyclesQuery = useQuery(
    auth.currentUser?.currentOrganizationId && [
      "organizations",
      auth.currentUser.currentOrganizationId,
      "cycles",
    ]
  );

  useEffect(() => {
    if (objectivesQuery.data) {
      setObjectives(objectivesQuery.data as Objective[]);
    }
    notifyApiErrors(objectivesQuery.errors);
  }, [objectivesQuery.data, objectivesQuery.errors]);

  useEffect(() => {
    if (cyclesQuery.data) {
      const fetchedCycles = cyclesQuery.data as Cycle[];
      setCycles(fetchedCycles);
      if (!currentCycle) {
        const theCycle =
          fetchedCycles.find((c) => c.default === true) || fetchedCycles[0];
        setCurrentCycle(theCycle);
      }
      notifyApiErrors(cyclesQuery.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyclesQuery.data, cyclesQuery.errors]);

  const statusColors: { [key: string]: string } = {
    on_track: "green-200",
    behind: "yellow-200",
    at_risk: "red-200",
    closed: "gray-800",
    postponed: "blue-200",
    not_started: "gray-200",
    default: "gray-200",
  };

  const progressBarColor = (status: string) => {
    const color = statusColors[status];
    return color ? color : statusColors["default"];
  };

  const timeDelta = (
    updatedAt: string | Date | null | undefined,
    createdAt: string | Date
  ) => {
    const date = updatedAt ? new Date(updatedAt) : new Date(createdAt);
    const today = new Date();
    const delta = differenceInDays(today, date);
    return delta === 0
      ? t("calendar.today")
      : delta > 30
      ? `${Math.floor(delta / 30)}${t("calendar.monthShortest")}`
      : `${delta}${t("calendar.dayShortest")}`;
  };

  const handleSelectObjective = (objective: Objective) => {
    setSelectedObjective(objective);
    setObjectiveDetailOpen(true);
  };

  const handleSelectKeyResult = (keyResult: KeyResult) => {
    setSelectedKeyResult(keyResult);
    setSelectedObjective(keyResult.objective);
    setKeyResultDetailOpen(true);
  };

  const handleAddObjective = () => {
    setSelectedObjective(null);
    setObjectiveDetailOpen(true);
  };

  const handleAddKeyResult = (objective: Objective) => {
    setSelectedKeyResult(null);
    setSelectedObjective(objective);
    setKeyResultDetailOpen(true);
  };

  const handleCycleChange = (cycleId: string) => {
    const newCycle = cycles.find((c) => c.id === cycleId);
    if (newCycle) {
      setCurrentCycle(newCycle);
    } else {
      setCurrentCycle(null);
    }
  };

  const isCyclesEmpty = () => {
    return !cyclesQuery.data || cycles.length === 0;
  };

  const isObjectivesEmpty = () => {
    return !objectivesQuery.data || objectives.length === 0;
  };

  return (
    <>
      {objectiveDetailOpen && (
        <ObjectiveDetail
          currentUser={auth.currentUser}
          selectedObjective={selectedObjective}
          selectedCycle={currentCycle}
          cycles={cycles}
          open={objectiveDetailOpen}
          setOpen={setObjectiveDetailOpen}
          weekStartIndex={auth.currentUser?.weekStartIndex || 0}
        />
      )}
      {keyResultDetailOpen && (
        <KeyResultDetail
          currentUser={auth.currentUser}
          selectedObjective={selectedObjective}
          selectedKeyResult={selectedKeyResult}
          objectives={objectives}
          open={keyResultDetailOpen}
          setOpen={setKeyResultDetailOpen}
        />
      )}
      <section className="flex h-screen overflow-y-none w-0 flex-1 flex-col xl:order-last m-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900 mb-6">
              {t("okrs.okrPageTitle")}
            </h1>
            {!isCyclesEmpty() && (
              <button
                type="button"
                onClick={() => handleAddObjective()}
                disabled={currentCycle ? false : true}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("okrs.addObjectiveLabel")}
              </button>
            )}
          </div>
          <div className="mt-4 flex sm:ml-16 sm:mt-0 sm:flex-none pr-1">
            {isCyclesEmpty() ? (
              <button
                type="button"
                onClick={() => navigate("/cycles", { replace: true })}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add First Cycle
              </button>
            ) : (
              <>
                <select
                  id="cycle"
                  name="cycle"
                  value={currentCycle?.id}
                  onChange={(e) => handleCycleChange(e.target.value)}
                  autoComplete="off"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {cycles.map((cycle) => (
                    <option key={cycle.id} value={cycle.id}>
                      {cycle?.name}
                    </option>
                  ))}
                </select>
                <MinimalDropdownMenu positionHorizontal="right" className="ml-2 h-7 w-7 flex justify-center items-center rounded-full bg-white text-gray-400 border border-gray-300 hover:bg-indigo-400 hover:text-white gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => navigate("/cycles", { replace: true })}
                        className={itemClass(active)}
                      >
                        {t("okrs.addCycleLabel")}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => navigate("/cycles", { replace: true })}
                        className={itemClass(active)}
                      >
                        {t("okrs.editCyclesLabel")}
                      </button>
                    )}
                  </Menu.Item>
                </MinimalDropdownMenu>
              </>
            )}
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <LoadingIndicator isLoading={objectivesQuery.isLoading}>
                <OKREmptyState isEmpty={isObjectivesEmpty() || isCyclesEmpty()}>
                  <table className="w-full">
                    <thead className="bg-gray-300">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          {t("okrs.okrLabel")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {t("okrs.ownerLabel")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {t("okrs.cycleLabel")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {t("okrs.progressLabel")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {t("okrs.actionsLabel")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {objectives.map((objective) => (
                        <Fragment key={objective.title}>
                          <tr className="border-t border-gray-200 bg-gray-200">
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                              <button
                                className="hover:text-indigo-700"
                                onClick={() => handleSelectObjective(objective)}
                              >
                                <span className="fa fa-bullseye pr-1"></span>
                                {objective.title}
                              </button>
                            </th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                              <img
                                src={
                                  objective.owner?.image
                                    ? objective.owner.image
                                    : UserPlaceholder
                                }
                                className="flex rounded-full focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:ring-offset-2 w-6 bg-gray-100"
                                alt={
                                  objective.owner?.displayName
                                    ? objective.owner.displayName
                                    : "unassigned"
                                }
                              />
                              {objective.owner?.displayName}
                            </th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                              {objective.cycle?.name}
                            </th>
                            <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                              <div className="mb-6 h-5 w-full bg-gray-300 rounded-md">
                                <div
                                  className={`h-5 bg-${progressBarColor(
                                    objective.status
                                  )} whitespace-nowrap overflow-visible rounded-md font-semibold`}
                                  style={
                                    {
                                      width: `${objective.progress}%`,
                                    } as React.CSSProperties
                                  }
                                >
                                  <span className="pl-2">
                                    {t(
                                      `okrs.${
                                        okrStatusMapping[objective.status]
                                      }`
                                    )}{" "}
                                    ({objective.progress}%)
                                  </span>
                                </div>
                                <span className="font-semibold">
                                  {objective.progress}%
                                </span>
                                {` ${t("okrs.completeLabel")} - `}
                                {timeDelta(
                                  objective.updatedAt,
                                  objective.createdAt
                                )}
                              </div>
                            </th>
                            <th className="flex space-x-1 py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                              <button
                                className="h-5 w-5 flex justify-center items-center rounded-full bg-indigo-500 text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={() => handleAddKeyResult(objective)}
                              >
                                <span
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></span>
                              </button>
                              <MinimalDropdownMenu positionHorizontal="right" className="h-5 w-5 flex justify-center items-center rounded-full bg-indigo-500 text-white hover:bg-indigo-400 gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={itemClass(active)}
                                      onClick={() =>
                                        handleAddKeyResult(objective)
                                      }
                                    >
                                      {t("okrs.addKeyResultLabel")}
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={itemClass(active)}
                                      onClick={() =>
                                        handleSelectObjective(objective)
                                      }
                                    >
                                      {t("menu.edit")}
                                    </button>
                                  )}
                                </Menu.Item>
                              </MinimalDropdownMenu>
                            </th>
                          </tr>
                          {objective.keyResults.map(
                            (keyResult, keyResultIdx) => (
                              <tr
                                key={keyResult.title}
                                className={classNames(
                                  keyResultIdx === 0
                                    ? "border-gray-300"
                                    : "border-gray-200",
                                  "border-t"
                                )}
                              >
                                <td className="w-min-50 py-4 pl-4 pr-3 text-sm font-medium sm:pl-3">
                                  <button
                                    className="flex text-left hover:text-indigo-700"
                                    onClick={() =>
                                      handleSelectKeyResult(keyResult)
                                    }
                                  >
                                    <span
                                      className="fa fa-reply rotate-180"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="fa fa-thermometer-half pl-3 pr-1"></span>
                                    <div>
                                      {keyResult.title}
                                    </div>
                                  </button>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                  <img
                                    src={
                                      keyResult.owner?.image
                                        ? keyResult.owner.image
                                        : UserPlaceholder
                                    }
                                    className="flex rounded-full focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:ring-offset-2 w-6 bg-gray-100"
                                    alt={
                                      keyResult.owner?.displayName
                                        ? keyResult.owner.displayName
                                        : "unassigned"
                                    }
                                  />
                                  {keyResult.owner?.displayName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                  {objective.cycle?.name}
                                </td>
                                <td className="px-3 py-4 text-sm">
                                  <div className="mb-6 h-5 w-min-50 rounded-md bg-gray-300">
                                    <div
                                      className={`h-5 bg-${progressBarColor(
                                        keyResult.status
                                      )} whitespace-nowrap overflow-visible rounded-md font-semibold`}
                                      style={
                                        {
                                          width: `${keyResult.progress}%`,
                                        } as React.CSSProperties
                                      }
                                    >
                                      <span className="pl-2">
                                        {t(
                                          `okrs.${
                                            okrStatusMapping[keyResult.status]
                                          }`
                                        )}{" "}
                                        ({keyResult.progress}%)
                                      </span>
                                    </div>
                                    {keyResult.valueType === "number" ? (
                                      <>
                                        <span className="font-semibold">
                                          {keyResult.valueUnit}:{" "}
                                          {keyResult.currentValue}{" "}
                                        </span>
                                        {`(${t("okrs.startLabel")}: ${
                                          keyResult.startValue
                                        } | ${t("okrs.targetLabel")}: ${
                                          keyResult.targetValue
                                        }) - `}
                                        {timeDelta(
                                          keyResult.updatedAt,
                                          keyResult.createdAt
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {`${keyResult.progress}% ${t(
                                          "okrs.completeLabel"
                                        )} - `}
                                        {timeDelta(
                                          keyResult.updatedAt,
                                          keyResult.createdAt
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3"></td>
                              </tr>
                            )
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </OKREmptyState>
              </LoadingIndicator>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
