import { createSlice } from "@reduxjs/toolkit";
import { userRegister, userLogin } from "./authActions";
import { CurrentUser } from "../lib/types"
import {
  deleteAuthHeaders,
  setAuthHeaders,
  getAuthToken,
  persistAuthHeadersInLocalStorage,
  deleteAuthHeadersFromLocalStorage,
  persistLocaleSettingsInLocalStorage,
} from "./authService";

export interface AuthState {
  loading: boolean;
  authToken: string | null;
  currentUser: CurrentUser | null;
  error: any;
  success: boolean;
}

const initialState: AuthState = {
  loading: false,
  authToken: getAuthToken(),
  currentUser: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      deleteAuthHeadersFromLocalStorage();
      deleteAuthHeaders();
      state.authToken = null;
      state.loading = false;
      state.currentUser = null;
      state.error = null;
    },
    setCredentials: (state, action) => {
      persistAuthHeadersInLocalStorage(action.payload);
      setAuthHeaders(action.payload);
      state.authToken = getAuthToken();
    },
    setCurrentUser: (state, action) => {
      const data = action.payload
      persistLocaleSettingsInLocalStorage(data);
      state.currentUser = data;
    },
  },
  extraReducers: (builder) => {
    // userLogin
    builder.addCase(userLogin.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      setAuthHeaders(action.payload);
      persistAuthHeadersInLocalStorage(action.payload.currentUser);
      persistLocaleSettingsInLocalStorage(action.payload.currentUser);
      state.loading = false;
      state.currentUser = action.payload.currentUser;
      state.authToken = getAuthToken();
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // userRegister
    builder.addCase(userRegister.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userRegister.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true; // registration successful
    });
    builder.addCase(userRegister.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { logout, setCredentials, setCurrentUser } = authSlice.actions;

export const authReducer = authSlice.reducer;
