import { useState } from "react";
import { User, CurrentUser } from "../../lib/types";
import { SlideOverRight } from "../elements/SlideOverRight";
import { useTranslation } from "react-i18next";
import { useClient, useMutation } from "jsonapi-react";
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";

type MemberDetailProps = {
  currentUser: CurrentUser | null;
  selectedUser: User | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MemberDetail = (props: MemberDetailProps) => {
  const { t } = useTranslation();
  const apiClient = useClient();
  const getCurrentOrganizationMembership = () => {
    return props.selectedUser?.organizationMemberships[0]
  };
  const [status, setStatus] = useState<string | undefined>(
    getCurrentOrganizationMembership()?.status
  );
  const [role, setRole] = useState<string | undefined>(
    getCurrentOrganizationMembership()?.role
  );
  const allStatus = ["active", "pendingInvitation", "banned"];
  const allRoles = ["admin", "member", "guest"];
  const [editUser] = useMutation(
    [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "users",
      props.selectedUser?.id,
    ],
    {
      invalidate: ["organizations", "users"],
    }
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editUser({
      role,
      status,
    });
    props.setOpen(false);
  };

  const handleDelete = async () => {
    await apiClient
      .delete([
        "organizations",
        props.currentUser?.currentOrganizationId,
        "users",
        props.selectedUser?.id,
      ])
      .then((response) => {
        if (response.meta && !response.error) {
          notifySuccess(t("apiMessages.successCycleDeleted"));
          props.setOpen(false);
        } else {
          notifyApiErrors([response.error]);
        }
      });
  };

  return (
    <SlideOverRight
      open={props.open}
      setOpen={props.setOpen}
      title={
        props.selectedUser
          ? props.selectedUser.displayName
          : t("organizations.newMemberLabel")
      }
      titleIconClass="fa-user"
      closeLabel="Close"
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("organizations.statusLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {allStatus.map((s) => (
                      <option key={s} value={s}>
                        {t(`organizations.${s}Status`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("organizations.roleLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {allRoles.map((s) => (
                      <option key={s} value={s}>
                        {t(`organizations.${s}Role`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleDelete}
            disabled={props.selectedUser ? false : true}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("menu.delete")}
          </button>
          <button
            type="button"
            onClick={() => props.setOpen(false)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("menu.cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </SlideOverRight>
  );
};
