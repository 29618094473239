import { canSplit, ReplaceAroundStep } from "prosemirror-transform";
import { v4 as uuidv4 } from "uuid";
import {
  Slice,
  Fragment,
  Node,
  NodeType,
  Attrs,
  MarkType,
  ContentMatch,
} from "prosemirror-model";
import {
  Selection,
  EditorState,
  Transaction,
  TextSelection,
  NodeSelection,
  SelectionRange,
  AllSelection,
  Command,
} from "prosemirror-state";

export const LIST_ITEM_TYPE_NAMES = ["bullet_point", "numbered_list_item"];
export const CHECKBOX_ITEM_TYPE_NAMES = ["action_item", "discussion_item"];
export const ASSIGNABLE_TYPE_NAMES = [
  "action_item",
  "paragraph_item",
  "discussion_item",
];
export const TEXT_TYPE_NAMES = ["paragraph_item", "bullet_point"]
const MAX_INDENT = 6;
const MIN_INDENT = 0;

const isParagraph = (typeName: string) => {
  return "paragraph_item" === typeName;
};

const isAssignableTypeNameWithoutParagraph = (typeName: string) => {
  return !isParagraph(typeName) && ASSIGNABLE_TYPE_NAMES.includes(typeName);
};

/// Returns a command that tries to set the selected textblocks to the
/// given node type with the given attributes.
export const setBlockType = (
  nodeType: NodeType,
  attrs?: { [key: string]: unknown | null },
  selection: Selection | null = null
) => {
  // selection determines the position of this operation
  // is not given, state.selection is used
  let newAttrs = attrs ? attrs : {};
  if (!Object.isExtensible(newAttrs)) {
    newAttrs = { ...newAttrs };
  }
  if (!("block_type" in newAttrs)) {
    newAttrs["block_type"] = nodeType.name;
  }
  function canChangeType(doc: Node, pos: number, type: NodeType) {
    const $pos = doc.resolve(pos);
    const index = $pos.index();
    return $pos.parent.canReplaceWith(index, index + 1, type);
  }
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    const { from, to } = selection || state.selection;
    let applicable = false;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (applicable) return false;
      if (
        !node.isTextblock ||
        node.hasMarkup(nodeType, { ...node.attrs, ...newAttrs })
      )
        return;
      if (node.type === nodeType) {
        applicable = true;
      } else {
        applicable = canChangeType(state.doc, pos, nodeType);
      }
      return;
    });
    if (!applicable) return false;
    if (dispatch) {
      const tr = state.tr;
      tr.doc.nodesBetween(from, to, (node: Node, pos: number) => {
        const attrsWithId = { ...node.attrs, ...newAttrs };
        if (
          node.isTextblock &&
          !node.hasMarkup(nodeType, attrsWithId) &&
          canChangeType(tr.doc, tr.mapping.map(pos), nodeType)
        ) {
          tr.clearIncompatible(tr.mapping.map(pos, 1), nodeType);
          const mapping = tr.mapping;
          const mappingStart = mapping.map(pos, 1),
            mappingEnd = mapping.map(pos + node.nodeSize, 1);
          const extraAttrs: { [key: string]: unknown } = {};
          if (
            !node.attrs.indent &&
            LIST_ITEM_TYPE_NAMES.includes(nodeType.name)
          ) {
            extraAttrs.indent = 1;
          } else if (
            node.attrs.indent &&
            LIST_ITEM_TYPE_NAMES.includes(node.type.name) &&
            ASSIGNABLE_TYPE_NAMES.includes(nodeType.name) &&
            "indent" in (nodeType.spec.attrs || {})
          ) {
            extraAttrs.indent = node.attrs.indent - 1;
          }
          tr.step(
            new ReplaceAroundStep(
              mappingStart,
              mappingEnd,
              mappingStart + 1,
              mappingEnd - 1,
              new Slice(
                Fragment.from(
                  nodeType.create(
                    { ...attrsWithId, ...extraAttrs },
                    Fragment.empty,
                    node.marks
                  )
                ),
                0,
                0
              ),
              1,
              true
            )
          );
          return false;
        }
        return;
      });
      dispatch(tr);
    }
    return true;
  };
};

function markApplies(
  doc: Node,
  ranges: readonly SelectionRange[],
  type: MarkType
) {
  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    let can =
      $from.depth === 0
        ? doc.inlineContent && doc.type.allowsMarkType(type)
        : false;
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) return false;
      can = node.inlineContent && node.type.allowsMarkType(type);
    });
    if (can) return true;
  }
  return false;
}

/// Create a command function that toggles the given mark with the
/// given attributes. Will return `false` when the current selection
/// doesn't support that mark. This will remove the mark if any marks
/// of that type exist in the selection, or add it otherwise. If the
/// selection is empty, this applies to the [stored
/// marks](#state.EditorState.storedMarks) instead of a range of the
/// document.
export function toggleMark(
  markType: MarkType,
  attrs: Attrs | null = null
): Command {
  return function (state, dispatch) {
    let { empty, $cursor, ranges } = state.selection as TextSelection;
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType))
      return false;
    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks()))
          dispatch(state.tr.removeStoredMark(markType));
        else dispatch(state.tr.addStoredMark(markType.create(attrs)));
      } else {
        let has = false,
          tr = state.tr;
        for (let i = 0; !has && i < ranges.length; i++) {
          let { $from, $to } = ranges[i];
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }
        for (let i = 0; i < ranges.length; i++) {
          let { $from, $to } = ranges[i];
          if (has) {
            tr.removeMark($from.pos, $to.pos, markType);
          } else {
            let from = $from.pos,
              to = $to.pos,
              start = $from.nodeAfter,
              end = $to.nodeBefore;
            let spaceStart =
              start && start.isText ? /^\s*/.exec(start.text!)![0].length : 0;
            let spaceEnd =
              end && end.isText ? /\s*$/.exec(end.text!)![0].length : 0;
            if (from + spaceStart < to) {
              from += spaceStart;
              to -= spaceEnd;
            }
            tr.addMark(from, to, markType.create(attrs));
          }
        }
        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
}

function defaultBlockAt(match: ContentMatch) {
  for (let i = 0; i < match.edgeCount; i++) {
    let { type } = match.edge(i);
    if (type.isTextblock && !type.hasRequiredAttrs()) return type;
  }
  return null;
}

/// Create a variant of [`splitBlock`](#commands.splitBlock) that uses
/// a custom function to determine the type of the newly split off block.
export function splitBlockAs(
  splitNode?: (
    node: Node,
    atEnd: boolean
  ) => { type: NodeType; attrs?: Attrs } | null
): Command {
  return (state, dispatch) => {
    let { $from, $to } = state.selection;
    if (
      state.selection instanceof NodeSelection &&
      state.selection.node.isBlock
    ) {
      if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) return false;
      if (dispatch) dispatch(state.tr.split($from.pos).scrollIntoView());
      return true;
    }

    if (!$from.parent.isBlock) return false;

    if (dispatch) {
      let atEnd = $to.parentOffset === $to.parent.content.size;
      let tr = state.tr;
      if (
        state.selection instanceof TextSelection ||
        state.selection instanceof AllSelection
      )
        tr.deleteSelection();
      let deflt =
        $from.depth === 0
          ? null
          : defaultBlockAt($from.node(-1).contentMatchAt($from.indexAfter(-1)));
      let splitType = splitNode && splitNode($to.parent, atEnd);
      let types = splitType
        ? [splitType]
        : atEnd && deflt
        ? [{ type: deflt }]
        : undefined;
      let can = canSplit(tr.doc, tr.mapping.map($from.pos), 1, types);
      if (
        !types &&
        !can &&
        canSplit(
          tr.doc,
          tr.mapping.map($from.pos),
          1,
          deflt ? [{ type: deflt }] : undefined
        )
      ) {
        if (deflt) types = [{ type: deflt }];
        can = true;
      }
      if (can) {
        tr.split(tr.mapping.map($from.pos), 1, types);
        if (!atEnd && !$from.parentOffset && $from.parent.type !== deflt) {
          let first = tr.mapping.map($from.before()),
            $first = tr.doc.resolve(first);
          if (
            deflt &&
            $from
              .node(-1)
              .canReplaceWith($first.index(), $first.index() + 1, deflt)
          )
            tr.setNodeMarkup(tr.mapping.map($from.before()), deflt);
        }
      }
      dispatch(tr.scrollIntoView());
    }
    return true;
  };
}

/// Split the parent block of the selection. If the selection is a text
/// selection, also delete its content.
export const splitBlock: Command = splitBlockAs();

/// Acts like [`splitBlock`](#commands.splitBlock), but without
/// resetting the set of active marks at the cursor.
export const splitBlockKeepMarks: Command = (state, dispatch) => {
  return splitBlock(
    state,
    dispatch &&
      ((tr) => {
        let marks =
          state.storedMarks ||
          (state.selection.$to.parentOffset && state.selection.$from.marks());
        if (marks) tr.ensureMarks(marks);
        dispatch(tr);
      })
  );
};

// Indent nodes like paragraph_item, action_item etc. by provided delta.
// Delta can be positive or negative. Indentation is only executed if indent attribute exists and will stay within min
// to max after applying the delta.
export const indentBy = (delta: number, max = MAX_INDENT, min = MIN_INDENT) => {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { $from, $to } = state.selection;
    const range = $from.blockRange($to);
    if (!range) return false;
    let tr = state.tr;
    let indentationExecuted = false;
    const parentStart = state.doc.resolve(range.start).start(range.depth);
    range.parent.nodesBetween(
      range.start - parentStart,
      range.end - parentStart,
      (node, pos) => {
        if (
          node.attrs.indent === undefined ||
          node.attrs.indent === null ||
          node.attrs.indent + delta < min ||
          node.attrs.indent + delta > max
        ) {
          return;
        }

        tr = tr.setNodeMarkup(pos, node.type, {
          ...node.attrs,
          indent: node.attrs.indent + delta,
        });
        indentationExecuted = true;
      }
    );
    if (indentationExecuted && dispatch) {
      dispatch(tr);
    }
    return true; // we don't want to delegate the keypress if we cannot handle the node
  };
};

export const indentOnEnter = () => {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { selection } = state;
    if (!selection.empty) return false;
    if (!isSelectionAtBeginningOfBlock(selection)) return false;
    const parent = selection.$from.parent;
    if (parent.textBetween(0, parent.content.size, undefined, "\n"))
      return false;
    const node = selection.$to.node();
    // special case the PLAIN_TEXT_NODE_TYPE_NAMES (except unstyled itself) because the desired behavior for them is to
    // be turned into an unstyled block, instead of dedented.
    if (!CHECKBOX_ITEM_TYPE_NAMES.includes(node.type.name)) return false;

    return indentBy(-1)(state, dispatch);
  };
};

export const getBlockPos = (pos: number, doc: Node, side = -1) => {
  let start = 0;
  for (let i = 0; i < doc.childCount; i++) {
    const child = doc.child(i);
    if (start + child.nodeSize > pos || start === pos) {
      if (side <= 0) {
        return start;
      } else {
        return start + child.nodeSize;
      }
    }
    start = start + child.nodeSize;
  }
  return null;
};

export const enterAtBeginningOfLine = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void
) => {
  const { $from, empty } = state.selection;
  if (!empty) return false;
  if (!isSelectionAtBeginningOfBlock(state.selection)) return false;
  if ($from.node().type.name.startsWith("header")) {
    return false;
  }
  const initialAttrs = (() => {
    const indent = $from.node().attrs.indent;
    if (indent !== undefined) {
      return { indent };
    }
    return {};
  })();
  const newNode = $from
    .node()
    .type.createAndFill({ ...initialAttrs, blockId: uuidv4() });
  if (!newNode) return false;
  const currentBlockStart = getBlockPos($from.pos, state.doc);
  if (currentBlockStart === null) return false;
  if ($from.node(-1).type.name !== "doc") return false;
  if (dispatch) {
    const tr = state.tr;
    tr.insert(currentBlockStart, newNode);
    tr.setSelection(
      TextSelection.create(tr.doc, tr.mapping.map(currentBlockStart + 1))
    );
    tr.scrollIntoView();
    dispatch(tr);
  }
  return true;
};

export const enterAtEndOfLine = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void
) => {
  const { $from, empty, $to } = state.selection;
  if (!empty) return false;
  if ($from.nodeAfter) return false;
  const initialAttrs = (() => {
    const indent = $from.node().attrs.indent;
    if (indent !== undefined) {
      return { indent };
    }
    return {};
  })();
  if ($from.node().type.name.startsWith("header")) {
    return false;
  }
  const newNode = $from
    .node()
    .type.createAndFill({ ...initialAttrs, blockId: uuidv4() });
  if (!newNode) return false;
  if (!$from.node().canAppend(newNode)) return false;
  if ($from.node(-1).type.name !== "doc") return false; // only do this in doc's direct children
  if (dispatch) {
    const tr = state.tr;
    const side = (
      !$from.parentOffset && $to.index() < $to.parent.childCount ? $from : $to
    ).pos;
    tr.insert($from.pos + 1, newNode);
    tr.setSelection(TextSelection.create(tr.doc, side + 2));
    tr.scrollIntoView();
    dispatch(tr);
  }
  return true;
};

export const isSelectionAtBeginningOfBlock = (selection: Selection) => {
  return !selection.$from.nodeBefore;
};

export const getText = (block: Node, leafText = "\n") => {
  if (block.isText) {
    return block.text || "";
  }
  if (block.isLeaf && !block.isText) {
    return leafText;
  }
  return block.textBetween(0, block.content.size, "", leafText);
};

// returns true when the node looks like an empty line
// false when there are checkboxes without text etc.
const isBlank = (node: Node) => {
  if (getText(node)) return false;
  if (isAssignableTypeNameWithoutParagraph(node.type.name)) return false;
  if (ASSIGNABLE_TYPE_NAMES.includes(node.type.name) && node.attrs.indent > 0)
    return false;
  return true;
};

export const setEmptyBlockToParagraph =
  () => (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { selection } = state;
    if (!selection.empty) return false;
    if (!isSelectionAtBeginningOfBlock(selection)) return false;
    const parent = selection.$from.parent;
    if (parent.textBetween(0, parent.content.size, undefined, "\n"))
      return false;
    if (isParagraph(parent.type.name)) return false;
    let attrs = selection.$from.parent.attrs;
    if (
      !CHECKBOX_ITEM_TYPE_NAMES.includes(parent.type.name) &&
      !isParagraph(parent.type.name) &&
      attrs.indent > 0
    ) {
      attrs = { ...attrs, indent: parent.attrs.indent - 1 };
    }
    return setBlockType(state.schema.nodes.paragraph_item, attrs)(
      state,
      dispatch
    );
  };

// Unindent block when cursor at beginning of line
export const unindentBlock =
  () => (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const {
      empty,
      $from: { nodeBefore, nodeAfter, pos },
      $to,
    } = state.selection;
    const node = $to.node();
    if (!ASSIGNABLE_TYPE_NAMES.includes(node.type.name)) return false;

    if (empty && !nodeBefore && (nodeAfter || isParagraph(node.type.name))) {
      if (!node.attrs.indent) return false;
      if (
        node.attrs.indent === 0 &&
        ASSIGNABLE_TYPE_NAMES.includes(node.type.name)
      ) {
        return false;
      }
      if (dispatch) {
        const indent = node.attrs.indent - 1;
        const tr = state.tr.setNodeMarkup(pos - 1, node.type, {
          ...node.attrs,
          indent,
        });
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
    return false;
  };

//
export const convertToParagraphItem =
  () => (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { selection } = state;
    if (!selection.empty || !isSelectionAtBeginningOfBlock(selection))
      return false;
    const parent = selection.$from.parent;
    if (isAssignableTypeNameWithoutParagraph(parent.type.name)) {
      let attrs = parent.attrs.indent > 0 ? { ...parent.attrs, indent: parent.attrs.indent - 1 } : parent.attrs
      return setBlockType(state.schema.nodes.paragraph_item, attrs)(
        state,
        dispatch
      );
    }
    return false;
  };

export const removeEmptyBlockBefore =
  (s: Selection | null = null) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const selection = s || state.selection;
    const { $from, empty } = selection;
    if (!empty || !isSelectionAtBeginningOfBlock(selection)) return false;
    const { node, offset } = state.doc.childBefore(
      $from.pos - $from.parentOffset - 1
    );
    if (!node || !isBlank(node)) return false;
    if (dispatch) {
      let tr = state.tr;
      tr = tr.deleteRange(offset, offset + 2);
      dispatch(tr);
    }
    return true;
  };

/**
 * Prevents deleting when the document is empty.
 */
export const deleteOnEmptyDocument =
  (s: Selection | null = null) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    return (state.doc.textContent.length === 0) ? true : false
  };


export const widenSelectionToBlockEdges = (
  start: number,
  end: number,
  doc: Node
) => {
  const $start = doc.resolve(start);
  const $end = doc.resolve(end);
  if ($start.node().type.name === "doc" && $end.node().type.name === "doc") {
    return [start, end];
  }
  return [$start.start(1) - 1, $end.end(1) + 1];
};

export const mergeWithPreviousEmptyBlock =
  (s: Selection | null = null) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const selection = s || state.selection;
    const { doc } = state;
    if (!selection.empty) return false;
    if (!isSelectionAtBeginningOfBlock(selection)) return false;
    const [currentBlockStart, currentBlockEnd] = widenSelectionToBlockEdges(
      selection.from,
      selection.to,
      doc
    );
    if (currentBlockStart === selection.from || currentBlockStart === 0)
      return false;
    const prevBlock = doc.childBefore(currentBlockStart).node;
    if (!prevBlock || !prevBlock.isBlock || prevBlock.textContent) return false;

    // If it's an embed, select it instead of deleting it
    if (prevBlock.type.name === "embed") {
      // Default behaviour on an empty line is to select the embed
      if (isBlank(selection.$from.parent)) return false;

      if (dispatch) {
        let tr = state.tr;
        tr = tr.setSelection(
          NodeSelection.create(tr.doc, currentBlockStart - 1)
        );
        dispatch(tr);
      }
      return true;
    }
    if (dispatch) {
      const text = selection.$from.parent.content;
      const tr = state.tr;
      tr.insert(currentBlockStart - 1, text);
      tr.deleteRange(
        tr.mapping.map(currentBlockStart),
        tr.mapping.map(currentBlockEnd)
      );
      tr.setSelection(TextSelection.create(tr.doc, currentBlockStart - 1));
      dispatch(tr);
    }
    return true;
  };
