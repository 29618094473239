import { Plugin } from "prosemirror-state";
import { Extension } from "./extension";

/**
 * Cleans up pasted content, especially directly from prose when attributes don't make sense.
 */
export default class Paste extends Extension {
	get name() {
		return "pasteHandler";
	}

	get plugins() {
		const plugin = new Plugin({
			appendTransaction: (trs, _oldState, newState) => {
				for (const tr of trs) {
					if (tr.getMeta("paste")) {
						const { from, to, $to } = newState.selection;
						const marks = $to.marks();
						const newTr = newState.tr;
						marks.forEach(mark => {
							newTr.removeStoredMark(mark).removeMark(from, to, mark);
						});
						return newTr.setStoredMarks([]);
					}
				}
				return null;
			},
			props: {
				transformPastedHTML(html: string) {
					try {
						const content = document.createElement("div");
						content.innerHTML = html;
						content.querySelectorAll("[data-assignee_id], [data-block_id], [data-creator_id]").forEach(e => {
              e.removeAttribute("data-assignee_id");
							e.removeAttribute("data-block_id");
							e.removeAttribute("data-creator_id");
						});

						return content.innerHTML;
					} catch (error) {
						console.error(error);
						return html;
					}
				},
			},
		});
		return [plugin];
	}
}
