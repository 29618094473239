import { Outlet } from "react-router-dom";
import { NavSidebar } from "../navigation/NavSidebar";

export const Layout = () => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-1 overflow-auto">
        <NavSidebar />
        <main className="ml-20 pl-1 flex-1 border-t border-gray-200 flex bg-gray-100">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
