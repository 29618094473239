import { Dispatch, SetStateAction, useState } from "react";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";

type MultiSelectProps = {
  title: string;
  selectedValues: any[];
  setSelectedValues: Dispatch<SetStateAction<any[]>>;
  allValues: any[];
  displayValue: (value: any) => string;
  by?: string;
};

export const MultiSelect = (props: MultiSelectProps) => {
  const [query, setQuery] = useState("");

  const filteredValues =
    query === ""
      ? props.allValues
      : props.allValues.filter((value) => {
          return props
            .displayValue(value)
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const handleRemove = (value: any) => {
    const newSelectedValues = props.selectedValues.filter((v) => {
      return !equals(v, value);
    });
    props.setSelectedValues(newSelectedValues);
  };

  const identity = (value: any) =>
    props.by ? value[props.by as keyof typeof value] : value;

  const equals = (a: any, b: any) =>
    props.by ? a[props.by as keyof typeof a] === b[props.by as keyof typeof b] : a === b;

  return (
    <>
      <Combobox
        as="div"
        value={props.selectedValues}
        by={props.by}
        onChange={props.setSelectedValues}
        multiple
      >
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          {props.title}
        </Combobox.Label>
        {props.selectedValues.map((value) => (
          <span className="inline-flex mr-2 mt-2 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
            {props.displayValue(value)}
            <button onClick={() => handleRemove(value)}>
              <i className="fa fa-times ml-1" aria-hidden="true"></i>
            </button>
          </span>
        ))}
        <div className="relative mt-2">
          <div className="relative">
            <Combobox.Input
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(value) => props.displayValue(value)}
              autoComplete="off"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <span
                className="fa fa-chevron-down h-5 w-5 text-gray-400"
                aria-hidden="true"
              ></span>
            </Combobox.Button>
          </div>

          {filteredValues.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredValues.map((value) => (
                <Combobox.Option
                  key={identity(value)}
                  value={value}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            "truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {props.displayValue(value)}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <span
                            className="fa fa-check"
                            aria-hidden="true"
                          ></span>
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
};
