import { Calendar } from "../components/elements/Calendar";
import { Event } from "../lib/types";
import { MeetingListItem } from "../components/meetings/MeetingListItem";
import { useQuery } from "jsonapi-react";
import { useState, useEffect } from "react";
import { startOfDay, endOfDay, getUnixTime } from "date-fns";
import { MeetingDetail } from "../components/meetings/MeetingDetail";
import { MeetingEmptyState } from "../components/meetings/MeetingEmptyState";
import { useParams } from "react-router-dom";
import { OrganizationModal } from "../components/organizations/OrganizationModal";
import { useAppSelector } from "../app/hooks";
import { EditEventModal } from "../components/meetings/EditEventModal";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../lib/toasts";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";

export const MeetingList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const [newEventIsOpen, setNewEventIsOpen] = useState(false);
  const [onboardingRequired, setOnboardingRequired] = useState(false);
  const params = useParams();
  const [isOpenCalendar, setIsOpenCalendar] = useState(true)
  const [eventId, setEventId] = useState(params.eventId);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(new Date());
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [fromFilter, setFromFilter] = useState(
    getUnixTime(startOfDay(selectedDate || new Date()))
  );
  const [untilFilter, setUntilFilter] = useState(
    getUnixTime(endOfDay(selectedDate || new Date()))
  );
  const eventsQuery = useQuery([
    "events",
    {
      filter: {
        from: fromFilter,
        until: untilFilter,
        eventId: eventId,
      },
      include: ["meeting", "attendees", "note"],
    },
  ]);

  const [events, setEvents] = useState<Event[]>([])

  useEffect(() => {
    if (eventsQuery.data) {
      setEvents(eventsQuery.data as Event[]);
    }
    notifyApiErrors(eventsQuery.errors);
  }, [eventsQuery.data, eventsQuery.errors]);

  const currentEventQuery = useQuery(
    eventId && ["events", eventId, { include: ["meeting"] }]
  );

  useEffect(() => {
    if (currentEventQuery.data) {
      const newEvent = currentEventQuery.data as Event
      setCurrentEvent(newEvent);
      newEvent.startAt && setSelectedDate(new Date(newEvent.startAt));
    }
    notifyApiErrors(currentEventQuery.errors);
  }, [currentEventQuery.data, currentEventQuery.errors]);

  const changeCurrentEvent = (event: Event) => {
    setCurrentEvent(event);
    setEventId(undefined);
    navigate("/meetings");
  };

  useEffect(() => {
    selectedDate && setFromFilter(getUnixTime(startOfDay(selectedDate)));
    selectedDate && setUntilFilter(getUnixTime(endOfDay(selectedDate)));
  }, [selectedDate]);

  useEffect(() => {
    if (auth.currentUser?.onboardedAt === null) {
      setOnboardingRequired(true);
    }
  }, [auth]);

  return (
    <>
      <section className="flex h-full min-w-0 flex-1 flex-col order-last bg-gray-100">
        {newEventIsOpen && (
          <EditEventModal setIsOpen={setNewEventIsOpen} currentEvent={null} />
        )}
        {onboardingRequired && (
          <OrganizationModal
            setOnboardingRequired={setOnboardingRequired}
            currentUser={auth.currentUser}
          />
        )}
        <MeetingEmptyState
          setNewEventIsOpen={setNewEventIsOpen}
          isEmpty={!currentEvent}
        >
          {currentEvent && auth.currentUser && (
            <MeetingDetail
              eventId={currentEvent.id}
              currentUser={auth.currentUser}
            />
          )}
        </MeetingEmptyState>
      </section>
      <aside className="">
        <div className="flex h-full min-h-screen w-96 flex-col border-r border-gray-200 bg-white">
          <div className="max-w-sm w-full">
            <div className="md:p-8 p-5 dark:bg-gray-800 rounded-t">
              <LoadingIndicator isLoading={!auth.currentUser}>
                <Calendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  weekStartIndex={
                    auth.currentUser ? auth.currentUser.weekStartIndex : 0
                  }
                  open={isOpenCalendar}
                  setOpen={setIsOpenCalendar}
                />
              </LoadingIndicator>
              <div className="p-4">
                <div className="flex items-center justify-center">
                  <button
                    onClick={() => setNewEventIsOpen(true)}
                    className="text-white bg-indigo-700 font-medium rounded-lg text-sm px-5 py-2 mb-4"
                  >
                    {t("meetings.newEvent")}
                  </button>
                </div>
                <LoadingIndicator isLoading={eventsQuery.isLoading}>
                  {events.length > 0 ? (
                    <ol className="grid grid-cols-1">
                      {events.map((event) => (
                        <MeetingListItem
                          key={event.id}
                          event={event}
                          active={event.id === currentEvent?.id}
                          changeCurrentEvent={changeCurrentEvent}
                        />
                      ))}
                    </ol>
                  ) : (
                    <p className="text-center font-semibold">
                      {t("meetings.eventsEmptyState")}
                    </p>
                  )}
                </LoadingIndicator>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
