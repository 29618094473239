import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useQuery } from "jsonapi-react";
import { useTranslation } from "react-i18next";
import { notifyApiErrors } from "../lib/toasts";
import { Cycle } from "../lib/types";
import { CycleDetail } from "../components/okrs/CycleDetail";
import { CycleEmptyState } from "../components/okrs/CycleEmptyState";
import { LoadingIndicator } from "../components/elements/LoadingIndicator";
import { displayDate } from "../lib/utils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";

export const CycleList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const [selectedCycle, setSelectedCycle] = useState<Cycle | null>(null);
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [cycleDetailOpen, setCycleDetailOpen] = useState(false);

  const cyclesQuery = useQuery(
    auth.currentUser?.currentOrganizationId && [
      "organizations",
      auth.currentUser.currentOrganizationId,
      "cycles",
      {
        filter: {
          includeArchived: true,
        },
      },
    ]
  );

  useEffect(() => {
    if (cyclesQuery.data) {
      setCycles(cyclesQuery.data as Cycle[]);
      notifyApiErrors(cyclesQuery.errors);
    }
  }, [cyclesQuery]);

  const handleSelectCycle = (cycle: Cycle) => {
    setSelectedCycle(cycle);
    setCycleDetailOpen(true);
  };

  const handleAddCycle = () => {
    setSelectedCycle(null);
    setCycleDetailOpen(true);
  };

  return (
    <section className="flex h-screen min-w-0 flex-1 flex-col xl:order-last m-6 bg-white overflow-auto">
      {cycleDetailOpen && (
        <CycleDetail
          currentUser={auth.currentUser}
          selectedCycle={selectedCycle}
          setSelectedCycle={setSelectedCycle}
          open={cycleDetailOpen}
          setOpen={setCycleDetailOpen}
          weekStartIndex={auth.currentUser?.weekStartIndex || 0}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 mb-6">
            {t("okrs.cyclesPageTitle")}
          </h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            {t("okrs.cyclesDescription")}
          </p> */}
          <button
            type="button"
            onClick={() => handleAddCycle()}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("okrs.addCycleLabel")}
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => navigate("/okrs")}
            className="block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Back to OKRs
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingIndicator isLoading={cyclesQuery.isLoading}>
              <CycleEmptyState isEmpty={cyclesQuery.data && cycles.length === 0}>
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        {t("okrs.nameLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("okrs.startAtLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("okrs.endAtLabel")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("okrs.isArchivedLabel")}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {cycles.map((cycle) => (
                      <Fragment key={cycle.name}>
                        <tr className="border-t border-gray-200 bg-gray-100">
                          <th
                            // colSpan={5}
                            // scope="colgroup"
                            className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            <button
                              className="hover:text-indigo-700"
                              onClick={() => handleSelectCycle(cycle)}
                            >
                              <span className="fa fa-bullseye pr-1"></span>
                              {cycle.name}{" "}
                              {cycle.default && `(${t("okrs.defaultLabel")})`}
                            </button>
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            {displayDate(cycle.startAt)}
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            {displayDate(cycle.endAt)}
                          </th>
                          <th className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-3">
                            {cycle.isArchived ? t("menu.yes") : t("menu.no")}
                          </th>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </CycleEmptyState>
            </LoadingIndicator>
          </div>
        </div>
      </div>
    </section>
  );
};
