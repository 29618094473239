import { Buffer } from "buffer";
import axios from "axios";

export const authHeaderKeys: Array<string> = [
  "access-token",
  "token-type",
  "client",
  "expiry",
  "uid",
];

export const setAuthHeaders = (headers: any): void => {
  authHeaderKeys.forEach((key: string) => {
    axios.defaults.headers.common[key] = headers[key];
  });
};

export const deleteAuthHeaders = (): void => {
  authHeaderKeys.forEach((key: string) => {
    delete axios.defaults.headers.common[key];
  });
};

export const persistAuthHeadersInLocalStorage = (headers: any): void => {
  authHeaderKeys.forEach((key: string) => {
    localStorage.setItem(key, headers[key]);
  });
};

interface LocaleSettings {
  locale: string;
  weekStartIndex: number;
}
export const persistLocaleSettingsInLocalStorage = (settings: LocaleSettings): void => {
  if(settings){
    if(settings.locale && ["en-US", "de-DE"].includes(settings.locale)) {
      localStorage.setItem("locale", settings.locale);
    }
    if(Number(settings.weekStartIndex) === settings.weekStartIndex && settings.weekStartIndex % 1 === 0 && settings.weekStartIndex >= 0 && settings.weekStartIndex < 7)
    localStorage.setItem("weekStartIndex", settings.weekStartIndex.toString());
  }
}

export const deleteAuthHeadersFromLocalStorage = async (): Promise<void> => {
  authHeaderKeys.forEach((key: string) => {
    localStorage.removeItem(key);
  });
};

export const getAuthToken = (): string | null => {
  let data: any = {};
  authHeaderKeys.forEach((key: string) => {
    data[key] = localStorage.getItem(key) || null;
  });
  if (
    data["access-token"] &&
    data["token-type"] &&
    data.client &&
    data.expiry &&
    data.uid &&
    parseInt(data.expiry) > new Date().getTime() / 1000
  ) {
    return Buffer.from(JSON.stringify(data)).toString("base64");
  }
  return null;
};
