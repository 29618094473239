import { Plugin } from "prosemirror-state";

export abstract class Extension {
  options: { [key: string]: any };

  constructor(options = {}) {
    this.options = options;
  }

  // Unique name for the extension. Used as schema and plugin key.
  abstract get name(): string;

  // Returns plugin instances. Override when your extension has plugins.
  get plugins(): Plugin[] {
    return [];
  }
}
