import { useTranslation } from "react-i18next";
import Logo from "../assets/images/visavys_logo.svg";

export const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <main className="flex flex-grow flex-col bg-white">
      <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-10 sm:pt-16">
          <a href="/" className="inline-flex">
            <span className="sr-only">{t("common.visavysName")}</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </a>
        </div>
        <div className="my-auto flex-shrink-0 py-16 sm:py-32">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t("errors.pageNotFound")}
          </h1>
          <p className="mt-2 text-base text-gray-500">
            {t("notFound.pageNotFoundExcuse")}
          </p>
          <div className="mt-6">
            <a
              href="/"
              className="text-base font-medium text-indigo-600 hover:text-indigo-500"
            >
              {t("notFound.backHome")}
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
