import { useState } from "react";
import { CurrentUser, KeyResult, Objective, User } from "../../lib/types";
import { SlideOverRight } from "../elements/SlideOverRight";
import { okrStatusMapping } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { useMutation, useClient, IConfig } from "jsonapi-react";
import { UserCombobox } from "../elements/forms/UserCombobox";
import { notifyApiErrors, notifySuccess } from "../../lib/toasts";

type KeyResultDetailProps = {
  currentUser: CurrentUser | null;
  selectedObjective: Objective | null;
  selectedKeyResult: KeyResult | null;
  objectives: Objective[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const KeyResultDetail = (props: KeyResultDetailProps) => {
  const { t } = useTranslation();
  const apiClient = useClient();
  const [editKeyResult] = useMutation(
    [
      "organizations",
      props.currentUser?.currentOrganizationId,
      "key_results",
      props.selectedKeyResult?.id,
    ],
    {
      invalidate: ["organizations", "objectives", "key_results"],
    }
  );
  const [currentValue, setCurrentValue] = useState(
    props.selectedKeyResult?.currentValue
      ? props.selectedKeyResult.currentValue
      : 0
  );
  const [startValue, setStartValue] = useState(
    props.selectedKeyResult?.startValue ? props.selectedKeyResult.startValue : 0
  );
  const [targetValue, setTargetValue] = useState(
    props.selectedKeyResult?.targetValue
      ? props.selectedKeyResult.targetValue
      : 100
  );
  const [valueType, setValueType] = useState(
    props.selectedKeyResult?.valueType
      ? props.selectedKeyResult.valueType
      : "percent"
  );
  const [valueUnit, setValueUnit] = useState(
    props.selectedKeyResult?.valueUnit ? props.selectedKeyResult.valueUnit : "%"
  );
  const [title, setTitle] = useState(
    props.selectedKeyResult?.title ? props.selectedKeyResult?.title : ""
  );
  const [description, setDescription] = useState(
    props.selectedKeyResult?.description
      ? props.selectedKeyResult?.description
      : ""
  );
  const [status, setStatus] = useState(
    props.selectedKeyResult?.status
      ? props.selectedKeyResult.status
      : "not_started"
  );
  const [owner, setOwner] = useState<User | null | undefined>(
    props.selectedKeyResult?.owner ? props.selectedKeyResult.owner : props.selectedObjective?.owner
  );
  const [objective, setObjective] = useState(props.selectedObjective);
  const [progress, setProgress] = useState(
    props.selectedKeyResult?.progress ? props.selectedKeyResult?.progress : 0
  );

  const allStatus = [
    "on_track",
    "behind",
    "at_risk",
    "closed",
    "postponed",
    "not_started",
  ];

  const allValueTypes = ["number", "percent"];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editKeyResult({
      currentValue,
      description,
      progress,
      startValue,
      status,
      targetValue,
      title,
      valueType,
      valueUnit,
      objectiveId: objective?.id,
      ownerId: owner?.id,
    });
    props.setOpen(false);
  };

  const handleDelete = async () => {
    // Cast to as IConfig is necessary because IConfig doesn't allow invalidate, but we need it and the library accepts it.
    await apiClient
      .delete(
        [
          "organizations",
          props.currentUser?.currentOrganizationId,
          "key_results",
          props.selectedKeyResult?.id,
        ],
        {
          invalidate: ["organizations", "objectives", "key_results"],
        } as IConfig
      )
      .then((response) => {
        if (response.meta && !response.error) {
          notifySuccess(t("apiMessages.successCycleDeleted"));
          props.setOpen(false);
        } else {
          notifyApiErrors([response.error]);
        }
      });
  };

  return (
    <SlideOverRight
      open={props.open}
      setOpen={props.setOpen}
      title={
        props.selectedKeyResult
          ? props.selectedKeyResult.title
          : t("okrs.newKeyResultLabel")
      }
      titleIconClass="fa-thermometer-half"
      closeLabel="Close"
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.titleLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      name="title"
                      id="title"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.descriptionLabel")}
                </label>
                <div className="mt-2">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="progress"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.progressLabel")}
                </label>
                <div className="mt-2">
                  <input
                    type="range"
                    min={0}
                    max="100"
                    step="10"
                    value={progress}
                    onChange={(e) => setProgress(+e.target.value)}
                    list="tickmarks"
                    className="range range-primary"
                  />
                  <span className="text-xs pl-1">({progress}%)</span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.statusLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {allStatus.map((s) => (
                      <option key={s} value={s}>
                        {t(`okrs.${okrStatusMapping[s]}`)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="startValue"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.startValueLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={startValue}
                      onChange={(e) => setStartValue(+e.target.value)}
                      name="startValue"
                      id="startValue"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="targetValue"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.targetValueLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={targetValue}
                      onChange={(e) => setTargetValue(+e.target.value)}
                      name="targetValue"
                      id="targetValue"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="currentValue"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.currentValueLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={currentValue}
                      onChange={(e) => setCurrentValue(+e.target.value)}
                      name="currentValue"
                      id="currentValue"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="valueType"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.valueTypeLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="valueType"
                    name="valueType"
                    value={valueType}
                    onChange={(e) => setValueType(e.target.value)}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {allValueTypes.map((vt) => (
                      <option key={vt} value={vt}>
                        {vt}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="valueUnit"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.valueUnitLabel")}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      value={valueUnit}
                      onChange={(e) => setValueUnit(e.target.value)}
                      name="valueUnit"
                      id="valueUnit"
                      autoComplete="off"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="objective"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("okrs.objectiveLabel")}
                </label>
                <div className="mt-2">
                  <select
                    id="objective"
                    name="objective"
                    value={objective?.id}
                    onChange={(e) =>
                      setObjective(
                        props.objectives.find((o) => o.id === e.target.value) ||
                          null
                      )
                    }
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {props.objectives.map((o) => (
                      <option key={o.id} value={o.id}>
                        {o.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-full">
                <UserCombobox
                  selectedUser={owner}
                  setSelectedUser={setOwner}
                  title={t("okrs.ownerLabel")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleDelete}
            disabled={props.selectedKeyResult ? false : true}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("menu.delete")}
          </button>
          <button
            type="button"
            onClick={() => props.setOpen(false)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("menu.cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("menu.save")}
          </button>
        </div>
      </form>
    </SlideOverRight>
  );
};
