import {
  Schema,
  NodeSpec,
  MarkSpec,
  DOMOutputSpec,
  Node,
} from "prosemirror-model";

const brDOM: DOMOutputSpec = ["br"];

/**
   A block that has a checkbox that is active based on the done
   attribute. The attribute wont_do strikes the whole item.
*/
const assignable_item = (blockType: string) => {
  return {
    attrs: {
      block_id: { default: null },
      done: { default: false },
      wont_do: { default: false },
      indent: { default: 0 },
      assignee_id: { default: null },
      creator_id: { default: null },
      due_at: { default: null },
    },
    group: "block",
    content: "inline*",
    // draggable: true,
    selectable: true,
    // isolating: false,
    parseDOM: [
      {
        priority: 51,
        tag: `[data-block_type="${blockType}"]`,
      },
    ],
    toDOM(node: Node) {
      let { block_id, done, wont_do, indent, assignee_id, creator_id, due_at } =
        node.attrs;
      return [
        "div",
        {
          "data-block_type": blockType,
          "data-block_id": block_id,
          "data-done": done,
          "data-wont_do": wont_do,
          "data-indent": indent,
          "data-assignee_id": assignee_id,
          "data-creator_id": creator_id,
          "data-due_at": due_at,
        },
        0
      ];
    }
  } as NodeSpec;
};

/**
   A heading textblock, with a `level` attribute that
   should hold the number 1 to 6. Parsed and serialized as `<h1>` to
   `<h6>` elements.
*/
const heading = {
  attrs: { level: { default: 1 } },
  content: "inline*",
  group: "block",
  defining: true,
  parseDOM: [
    {
      priority: 65,
      tag: "h1",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
    {
      priority: 65,
      tag: "h2",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
    {
      priority: 65,
      tag: "h3",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
    {
      priority: 65,
      tag: "h4",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
    {
      priority: 65,
      tag: "h5",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
    {
      priority: 65,
      tag: "h6",
      getAttrs(dom: HTMLElement) {
        return {
          level: 1,
          block_id: dom.getAttribute("data-block_id") || null,
        };
      },
    },
  ],
  toDOM(node) {
    return [
      "h" + node.attrs.level,
      {
        block_id: node.attrs.block_id,
      },
      0,
    ];
  },
} as NodeSpec;

const bullet_point = {
  attrs: {
    block_id: { default: null },
    indent: { default: 0 },
  },
  group: "block",
  content: "inline*",
  // draggable: true,
  selectable: true,
  // isolating: false,
  parseDOM: [
    {
      priority: 10,
      tag: '[data-block_type="bullet_point"]',
    },
  ],
  toDOM(node: Node) {
    let { block_id, indent } =
      node.attrs;
    return [
      "div",
      {
        block_type: "bullet_point",
        block_id,
        indent,
      },
    ];
  }
} as NodeSpec;


const paragraph_item = assignable_item("paragraph_item")
paragraph_item.parseDOM = [
      {
        priority: 51,
        tag: '[data-block_type="paragraph_item"]',
      },
      {
        priority: 51,
        tag: 'br',
      },
      {
        priority: 51,
        tag: 'p',
      }
    ]

/**
   [Specs](https://prosemirror.net/docs/ref/#model.NodeSpec) for the nodes defined in this schema.
*/
const nodes = {
  /**
       NodeSpec The top level document node.
    */
  doc: {
    content: "heading block+",
  } as NodeSpec,

  heading,
  paragraph_item: paragraph_item,
  action_item: assignable_item("action_item"),
  discussion_item: assignable_item("discussion_item") ,
  bullet_point: bullet_point,
  /**
       The text node.
    */
  text: {
    group: "inline",
  },
  /**
       An inline image (`<img>`) node. Supports `src`,
       `alt`, and `href` attributes. The latter two default to the empty
       string.
    */
  image: {
    inline: true,
    attrs: {
      src: {},
      alt: { default: null },
      title: { default: null },
    },
    group: "inline",
    draggable: true,
    parseDOM: [
      {
        tag: "img[src]",
        getAttrs(dom: HTMLElement) {
          return {
            src: dom.getAttribute("src"),
            title: dom.getAttribute("title"),
            alt: dom.getAttribute("alt"),
          };
        },
      },
    ],
    toDOM(node) {
      let { src, alt, title } = node.attrs;
      return ["img", { src, alt, title }];
    },
  } as NodeSpec,
  /**
       A hard line break, represented in the DOM as `<br>`.
    */
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    parseDOM: [{ tag: "br" }],
    toDOM() {
      return brDOM;
    },
  },
};

const emDOM: DOMOutputSpec = ["em", 0],
  strongDOM: DOMOutputSpec = ["strong", 0],
  codeDOM: DOMOutputSpec = ["code", 0];

/// [Specs](#model.MarkSpec) for the marks in the schema.
export const marks = {
  /// A link. Has `href` and `title` attributes. `title`
  /// defaults to the empty string. Rendered and parsed as an `<a>`
  /// element.
  link: {
    attrs: {
      href: {},
      title: { default: null },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: "a[href]",
        getAttrs(dom: HTMLElement) {
          return {
            href: dom.getAttribute("href"),
            title: dom.getAttribute("title"),
          };
        },
      },
    ],
    toDOM(node) {
      let { href, title } = node.attrs;
      return ["a", { href, title }, 0];
    },
  } as MarkSpec,

  /// An emphasis mark. Rendered as an `<em>` element. Has parse rules
  /// that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [
      { tag: "i" },
      { tag: "em" },
      { class: "italic" },
      { class: "not-italic", clearMark: (m) => m.type.name === "em" },
    ],
    toDOM() {
      return emDOM;
    },
  } as MarkSpec,

  /// A strong mark. Rendered as `<strong>`, parse rules also match
  /// `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [
      { tag: "strong" },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      {
        tag: "b",
        getAttrs: (node: HTMLElement) =>
          node.style.fontWeight !== "normal" && null,
      },
      { class: "font-bold", clearMark: (m) => m.type.name === "strong" },
      {
        class: "font-normal",
        getAttrs: (value: string) =>
          /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
      },
    ],
    toDOM() {
      return strongDOM;
    },
  } as MarkSpec,

  /// Code font mark. Represented as a `<code>` element.
  code: {
    parseDOM: [{ tag: "code" }],
    toDOM() {
      return codeDOM;
    },
  } as MarkSpec,
};

const schema = new Schema({ nodes, marks });

export { schema };
